/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCurrentPaymentDto
 */
export interface OrderCurrentPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCurrentPaymentDto
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCurrentPaymentDto
     */
    type: OrderCurrentPaymentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderCurrentPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCurrentPaymentDto
     */
    status: OrderCurrentPaymentDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export type OrderCurrentPaymentDtoTypeEnum =
    | 'FULL'
    | 'SURCHARGE'
    | 'FIRST_SPLIT'
    | 'SECOND_SPLIT'
    | 'FREE'
/**
* @export
* @enum {string}
*/
export type OrderCurrentPaymentDtoStatusEnum =
    | 'DRAFT'
    | 'CREATING'
    | 'ACTIVE'
    | 'WAIT_CONFIRM'



export function OrderCurrentPaymentDtoFromJSON(json: any): OrderCurrentPaymentDto {
    return OrderCurrentPaymentDtoFromJSONTyped(json, false);
}

export function OrderCurrentPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCurrentPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderId': json['orderId'],
        'type': json['type'],
        'amount': json['amount'],
        'status': json['status'],
    };
}

export function OrderCurrentPaymentDtoToJSON(value?: OrderCurrentPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderId': value.orderId,
        'type': value.type,
        'amount': value.amount,
        'status': value.status,
    };
}


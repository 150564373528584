/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderUpdatePaymentTypeRequestDto
 */
export interface OrderUpdatePaymentTypeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrderUpdatePaymentTypeRequestDto
     */
    type: OrderUpdatePaymentTypeRequestDtoTypeEnum;
}

/**
* @export
* @enum {string}
*/
export type OrderUpdatePaymentTypeRequestDtoTypeEnum =
    | 'MANUAL'
    | 'MPCVIP'
    | 'MPCVIP_REFER'
    | 'CART'
    | 'ROCKET_WORK'
    | 'ROCKET_WORK_TKB'
    | 'ONE_PAYMENTS'
    | 'ONE_PAYMENTS_CUSTOM'
    | 'COMPANY_MONEY'
    | 'CUSTOM'
    | 'CASH'
    | 'YOOKASSA'
    | 'AIFORY'
    | 'LEGIT_PAY_CUSTOM_FORM'
    | 'LEGIT_PAY_SIMPLE_FORM'
    | 'AIFORY_CUSTOM'
    | 'X_PAY'
    | 'SBC_TECH'
    | 'ALFA_BANK'



export function OrderUpdatePaymentTypeRequestDtoFromJSON(json: any): OrderUpdatePaymentTypeRequestDto {
    return OrderUpdatePaymentTypeRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdatePaymentTypeRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdatePaymentTypeRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function OrderUpdatePaymentTypeRequestDtoToJSON(value?: OrderUpdatePaymentTypeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}


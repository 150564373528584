/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowTransferByPhoneDto
 */
export interface PaymentFlowTransferByPhoneDto {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentFlowTransferByPhoneDto
     */
    enabled: boolean;
}


export function PaymentFlowTransferByPhoneDtoFromJSON(json: any): PaymentFlowTransferByPhoneDto {
    return PaymentFlowTransferByPhoneDtoFromJSONTyped(json, false);
}

export function PaymentFlowTransferByPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowTransferByPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function PaymentFlowTransferByPhoneDtoToJSON(value?: PaymentFlowTransferByPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
    };
}


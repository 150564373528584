/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductSplitDto
 */
export interface CatalogProductSplitDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductSplitDto
   */
  first: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSplitDto
   */
  second: number
}

export function CatalogProductSplitDtoFromJSON(json: any): CatalogProductSplitDto {
  return CatalogProductSplitDtoFromJSONTyped(json, false)
}

export function CatalogProductSplitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductSplitDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    first: json["first"],
    second: json["second"],
  }
}

export function CatalogProductSplitDtoToJSON(value?: CatalogProductSplitDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    first: value.first,
    second: value.second,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { Category, CategoryFromJSON, CategoryFromJSONTyped, CategoryToJSON } from "./Category"

/**
 *
 * @export
 * @interface CategoryTree
 */
export interface CategoryTree {
  /**
   *
   * @type {Array<Category>}
   * @memberof CategoryTree
   */
  categories: Array<Category>
}

export function CategoryTreeFromJSON(json: any): CategoryTree {
  return CategoryTreeFromJSONTyped(json, false)
}

export function CategoryTreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryTree {
  if (json === undefined || json === null) {
    return json
  }
  return {
    categories: (json["categories"] as Array<any>).map(CategoryFromJSON),
  }
}

export function CategoryTreeToJSON(value?: CategoryTree | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    categories: (value.categories as Array<any>).map(CategoryToJSON),
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerDiscountDto
 */
export interface CustomerDiscountDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDiscountDto
     */
    type: CustomerDiscountDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerDiscountDto
     */
    spuId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDiscountDto
     */
    activeFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDiscountDto
     */
    activeTo: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerDiscountDto
     */
    amount: string;
}

/**
* @export
* @enum {string}
*/
export type CustomerDiscountDtoTypeEnum =
    | 'CUSTOM'
    | 'PINK_FRIDAY_2024_REVIEW'
    | 'PINK_FRIDAY_2024_ORDER'



export function CustomerDiscountDtoFromJSON(json: any): CustomerDiscountDto {
    return CustomerDiscountDtoFromJSONTyped(json, false);
}

export function CustomerDiscountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDiscountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'spuId': !exists(json, 'spuId') ? undefined : json['spuId'],
        'activeFrom': (new Date(json['activeFrom'])),
        'activeTo': (new Date(json['activeTo'])),
        'amount': json['amount'],
    };
}

export function CustomerDiscountDtoToJSON(value?: CustomerDiscountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'spuId': value.spuId,
        'activeFrom': (value.activeFrom.toISOString()),
        'activeTo': (value.activeTo.toISOString()),
        'amount': value.amount,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderSplitDto
 */
export interface OrderSplitDto {
    /**
     * 
     * @type {string}
     * @memberof OrderSplitDto
     */
    orderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSplitDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderSplitDto
     */
    firstPayment: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSplitDto
     */
    secondPayment: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSplitDto
     */
    canBeEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSplitDto
     */
    firstPaymentCompleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSplitDto
     */
    secondPaymentCompleted: boolean;
}


export function OrderSplitDtoFromJSON(json: any): OrderSplitDto {
    return OrderSplitDtoFromJSONTyped(json, false);
}

export function OrderSplitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSplitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderId': json['orderId'],
        'enabled': json['enabled'],
        'firstPayment': json['firstPayment'],
        'secondPayment': json['secondPayment'],
        'canBeEnabled': json['canBeEnabled'],
        'firstPaymentCompleted': json['firstPaymentCompleted'],
        'secondPaymentCompleted': json['secondPaymentCompleted'],
    };
}

export function OrderSplitDtoToJSON(value?: OrderSplitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderId': value.orderId,
        'enabled': value.enabled,
        'firstPayment': value.firstPayment,
        'secondPayment': value.secondPayment,
        'canBeEnabled': value.canBeEnabled,
        'firstPaymentCompleted': value.firstPaymentCompleted,
        'secondPaymentCompleted': value.secondPaymentCompleted,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowLinkDto
 */
export interface PaymentFlowLinkDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowLinkDto
     */
    link: string;
}


export function PaymentFlowLinkDtoFromJSON(json: any): PaymentFlowLinkDto {
    return PaymentFlowLinkDtoFromJSONTyped(json, false);
}

export function PaymentFlowLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': json['link'],
    };
}

export function PaymentFlowLinkDtoToJSON(value?: PaymentFlowLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderAiforyPaymentCustomDto,
    OrderAiforyPaymentCustomDtoFromJSON,
    OrderAiforyPaymentCustomDtoFromJSONTyped,
    OrderAiforyPaymentCustomDtoToJSON,
} from './OrderAiforyPaymentCustomDto';
import {
    OrderAiforyPaymentDto,
    OrderAiforyPaymentDtoFromJSON,
    OrderAiforyPaymentDtoFromJSONTyped,
    OrderAiforyPaymentDtoToJSON,
} from './OrderAiforyPaymentDto';
import {
    OrderLegitPayPaymentCustomFormDto,
    OrderLegitPayPaymentCustomFormDtoFromJSON,
    OrderLegitPayPaymentCustomFormDtoFromJSONTyped,
    OrderLegitPayPaymentCustomFormDtoToJSON,
} from './OrderLegitPayPaymentCustomFormDto';
import {
    OrderLegitPayPaymentSimpleFormDto,
    OrderLegitPayPaymentSimpleFormDtoFromJSON,
    OrderLegitPayPaymentSimpleFormDtoFromJSONTyped,
    OrderLegitPayPaymentSimpleFormDtoToJSON,
} from './OrderLegitPayPaymentSimpleFormDto';
import {
    OrderManualPaymentDto,
    OrderManualPaymentDtoFromJSON,
    OrderManualPaymentDtoFromJSONTyped,
    OrderManualPaymentDtoToJSON,
} from './OrderManualPaymentDto';
import {
    OrderMpcVipReferPaymentDto,
    OrderMpcVipReferPaymentDtoFromJSON,
    OrderMpcVipReferPaymentDtoFromJSONTyped,
    OrderMpcVipReferPaymentDtoToJSON,
} from './OrderMpcVipReferPaymentDto';
import {
    OrderOnepaymentsPaymentDto,
    OrderOnepaymentsPaymentDtoFromJSON,
    OrderOnepaymentsPaymentDtoFromJSONTyped,
    OrderOnepaymentsPaymentDtoToJSON,
} from './OrderOnepaymentsPaymentDto';
import {
    OrderRocketWorkPaymentDto,
    OrderRocketWorkPaymentDtoFromJSON,
    OrderRocketWorkPaymentDtoFromJSONTyped,
    OrderRocketWorkPaymentDtoToJSON,
} from './OrderRocketWorkPaymentDto';
import {
    OrderYookassaPaymentDto,
    OrderYookassaPaymentDtoFromJSON,
    OrderYookassaPaymentDtoFromJSONTyped,
    OrderYookassaPaymentDtoToJSON,
} from './OrderYookassaPaymentDto';

/**
 * 
 * @export
 * @interface OrderPaymentDto
 */
export interface OrderPaymentDto {
    /**
     * 
     * @type {object}
     * @memberof OrderPaymentDto
     */
    mpcVip: object | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    customerOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    status: OrderPaymentDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderPaymentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    type: OrderPaymentDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentDto
     */
    additional: boolean;
    /**
     * 
     * @type {OrderManualPaymentDto}
     * @memberof OrderPaymentDto
     */
    manual: OrderManualPaymentDto | null;
    /**
     * 
     * @type {OrderMpcVipReferPaymentDto}
     * @memberof OrderPaymentDto
     */
    mpcVipRefer: OrderMpcVipReferPaymentDto | null;
    /**
     * 
     * @type {OrderAiforyPaymentDto}
     * @memberof OrderPaymentDto
     */
    aifory: OrderAiforyPaymentDto | null;
    /**
     * 
     * @type {OrderAiforyPaymentCustomDto}
     * @memberof OrderPaymentDto
     */
    aiforyCustom: OrderAiforyPaymentCustomDto | null;
    /**
     * 
     * @type {OrderRocketWorkPaymentDto}
     * @memberof OrderPaymentDto
     */
    rocketWork: OrderRocketWorkPaymentDto | null;
    /**
     * 
     * @type {OrderOnepaymentsPaymentDto}
     * @memberof OrderPaymentDto
     */
    onepayments: OrderOnepaymentsPaymentDto | null;
    /**
     * 
     * @type {OrderYookassaPaymentDto}
     * @memberof OrderPaymentDto
     */
    yookassa: OrderYookassaPaymentDto | null;
    /**
     * 
     * @type {OrderLegitPayPaymentCustomFormDto}
     * @memberof OrderPaymentDto
     */
    legitPayCustomForm: OrderLegitPayPaymentCustomFormDto | null;
    /**
     * 
     * @type {OrderLegitPayPaymentSimpleFormDto}
     * @memberof OrderPaymentDto
     */
    legitPaySimpleForm: OrderLegitPayPaymentSimpleFormDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentDto
     */
    isFromBot: boolean;
}

/**
* @export
* @enum {string}
*/
export type OrderPaymentDtoStatusEnum =
    | 'NEW'
    | 'PAID'
    | 'CANCELED'
    | 'REFUND'
    | 'MARK_PAID'
    | 'REJECTED'
    | 'REJECTED_CONFIRMED'
    | 'APPELLATION'
/**
* @export
* @enum {string}
*/
export type OrderPaymentDtoTypeEnum =
    | 'MANUAL'
    | 'MPCVIP'
    | 'MPCVIP_REFER'
    | 'CART'
    | 'ROCKET_WORK'
    | 'ROCKET_WORK_TKB'
    | 'ONE_PAYMENTS'
    | 'ONE_PAYMENTS_CUSTOM'
    | 'COMPANY_MONEY'
    | 'CUSTOM'
    | 'CASH'
    | 'YOOKASSA'
    | 'AIFORY'
    | 'LEGIT_PAY_CUSTOM_FORM'
    | 'LEGIT_PAY_SIMPLE_FORM'
    | 'AIFORY_CUSTOM'
    | 'X_PAY'
    | 'SBC_TECH'
    | 'ALFA_BANK'



export function OrderPaymentDtoFromJSON(json: any): OrderPaymentDto {
    return OrderPaymentDtoFromJSONTyped(json, false);
}

export function OrderPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mpcVip': json['mpcVip'],
        'id': json['id'],
        'customerOrderId': json['customerOrderId'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'type': json['type'],
        'additional': json['additional'],
        'manual': OrderManualPaymentDtoFromJSON(json['manual']),
        'mpcVipRefer': OrderMpcVipReferPaymentDtoFromJSON(json['mpcVipRefer']),
        'aifory': OrderAiforyPaymentDtoFromJSON(json['aifory']),
        'aiforyCustom': OrderAiforyPaymentCustomDtoFromJSON(json['aiforyCustom']),
        'rocketWork': OrderRocketWorkPaymentDtoFromJSON(json['rocketWork']),
        'onepayments': OrderOnepaymentsPaymentDtoFromJSON(json['onepayments']),
        'yookassa': OrderYookassaPaymentDtoFromJSON(json['yookassa']),
        'legitPayCustomForm': OrderLegitPayPaymentCustomFormDtoFromJSON(json['legitPayCustomForm']),
        'legitPaySimpleForm': OrderLegitPayPaymentSimpleFormDtoFromJSON(json['legitPaySimpleForm']),
        'isFromBot': json['isFromBot'],
    };
}

export function OrderPaymentDtoToJSON(value?: OrderPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mpcVip': value.mpcVip,
        'id': value.id,
        'customerOrderId': value.customerOrderId,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'type': value.type,
        'additional': value.additional,
        'manual': OrderManualPaymentDtoToJSON(value.manual),
        'mpcVipRefer': OrderMpcVipReferPaymentDtoToJSON(value.mpcVipRefer),
        'aifory': OrderAiforyPaymentDtoToJSON(value.aifory),
        'aiforyCustom': OrderAiforyPaymentCustomDtoToJSON(value.aiforyCustom),
        'rocketWork': OrderRocketWorkPaymentDtoToJSON(value.rocketWork),
        'onepayments': OrderOnepaymentsPaymentDtoToJSON(value.onepayments),
        'yookassa': OrderYookassaPaymentDtoToJSON(value.yookassa),
        'legitPayCustomForm': OrderLegitPayPaymentCustomFormDtoToJSON(value.legitPayCustomForm),
        'legitPaySimpleForm': OrderLegitPayPaymentSimpleFormDtoToJSON(value.legitPaySimpleForm),
        'isFromBot': value.isFromBot,
    };
}


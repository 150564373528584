/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductCategory,
  CatalogProductCategoryFromJSON,
  CatalogProductCategoryFromJSONTyped,
  CatalogProductCategoryToJSON,
} from "./CatalogProductCategory"
import {
  CatalogProductFit,
  CatalogProductFitFromJSON,
  CatalogProductFitFromJSONTyped,
  CatalogProductFitToJSON,
} from "./CatalogProductFit"
import {
  CatalogProductSizeType,
  CatalogProductSizeTypeFromJSON,
  CatalogProductSizeTypeFromJSONTyped,
  CatalogProductSizeTypeToJSON,
} from "./CatalogProductSizeType"
import {
  CatalogProductSort,
  CatalogProductSortFromJSON,
  CatalogProductSortFromJSONTyped,
  CatalogProductSortToJSON,
} from "./CatalogProductSort"

/**
 *
 * @export
 * @interface CatalogSearchQueryDto
 */
export interface CatalogSearchQueryDto {
  /**
   *
   * @type {CatalogProductCategory}
   * @memberof CatalogSearchQueryDto
   */
  category?: CatalogProductCategory
  /**
   *
   * @type {CatalogProductSizeType}
   * @memberof CatalogSearchQueryDto
   */
  sizeType?: CatalogProductSizeType
  /**
   *
   * @type {CatalogProductSort}
   * @memberof CatalogSearchQueryDto
   */
  sort?: CatalogProductSort
  /**
   *
   * @type {Array<CatalogProductFit>}
   * @memberof CatalogSearchQueryDto
   */
  fit?: Array<CatalogProductFit>
  /**
   *
   * @type {string}
   * @memberof CatalogSearchQueryDto
   */
  search?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogSearchQueryDto
   */
  brands?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CatalogSearchQueryDto
   */
  sizeValue?: string
  /**
   *
   * @type {number}
   * @memberof CatalogSearchQueryDto
   */
  priceFrom?: number
  /**
   *
   * @type {number}
   * @memberof CatalogSearchQueryDto
   */
  priceTo?: number
  /**
   *
   * @type {string}
   * @memberof CatalogSearchQueryDto
   */
  categorySlug?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogSearchQueryDto
   */
  multiCategorySlug?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof CatalogSearchQueryDto
   */
  fromAvailability?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CatalogSearchQueryDto
   */
  fromAvailabilityDiscount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CatalogSearchQueryDto
   */
  description?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CatalogSearchQueryDto
   */
  discount?: boolean
  /**
   *
   * @type {string}
   * @memberof CatalogSearchQueryDto
   */
  color?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogSearchQueryDto
   */
  seasons?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof CatalogSearchQueryDto
   */
  returnable?: boolean
}

export function CatalogSearchQueryDtoFromJSON(json: any): CatalogSearchQueryDto {
  return CatalogSearchQueryDtoFromJSONTyped(json, false)
}

export function CatalogSearchQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogSearchQueryDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    category: !exists(json, "category") ? undefined : CatalogProductCategoryFromJSON(json["category"]),
    sizeType: !exists(json, "sizeType") ? undefined : CatalogProductSizeTypeFromJSON(json["sizeType"]),
    sort: !exists(json, "sort") ? undefined : CatalogProductSortFromJSON(json["sort"]),
    fit: !exists(json, "fit") ? undefined : (json["fit"] as Array<any>).map(CatalogProductFitFromJSON),
    search: !exists(json, "search") ? undefined : json["search"],
    brands: !exists(json, "brands") ? undefined : json["brands"],
    sizeValue: !exists(json, "sizeValue") ? undefined : json["sizeValue"],
    priceFrom: !exists(json, "priceFrom") ? undefined : json["priceFrom"],
    priceTo: !exists(json, "priceTo") ? undefined : json["priceTo"],
    categorySlug: !exists(json, "categorySlug") ? undefined : json["categorySlug"],
    multiCategorySlug: !exists(json, "multiCategorySlug") ? undefined : json["multiCategorySlug"],
    fromAvailability: !exists(json, "fromAvailability") ? undefined : json["fromAvailability"],
    fromAvailabilityDiscount: !exists(json, "fromAvailabilityDiscount") ? undefined : json["fromAvailabilityDiscount"],
    description: !exists(json, "description") ? undefined : json["description"],
    discount: !exists(json, "discount") ? undefined : json["discount"],
    color: !exists(json, "color") ? undefined : json["color"],
    seasons: !exists(json, "seasons") ? undefined : json["seasons"],
    returnable: !exists(json, "returnable") ? undefined : json["returnable"],
  }
}

export function CatalogSearchQueryDtoToJSON(value?: CatalogSearchQueryDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    category: CatalogProductCategoryToJSON(value.category),
    sizeType: CatalogProductSizeTypeToJSON(value.sizeType),
    sort: CatalogProductSortToJSON(value.sort),
    fit: value.fit === undefined ? undefined : (value.fit as Array<any>).map(CatalogProductFitToJSON),
    search: value.search,
    brands: value.brands,
    sizeValue: value.sizeValue,
    priceFrom: value.priceFrom,
    priceTo: value.priceTo,
    categorySlug: value.categorySlug,
    multiCategorySlug: value.multiCategorySlug,
    fromAvailability: value.fromAvailability,
    fromAvailabilityDiscount: value.fromAvailabilityDiscount,
    description: value.description,
    discount: value.discount,
    color: value.color,
    seasons: value.seasons,
    returnable: value.returnable,
  }
}

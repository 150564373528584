/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    OrderCreateV2RequestDto,
    OrderCreateV2RequestDtoFromJSON,
    OrderCreateV2RequestDtoToJSON,
    OrderDetailsDto,
    OrderDetailsDtoFromJSON,
    OrderDetailsDtoToJSON,
    OrderUpdateDeliveryV2RequestDto,
    OrderUpdateDeliveryV2RequestDtoFromJSON,
    OrderUpdateDeliveryV2RequestDtoToJSON,
} from '../models';

export interface OrderCreateV2Request {
    orderCreateV2RequestDto: OrderCreateV2RequestDto;
}

export interface OrderCreateV2UnauthRequest {
    orderCreateV2RequestDto: OrderCreateV2RequestDto;
}

export interface OrderUpdateDeliveryV2Request {
    id: string;
    orderUpdateDeliveryV2RequestDto: OrderUpdateDeliveryV2RequestDto;
}

/**
 * 
 */
export class OrderV2Api extends runtime.BaseAPI {

    /**
     */
    protected orderCreateV2RequestOpts = (requestParameters: OrderCreateV2Request): runtime.RequestOpts => {
        if (requestParameters.orderCreateV2RequestDto === null || requestParameters.orderCreateV2RequestDto === undefined) {
            throw new runtime.RequiredError('orderCreateV2RequestDto','Required parameter requestParameters.orderCreateV2RequestDto was null or undefined when calling orderCreateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/order-v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderCreateV2RequestDtoToJSON(requestParameters.orderCreateV2RequestDto),
        };
    }

    /**
     */
    protected orderCreateV2Fetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderCreateV2Raw = async (requestParameters: OrderCreateV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const context = this.orderCreateV2RequestOpts(requestParameters);
        return this.orderCreateV2Fetch(context, initOverrides);
    }

    /**
     */
    orderCreateV2 = async (orderCreateV2RequestDto: OrderCreateV2RequestDto, initOverrides?: RequestInit): Promise<OrderDetailsDto> => {
        const response = await this.orderCreateV2Raw({ orderCreateV2RequestDto: orderCreateV2RequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected orderCreateV2UnauthRequestOpts = (requestParameters: OrderCreateV2UnauthRequest): runtime.RequestOpts => {
        if (requestParameters.orderCreateV2RequestDto === null || requestParameters.orderCreateV2RequestDto === undefined) {
            throw new runtime.RequiredError('orderCreateV2RequestDto','Required parameter requestParameters.orderCreateV2RequestDto was null or undefined when calling orderCreateV2Unauth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order-v2/unauth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderCreateV2RequestDtoToJSON(requestParameters.orderCreateV2RequestDto),
        };
    }

    /**
     */
    protected orderCreateV2UnauthFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderCreateV2UnauthRaw = async (requestParameters: OrderCreateV2UnauthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const context = this.orderCreateV2UnauthRequestOpts(requestParameters);
        return this.orderCreateV2UnauthFetch(context, initOverrides);
    }

    /**
     */
    orderCreateV2Unauth = async (orderCreateV2RequestDto: OrderCreateV2RequestDto, initOverrides?: RequestInit): Promise<OrderDetailsDto> => {
        const response = await this.orderCreateV2UnauthRaw({ orderCreateV2RequestDto: orderCreateV2RequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected orderUpdateDeliveryV2RequestOpts = (requestParameters: OrderUpdateDeliveryV2Request): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdateDeliveryV2.');
        }

        if (requestParameters.orderUpdateDeliveryV2RequestDto === null || requestParameters.orderUpdateDeliveryV2RequestDto === undefined) {
            throw new runtime.RequiredError('orderUpdateDeliveryV2RequestDto','Required parameter requestParameters.orderUpdateDeliveryV2RequestDto was null or undefined when calling orderUpdateDeliveryV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/order-v2/{id}/update-delivery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateDeliveryV2RequestDtoToJSON(requestParameters.orderUpdateDeliveryV2RequestDto),
        };
    }

    /**
     */
    protected orderUpdateDeliveryV2Fetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected orderUpdateDeliveryV2Raw = async (requestParameters: OrderUpdateDeliveryV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsDto>> => {
        const context = this.orderUpdateDeliveryV2RequestOpts(requestParameters);
        return this.orderUpdateDeliveryV2Fetch(context, initOverrides);
    }

    /**
     */
    orderUpdateDeliveryV2 = async (id: string, orderUpdateDeliveryV2RequestDto: OrderUpdateDeliveryV2RequestDto, initOverrides?: RequestInit): Promise<OrderDetailsDto> => {
        const response = await this.orderUpdateDeliveryV2Raw({ id: id, orderUpdateDeliveryV2RequestDto: orderUpdateDeliveryV2RequestDto }, initOverrides);
        return await response.value();
    }



}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    StoriesMarkReadRequestDto,
    StoriesMarkReadRequestDtoFromJSON,
    StoriesMarkReadRequestDtoToJSON,
    StoriesReadStoriesDto,
    StoriesReadStoriesDtoFromJSON,
    StoriesReadStoriesDtoToJSON,
} from '../models';

export interface StoriesMarkReadRequest {
    storiesMarkReadRequestDto: StoriesMarkReadRequestDto;
}

/**
 * 
 */
export class StoriesApi extends runtime.BaseAPI {

    /**
     */
    protected storiesMarkReadRequestOpts = (requestParameters: StoriesMarkReadRequest): runtime.RequestOpts => {
        if (requestParameters.storiesMarkReadRequestDto === null || requestParameters.storiesMarkReadRequestDto === undefined) {
            throw new runtime.RequiredError('storiesMarkReadRequestDto','Required parameter requestParameters.storiesMarkReadRequestDto was null or undefined when calling storiesMarkRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/stories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoriesMarkReadRequestDtoToJSON(requestParameters.storiesMarkReadRequestDto),
        };
    }

    /**
     */
    protected storiesMarkReadFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected storiesMarkReadRaw = async (requestParameters: StoriesMarkReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.storiesMarkReadRequestOpts(requestParameters);
        return this.storiesMarkReadFetch(context, initOverrides);
    }

    /**
     */
    storiesMarkRead = async (storiesMarkReadRequestDto: StoriesMarkReadRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.storiesMarkReadRaw({ storiesMarkReadRequestDto: storiesMarkReadRequestDto }, initOverrides);
    }



    /**
     */
    protected storiesReadStoriesRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/stories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected storiesReadStoriesFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoriesReadStoriesDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoriesReadStoriesDtoFromJSON(jsonValue));
    }

    /**
     */
    protected storiesReadStoriesRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoriesReadStoriesDto>> => {
        const context = this.storiesReadStoriesRequestOpts();
        return this.storiesReadStoriesFetch(context, initOverrides);
    }

    /**
     */
    storiesReadStories = async (initOverrides?: RequestInit): Promise<StoriesReadStoriesDto> => {
        const response = await this.storiesReadStoriesRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useStoriesReadStories = (() => {
        const key = () => this.storiesReadStoriesRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.storiesReadStoriesFetch(context));
        const fn = (config?: SWRConfiguration<StoriesReadStoriesDto>): SWRResponse<StoriesReadStoriesDto> => {
            return useSWR<StoriesReadStoriesDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    GeocoderResponseDto,
    GeocoderResponseDtoFromJSON,
    GeocoderResponseDtoToJSON,
} from '../models';

export interface GeocoderRequest {
    geocode: string;
    format?: string;
    lang?: string;
    kind?: string;
    results?: number;
    skip?: number;
    ll?: string;
    spn?: string;
    bbox?: string;
    sco?: string;
    type?: string;
    locality?: string;
    country?: string;
    administrativeArea?: string;
    street?: string;
    house?: string;
    postalCode?: string;
}

/**
 * 
 */
export class GeocoderApi extends runtime.BaseAPI {

    /**
     */
    protected geocoderRequestOpts = (requestParameters: GeocoderRequest): runtime.RequestOpts => {
        if (requestParameters.geocode === null || requestParameters.geocode === undefined) {
            throw new runtime.RequiredError('geocode','Required parameter requestParameters.geocode was null or undefined when calling geocoder.');
        }

        const queryParameters: any = {};

        if (requestParameters.geocode !== undefined) {
            queryParameters['geocode'] = requestParameters.geocode;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.kind !== undefined) {
            queryParameters['kind'] = requestParameters.kind;
        }

        if (requestParameters.results !== undefined) {
            queryParameters['results'] = requestParameters.results;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.ll !== undefined) {
            queryParameters['ll'] = requestParameters.ll;
        }

        if (requestParameters.spn !== undefined) {
            queryParameters['spn'] = requestParameters.spn;
        }

        if (requestParameters.bbox !== undefined) {
            queryParameters['bbox'] = requestParameters.bbox;
        }

        if (requestParameters.sco !== undefined) {
            queryParameters['sco'] = requestParameters.sco;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.locality !== undefined) {
            queryParameters['locality'] = requestParameters.locality;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        if (requestParameters.administrativeArea !== undefined) {
            queryParameters['administrative_area'] = requestParameters.administrativeArea;
        }

        if (requestParameters.street !== undefined) {
            queryParameters['street'] = requestParameters.street;
        }

        if (requestParameters.house !== undefined) {
            queryParameters['house'] = requestParameters.house;
        }

        if (requestParameters.postalCode !== undefined) {
            queryParameters['postal_code'] = requestParameters.postalCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/geocoder/by-address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected geocoderFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeocoderResponseDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeocoderResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    protected geocoderRaw = async (requestParameters: GeocoderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeocoderResponseDto>> => {
        const context = this.geocoderRequestOpts(requestParameters);
        return this.geocoderFetch(context, initOverrides);
    }

    /**
     */
    geocoder = async (geocode: string, format?: string, lang?: string, kind?: string, results?: number, skip?: number, ll?: string, spn?: string, bbox?: string, sco?: string, type?: string, locality?: string, country?: string, administrativeArea?: string, street?: string, house?: string, postalCode?: string, initOverrides?: RequestInit): Promise<GeocoderResponseDto> => {
        const response = await this.geocoderRaw({ geocode: geocode, format: format, lang: lang, kind: kind, results: results, skip: skip, ll: ll, spn: spn, bbox: bbox, sco: sco, type: type, locality: locality, country: country, administrativeArea: administrativeArea, street: street, house: house, postalCode: postalCode }, initOverrides);
        return await response.value();
    }


    /**
     */
    useGeocoder = (() => {
        const key = (requestParameters: GeocoderRequest, config?: SWRConfiguration<GeocoderResponseDto>) => this.geocoderRequestOpts(requestParameters);
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.geocoderFetch(context));
        const fn = (requestParameters: GeocoderRequest, config?: SWRConfiguration<GeocoderResponseDto>): SWRResponse<GeocoderResponseDto> => {
            return useSWR<GeocoderResponseDto>(() => key(requestParameters), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

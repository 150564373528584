/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    PaymentFlowAttachReceiptRequestDto,
    PaymentFlowAttachReceiptRequestDtoFromJSON,
    PaymentFlowAttachReceiptRequestDtoToJSON,
    PaymentFlowDto,
    PaymentFlowDtoFromJSON,
    PaymentFlowDtoToJSON,
} from '../models';

export interface PaymentFlowRequest {
    id: string;
}

export interface PaymentFlowAttachReceiptRequest {
    id: string;
    paymentFlowAttachReceiptRequestDto: PaymentFlowAttachReceiptRequestDto;
}

export interface PaymentFlowMarkPaidRequest {
    id: string;
}

/**
 * 
 */
export class PaymentFlowApi extends runtime.BaseAPI {

    /**
     */
    protected paymentFlowRequestOpts = (requestParameters: PaymentFlowRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling paymentFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/payment-flow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected paymentFlowFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFlowDtoFromJSON(jsonValue));
    }

    /**
     */
    protected paymentFlowRaw = async (requestParameters: PaymentFlowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentFlowDto>> => {
        const context = this.paymentFlowRequestOpts(requestParameters);
        return this.paymentFlowFetch(context, initOverrides);
    }

    /**
     */
    paymentFlow = async (id: string, initOverrides?: RequestInit): Promise<PaymentFlowDto> => {
        const response = await this.paymentFlowRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    usePaymentFlow = (() => {
        const key = (id: string) => this.paymentFlowRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.paymentFlowFetch(context));
        const fn = (id: string, config?: SWRConfiguration<PaymentFlowDto>): SWRResponse<PaymentFlowDto> => {
            return useSWR<PaymentFlowDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected paymentFlowAttachReceiptRequestOpts = (requestParameters: PaymentFlowAttachReceiptRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling paymentFlowAttachReceipt.');
        }

        if (requestParameters.paymentFlowAttachReceiptRequestDto === null || requestParameters.paymentFlowAttachReceiptRequestDto === undefined) {
            throw new runtime.RequiredError('paymentFlowAttachReceiptRequestDto','Required parameter requestParameters.paymentFlowAttachReceiptRequestDto was null or undefined when calling paymentFlowAttachReceipt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/payment-flow/{id}/attach-receipt`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentFlowAttachReceiptRequestDtoToJSON(requestParameters.paymentFlowAttachReceiptRequestDto),
        };
    }

    /**
     */
    protected paymentFlowAttachReceiptFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected paymentFlowAttachReceiptRaw = async (requestParameters: PaymentFlowAttachReceiptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.paymentFlowAttachReceiptRequestOpts(requestParameters);
        return this.paymentFlowAttachReceiptFetch(context, initOverrides);
    }

    /**
     */
    paymentFlowAttachReceipt = async (id: string, paymentFlowAttachReceiptRequestDto: PaymentFlowAttachReceiptRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.paymentFlowAttachReceiptRaw({ id: id, paymentFlowAttachReceiptRequestDto: paymentFlowAttachReceiptRequestDto }, initOverrides);
    }



    /**
     */
    protected paymentFlowMarkPaidRequestOpts = (requestParameters: PaymentFlowMarkPaidRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling paymentFlowMarkPaid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/payment-flow/{id}/mark-paid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected paymentFlowMarkPaidFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected paymentFlowMarkPaidRaw = async (requestParameters: PaymentFlowMarkPaidRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.paymentFlowMarkPaidRequestOpts(requestParameters);
        return this.paymentFlowMarkPaidFetch(context, initOverrides);
    }

    /**
     */
    paymentFlowMarkPaid = async (id: string, initOverrides?: RequestInit): Promise<void> => {
        await this.paymentFlowMarkPaidRaw({ id: id }, initOverrides);
    }



}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderManualPaymentUpdateRequestDto
 */
export interface OrderManualPaymentUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrderManualPaymentUpdateRequestDto
     */
    paymentCardId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderManualPaymentUpdateRequestDto
     */
    receiptImageId: string;
}


export function OrderManualPaymentUpdateRequestDtoFromJSON(json: any): OrderManualPaymentUpdateRequestDto {
    return OrderManualPaymentUpdateRequestDtoFromJSONTyped(json, false);
}

export function OrderManualPaymentUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderManualPaymentUpdateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentCardId': json['paymentCardId'],
        'receiptImageId': json['receiptImageId'],
    };
}

export function OrderManualPaymentUpdateRequestDtoToJSON(value?: OrderManualPaymentUpdateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentCardId': value.paymentCardId,
        'receiptImageId': value.receiptImageId,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDetailsDeliveryToMSK {
    usual = 'usual',
    express = 'express',
    shop = 'shop'
}

export function OrderDetailsDeliveryToMSKFromJSON(json: any): OrderDetailsDeliveryToMSK {
    return OrderDetailsDeliveryToMSKFromJSONTyped(json, false);
}

export function OrderDetailsDeliveryToMSKFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailsDeliveryToMSK {
    return json as OrderDetailsDeliveryToMSK;
}

export function OrderDetailsDeliveryToMSKToJSON(value?: OrderDetailsDeliveryToMSK | null): any {
    return value as any;
}


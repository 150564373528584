import { CustomerSizeApi, CustomerSizeCollectionDto } from "shared/api/back/_generated"
import * as runtime from "shared/api/back/_generated/runtime"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"
import { getAuthed } from "shared/api/back/lib"

export class CustomerSizeApiOverridden extends CustomerSizeApi {
  useCustomerSizes = (() => {
    const key = (page?: number, perPage?: number) => {
      if (!getAuthed()) throw new Error("not authed")
      return this.customerSizesRequestOpts({ page: page, perPage: perPage })
    }
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerSizesFetch(context))
    const fn = (
      page?: number,
      perPage?: number,
      config?: SWRConfiguration<CustomerSizeCollectionDto>,
    ): SWRResponse<CustomerSizeCollectionDto> => {
      return useSWR<CustomerSizeCollectionDto>(() => key(page, perPage), fetcher, config)
    }
    fn.key = key
    return fn
  })()
}

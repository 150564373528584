/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthTelegramRequestDto
 */
export interface AuthTelegramRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    authDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTelegramRequestDto
     */
    hash?: string;
}


export function AuthTelegramRequestDtoFromJSON(json: any): AuthTelegramRequestDto {
    return AuthTelegramRequestDtoFromJSONTyped(json, false);
}

export function AuthTelegramRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthTelegramRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'photoUrl': !exists(json, 'photo_url') ? undefined : json['photo_url'],
        'authDate': !exists(json, 'auth_date') ? undefined : json['auth_date'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
    };
}

export function AuthTelegramRequestDtoToJSON(value?: AuthTelegramRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'username': value.username,
        'photo_url': value.photoUrl,
        'auth_date': value.authDate,
        'hash': value.hash,
    };
}


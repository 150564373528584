/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublicCustomerOrderManualPaymentDto,
    PublicCustomerOrderManualPaymentDtoFromJSON,
    PublicCustomerOrderManualPaymentDtoFromJSONTyped,
    PublicCustomerOrderManualPaymentDtoToJSON,
} from './PublicCustomerOrderManualPaymentDto';
import {
    PublicCustomerOrderMpcVipReferPaymentDto,
    PublicCustomerOrderMpcVipReferPaymentDtoFromJSON,
    PublicCustomerOrderMpcVipReferPaymentDtoFromJSONTyped,
    PublicCustomerOrderMpcVipReferPaymentDtoToJSON,
} from './PublicCustomerOrderMpcVipReferPaymentDto';
import {
    PublicOrderOnepaymentsPaymentDto,
    PublicOrderOnepaymentsPaymentDtoFromJSON,
    PublicOrderOnepaymentsPaymentDtoFromJSONTyped,
    PublicOrderOnepaymentsPaymentDtoToJSON,
} from './PublicOrderOnepaymentsPaymentDto';
import {
    PublicOrderYookassaPaymentDto,
    PublicOrderYookassaPaymentDtoFromJSON,
    PublicOrderYookassaPaymentDtoFromJSONTyped,
    PublicOrderYookassaPaymentDtoToJSON,
} from './PublicOrderYookassaPaymentDto';

/**
 * 
 * @export
 * @interface PublicOrderPaymentDto
 */
export interface PublicOrderPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PublicOrderPaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderPaymentDto
     */
    customerOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderPaymentDto
     */
    status: PublicOrderPaymentDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PublicOrderPaymentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderPaymentDto
     */
    type: PublicOrderPaymentDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PublicOrderPaymentDto
     */
    additional: boolean;
    /**
     * 
     * @type {PublicCustomerOrderManualPaymentDto}
     * @memberof PublicOrderPaymentDto
     */
    manual: PublicCustomerOrderManualPaymentDto | null;
    /**
     * 
     * @type {object}
     * @memberof PublicOrderPaymentDto
     */
    mpcVip: object | null;
    /**
     * 
     * @type {PublicCustomerOrderMpcVipReferPaymentDto}
     * @memberof PublicOrderPaymentDto
     */
    mpcVipRefer: PublicCustomerOrderMpcVipReferPaymentDto | null;
    /**
     * 
     * @type {PublicOrderOnepaymentsPaymentDto}
     * @memberof PublicOrderPaymentDto
     */
    onepayments: PublicOrderOnepaymentsPaymentDto | null;
    /**
     * 
     * @type {PublicOrderYookassaPaymentDto}
     * @memberof PublicOrderPaymentDto
     */
    yookassa: PublicOrderYookassaPaymentDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicOrderPaymentDto
     */
    isFromBot: boolean;
}

/**
* @export
* @enum {string}
*/
export type PublicOrderPaymentDtoStatusEnum =
    | 'NEW'
    | 'PAID'
    | 'CANCELED'
    | 'REFUND'
    | 'MARK_PAID'
    | 'REJECTED'
    | 'REJECTED_CONFIRMED'
    | 'APPELLATION'
/**
* @export
* @enum {string}
*/
export type PublicOrderPaymentDtoTypeEnum =
    | 'MANUAL'
    | 'MPCVIP'
    | 'MPCVIP_REFER'
    | 'CART'
    | 'ROCKET_WORK'
    | 'ROCKET_WORK_TKB'
    | 'ONE_PAYMENTS'
    | 'ONE_PAYMENTS_CUSTOM'
    | 'COMPANY_MONEY'
    | 'CUSTOM'
    | 'CASH'
    | 'YOOKASSA'
    | 'AIFORY'
    | 'LEGIT_PAY_CUSTOM_FORM'
    | 'LEGIT_PAY_SIMPLE_FORM'
    | 'AIFORY_CUSTOM'
    | 'X_PAY'
    | 'SBC_TECH'
    | 'ALFA_BANK'



export function PublicOrderPaymentDtoFromJSON(json: any): PublicOrderPaymentDto {
    return PublicOrderPaymentDtoFromJSONTyped(json, false);
}

export function PublicOrderPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicOrderPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerOrderId': json['customerOrderId'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'type': json['type'],
        'additional': json['additional'],
        'manual': PublicCustomerOrderManualPaymentDtoFromJSON(json['manual']),
        'mpcVip': json['mpcVip'],
        'mpcVipRefer': PublicCustomerOrderMpcVipReferPaymentDtoFromJSON(json['mpcVipRefer']),
        'onepayments': PublicOrderOnepaymentsPaymentDtoFromJSON(json['onepayments']),
        'yookassa': PublicOrderYookassaPaymentDtoFromJSON(json['yookassa']),
        'isFromBot': json['isFromBot'],
    };
}

export function PublicOrderPaymentDtoToJSON(value?: PublicOrderPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerOrderId': value.customerOrderId,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'type': value.type,
        'additional': value.additional,
        'manual': PublicCustomerOrderManualPaymentDtoToJSON(value.manual),
        'mpcVip': value.mpcVip,
        'mpcVipRefer': PublicCustomerOrderMpcVipReferPaymentDtoToJSON(value.mpcVipRefer),
        'onepayments': PublicOrderOnepaymentsPaymentDtoToJSON(value.onepayments),
        'yookassa': PublicOrderYookassaPaymentDtoToJSON(value.yookassa),
        'isFromBot': value.isFromBot,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderStatus {
    NEW = 'NEW',
    CALCULATED = 'CALCULATED',
    DISABLED = 'DISABLED',
    PAY_CHECK = 'PAY_CHECK',
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    ON_PURCHASE = 'ON_PURCHASE',
    PURCHASED = 'PURCHASED',
    ON_CHINA_WAREHOUSE = 'ON_CHINA_WAREHOUSE',
    SENT_TO_MOSCOW = 'SENT_TO_MOSCOW',
    ON_MOSCOW_WAREHOUSE = 'ON_MOSCOW_WAREHOUSE',
    RU_DELIVERY = 'RU_DELIVERY',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}

export function OrderStatusFromJSON(json: any): OrderStatus {
    return OrderStatusFromJSONTyped(json, false);
}

export function OrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatus {
    return json as OrderStatus;
}

export function OrderStatusToJSON(value?: OrderStatus | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowAttachReceiptRequestDto
 */
export interface PaymentFlowAttachReceiptRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowAttachReceiptRequestDto
     */
    imageId: string;
}


export function PaymentFlowAttachReceiptRequestDtoFromJSON(json: any): PaymentFlowAttachReceiptRequestDto {
    return PaymentFlowAttachReceiptRequestDtoFromJSONTyped(json, false);
}

export function PaymentFlowAttachReceiptRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowAttachReceiptRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageId': json['imageId'],
    };
}

export function PaymentFlowAttachReceiptRequestDtoToJSON(value?: PaymentFlowAttachReceiptRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageId': value.imageId,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegitPayMethodType,
    LegitPayMethodTypeFromJSON,
    LegitPayMethodTypeFromJSONTyped,
    LegitPayMethodTypeToJSON,
} from './LegitPayMethodType';

/**
 * 
 * @export
 * @interface OrderLegitPayPaymentCustomFormDto
 */
export interface OrderLegitPayPaymentCustomFormDto {
    /**
     * 
     * @type {LegitPayMethodType}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    externalPayMethodType: LegitPayMethodType;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    status: OrderLegitPayPaymentCustomFormDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    externalID: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    externalCard: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    externalHolder: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentCustomFormDto
     */
    externalBank: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderLegitPayPaymentCustomFormDtoStatusEnum =
    | 'NEW'
    | 'GENERATED'
    | 'MARK_PAID'
    | 'PAID'
    | 'REJECTED'
    | 'CANCELED'



export function OrderLegitPayPaymentCustomFormDtoFromJSON(json: any): OrderLegitPayPaymentCustomFormDto {
    return OrderLegitPayPaymentCustomFormDtoFromJSONTyped(json, false);
}

export function OrderLegitPayPaymentCustomFormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLegitPayPaymentCustomFormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalPayMethodType': LegitPayMethodTypeFromJSON(json['externalPayMethodType']),
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'externalID': json['externalID'],
        'externalCard': json['externalCard'],
        'externalHolder': json['externalHolder'],
        'externalBank': json['externalBank'],
    };
}

export function OrderLegitPayPaymentCustomFormDtoToJSON(value?: OrderLegitPayPaymentCustomFormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalPayMethodType': LegitPayMethodTypeToJSON(value.externalPayMethodType),
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'externalID': value.externalID,
        'externalCard': value.externalCard,
        'externalHolder': value.externalHolder,
        'externalBank': value.externalBank,
    };
}


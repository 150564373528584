/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceUpdateDto
 */
export interface PriceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    spuId: number;
}


export function PriceUpdateDtoFromJSON(json: any): PriceUpdateDto {
    return PriceUpdateDtoFromJSONTyped(json, false);
}

export function PriceUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spuId': json['spuId'],
    };
}

export function PriceUpdateDtoToJSON(value?: PriceUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spuId': value.spuId,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeliveryPointDto,
    DeliveryPointDtoFromJSON,
    DeliveryPointDtoFromJSONTyped,
    DeliveryPointDtoToJSON,
} from './DeliveryPointDto';

/**
 * 
 * @export
 * @interface DeliveryPointsDto
 */
export interface DeliveryPointsDto {
    /**
     * 
     * @type {Array<DeliveryPointDto>}
     * @memberof DeliveryPointsDto
     */
    items: Array<DeliveryPointDto>;
}


export function DeliveryPointsDtoFromJSON(json: any): DeliveryPointsDto {
    return DeliveryPointsDtoFromJSONTyped(json, false);
}

export function DeliveryPointsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryPointsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(DeliveryPointDtoFromJSON)),
    };
}

export function DeliveryPointsDtoToJSON(value?: DeliveryPointsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(DeliveryPointDtoToJSON)),
    };
}


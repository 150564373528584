/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerOrderPaymentStatus {
    NEW = 'NEW',
    PAID = 'PAID',
    CANCELED = 'CANCELED',
    REFUND = 'REFUND',
    MARK_PAID = 'MARK_PAID',
    REJECTED = 'REJECTED',
    REJECTED_CONFIRMED = 'REJECTED_CONFIRMED',
    APPELLATION = 'APPELLATION'
}

export function CustomerOrderPaymentStatusFromJSON(json: any): CustomerOrderPaymentStatus {
    return CustomerOrderPaymentStatusFromJSONTyped(json, false);
}

export function CustomerOrderPaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderPaymentStatus {
    return json as CustomerOrderPaymentStatus;
}

export function CustomerOrderPaymentStatusToJSON(value?: CustomerOrderPaymentStatus | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    FavoriteAddRequestDto,
    FavoriteAddRequestDtoFromJSON,
    FavoriteAddRequestDtoToJSON,
    FavoriteDto,
    FavoriteDtoFromJSON,
    FavoriteDtoToJSON,
} from '../models';

export interface FavoriteAddRequest {
    favoriteAddRequestDto: FavoriteAddRequestDto;
}

export interface FavoriteRemoveRequest {
    spuId: number;
}

/**
 * 
 */
export class FavoriteApi extends runtime.BaseAPI {

    /**
     */
    protected favoriteAddRequestOpts = (requestParameters: FavoriteAddRequest): runtime.RequestOpts => {
        if (requestParameters.favoriteAddRequestDto === null || requestParameters.favoriteAddRequestDto === undefined) {
            throw new runtime.RequiredError('favoriteAddRequestDto','Required parameter requestParameters.favoriteAddRequestDto was null or undefined when calling favoriteAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteAddRequestDtoToJSON(requestParameters.favoriteAddRequestDto),
        };
    }

    /**
     */
    protected favoriteAddFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected favoriteAddRaw = async (requestParameters: FavoriteAddRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.favoriteAddRequestOpts(requestParameters);
        return this.favoriteAddFetch(context, initOverrides);
    }

    /**
     */
    favoriteAdd = async (favoriteAddRequestDto: FavoriteAddRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.favoriteAddRaw({ favoriteAddRequestDto: favoriteAddRequestDto }, initOverrides);
    }



    /**
     */
    protected favoriteRemoveRequestOpts = (requestParameters: FavoriteRemoveRequest): runtime.RequestOpts => {
        if (requestParameters.spuId === null || requestParameters.spuId === undefined) {
            throw new runtime.RequiredError('spuId','Required parameter requestParameters.spuId was null or undefined when calling favoriteRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/favorite/{spuId}`.replace(`{${"spuId"}}`, encodeURIComponent(String(requestParameters.spuId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected favoriteRemoveFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected favoriteRemoveRaw = async (requestParameters: FavoriteRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.favoriteRemoveRequestOpts(requestParameters);
        return this.favoriteRemoveFetch(context, initOverrides);
    }

    /**
     */
    favoriteRemove = async (spuId: number, initOverrides?: RequestInit): Promise<void> => {
        await this.favoriteRemoveRaw({ spuId: spuId }, initOverrides);
    }



    /**
     */
    protected favoritesRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/favorite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected favoritesFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FavoriteDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FavoriteDtoFromJSON));
    }

    /**
     */
    protected favoritesRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FavoriteDto>>> => {
        const context = this.favoritesRequestOpts();
        return this.favoritesFetch(context, initOverrides);
    }

    /**
     */
    favorites = async (initOverrides?: RequestInit): Promise<Array<FavoriteDto>> => {
        const response = await this.favoritesRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useFavorites = (() => {
        const key = () => this.favoritesRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.favoritesFetch(context));
        const fn = (config?: SWRConfiguration<Array<FavoriteDto>>): SWRResponse<Array<FavoriteDto>> => {
            return useSWR<Array<FavoriteDto>>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

import React from "react"
import qs from "qs"
import { createStrapiImage, IStrapiImage } from "./strapi-image"

export class BaseStrapiApi {
  readonly baseUrl: string
  readonly Image: (props: IStrapiImage) => React.ReactElement

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.Image = createStrapiImage(this.baseUrl)
  }

  protected request = async (url: string, query?: any) => {
    let fullUrl = this.baseUrl + url
    if (query) fullUrl += "?" + qs.stringify(query)

    const res = await fetch(fullUrl, { next: { revalidate: 60 } })
    return res.json()
  }
}

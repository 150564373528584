/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderStatusSwitchDatesDto
 */
export interface OrderStatusSwitchDatesDto {
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    newAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    calculatedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    payCheckAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    waitConfirmAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    onPurchaseAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    purchasedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    onChinaWarehouseAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    sentToMoscowAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    onMoscowWarehouseAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    ruDeliveryAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    completedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    canceledAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderStatusSwitchDatesDto
     */
    disabledAt: Date | null;
}


export function OrderStatusSwitchDatesDtoFromJSON(json: any): OrderStatusSwitchDatesDto {
    return OrderStatusSwitchDatesDtoFromJSONTyped(json, false);
}

export function OrderStatusSwitchDatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatusSwitchDatesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newAt': (json['newAt'] === null ? null : new Date(json['newAt'])),
        'calculatedAt': (json['calculatedAt'] === null ? null : new Date(json['calculatedAt'])),
        'payCheckAt': (json['payCheckAt'] === null ? null : new Date(json['payCheckAt'])),
        'waitConfirmAt': (json['waitConfirmAt'] === null ? null : new Date(json['waitConfirmAt'])),
        'onPurchaseAt': (json['onPurchaseAt'] === null ? null : new Date(json['onPurchaseAt'])),
        'purchasedAt': (json['purchasedAt'] === null ? null : new Date(json['purchasedAt'])),
        'onChinaWarehouseAt': (json['onChinaWarehouseAt'] === null ? null : new Date(json['onChinaWarehouseAt'])),
        'sentToMoscowAt': (json['sentToMoscowAt'] === null ? null : new Date(json['sentToMoscowAt'])),
        'onMoscowWarehouseAt': (json['onMoscowWarehouseAt'] === null ? null : new Date(json['onMoscowWarehouseAt'])),
        'ruDeliveryAt': (json['ruDeliveryAt'] === null ? null : new Date(json['ruDeliveryAt'])),
        'completedAt': (json['completedAt'] === null ? null : new Date(json['completedAt'])),
        'canceledAt': (json['canceledAt'] === null ? null : new Date(json['canceledAt'])),
        'disabledAt': (json['disabledAt'] === null ? null : new Date(json['disabledAt'])),
    };
}

export function OrderStatusSwitchDatesDtoToJSON(value?: OrderStatusSwitchDatesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newAt': (value.newAt === null ? null : value.newAt.toISOString()),
        'calculatedAt': (value.calculatedAt === null ? null : value.calculatedAt.toISOString()),
        'payCheckAt': (value.payCheckAt === null ? null : value.payCheckAt.toISOString()),
        'waitConfirmAt': (value.waitConfirmAt === null ? null : value.waitConfirmAt.toISOString()),
        'onPurchaseAt': (value.onPurchaseAt === null ? null : value.onPurchaseAt.toISOString()),
        'purchasedAt': (value.purchasedAt === null ? null : value.purchasedAt.toISOString()),
        'onChinaWarehouseAt': (value.onChinaWarehouseAt === null ? null : value.onChinaWarehouseAt.toISOString()),
        'sentToMoscowAt': (value.sentToMoscowAt === null ? null : value.sentToMoscowAt.toISOString()),
        'onMoscowWarehouseAt': (value.onMoscowWarehouseAt === null ? null : value.onMoscowWarehouseAt.toISOString()),
        'ruDeliveryAt': (value.ruDeliveryAt === null ? null : value.ruDeliveryAt.toISOString()),
        'completedAt': (value.completedAt === null ? null : value.completedAt.toISOString()),
        'canceledAt': (value.canceledAt === null ? null : value.canceledAt.toISOString()),
        'disabledAt': (value.disabledAt === null ? null : value.disabledAt.toISOString()),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductSkuAvailabilityDto
 */
export interface CatalogProductSkuAvailabilityDto {
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuAvailabilityDto
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuAvailabilityDto
   */
  rubPrice: number
}

export function CatalogProductSkuAvailabilityDtoFromJSON(json: any): CatalogProductSkuAvailabilityDto {
  return CatalogProductSkuAvailabilityDtoFromJSONTyped(json, false)
}

export function CatalogProductSkuAvailabilityDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductSkuAvailabilityDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    rubPrice: json["rubPrice"],
  }
}

export function CatalogProductSkuAvailabilityDtoToJSON(value?: CatalogProductSkuAvailabilityDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    rubPrice: value.rubPrice,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    ImageDto,
    ImageDtoFromJSON,
    ImageDtoToJSON,
} from '../models';

export interface ImageRequest {
    id: string;
}

export interface UploadImageRequest {
    file?: Blob;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     */
    protected imageRequestOpts = (requestParameters: ImageRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling image.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected imageFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImageDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageDtoFromJSON(jsonValue));
    }

    /**
     */
    protected imageRaw = async (requestParameters: ImageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImageDto>> => {
        const context = this.imageRequestOpts(requestParameters);
        return this.imageFetch(context, initOverrides);
    }

    /**
     */
    image = async (id: string, initOverrides?: RequestInit): Promise<ImageDto> => {
        const response = await this.imageRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useImage = (() => {
        const key = (id: string) => this.imageRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.imageFetch(context));
        const fn = (id: string, config?: SWRConfiguration<ImageDto>): SWRResponse<ImageDto> => {
            return useSWR<ImageDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected uploadImageRequestOpts = (requestParameters: UploadImageRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        return {
            path: `/api/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
    }

    /**
     */
    protected uploadImageFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImageDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageDtoFromJSON(jsonValue));
    }

    /**
     */
    protected uploadImageRaw = async (requestParameters: UploadImageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImageDto>> => {
        const context = this.uploadImageRequestOpts(requestParameters);
        return this.uploadImageFetch(context, initOverrides);
    }

    /**
     */
    uploadImage = async (file?: Blob, initOverrides?: RequestInit): Promise<ImageDto> => {
        const response = await this.uploadImageRaw({ file: file }, initOverrides);
        return await response.value();
    }



}

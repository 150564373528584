/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerSizeAddDto
 */
export interface CustomerSizeAddDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSizeAddDto
     */
    sizeType: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSizeAddDto
     */
    sizeValue: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerSizeAddDto
     */
    createdAt?: Date;
}


export function CustomerSizeAddDtoFromJSON(json: any): CustomerSizeAddDto {
    return CustomerSizeAddDtoFromJSONTyped(json, false);
}

export function CustomerSizeAddDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSizeAddDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sizeType': json['sizeType'],
        'sizeValue': json['sizeValue'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function CustomerSizeAddDtoToJSON(value?: CustomerSizeAddDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sizeType': value.sizeType,
        'sizeValue': value.sizeValue,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


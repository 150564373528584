/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderReviewDto,
    CustomerOrderReviewDtoFromJSON,
    CustomerOrderReviewDtoFromJSONTyped,
    CustomerOrderReviewDtoToJSON,
} from './CustomerOrderReviewDto';

/**
 * 
 * @export
 * @interface CustomerOrderReviewCollectionDto
 */
export interface CustomerOrderReviewCollectionDto {
    /**
     * 
     * @type {Array<CustomerOrderReviewDto>}
     * @memberof CustomerOrderReviewCollectionDto
     */
    items: Array<CustomerOrderReviewDto>;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderReviewCollectionDto
     */
    total: number;
}


export function CustomerOrderReviewCollectionDtoFromJSON(json: any): CustomerOrderReviewCollectionDto {
    return CustomerOrderReviewCollectionDtoFromJSONTyped(json, false);
}

export function CustomerOrderReviewCollectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderReviewCollectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CustomerOrderReviewDtoFromJSON)),
        'total': json['total'],
    };
}

export function CustomerOrderReviewCollectionDtoToJSON(value?: CustomerOrderReviewCollectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CustomerOrderReviewDtoToJSON)),
        'total': value.total,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerPointLevel {
    BEGINNER = 'BEGINNER',
    EXPERT = 'EXPERT',
    MEGA = 'MEGA',
    PRO = 'PRO',
    PREMIUM = 'PREMIUM'
}

export function CustomerPointLevelFromJSON(json: any): CustomerPointLevel {
    return CustomerPointLevelFromJSONTyped(json, false);
}

export function CustomerPointLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPointLevel {
    return json as CustomerPointLevel;
}

export function CustomerPointLevelToJSON(value?: CustomerPointLevel | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPointsOperationReferralCustomerDto
 */
export interface CustomerPointsOperationReferralCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationReferralCustomerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationReferralCustomerDto
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationReferralCustomerDto
     */
    telegramId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationReferralCustomerDto
     */
    phone: string | null;
}


export function CustomerPointsOperationReferralCustomerDtoFromJSON(json: any): CustomerPointsOperationReferralCustomerDto {
    return CustomerPointsOperationReferralCustomerDtoFromJSONTyped(json, false);
}

export function CustomerPointsOperationReferralCustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPointsOperationReferralCustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'telegramId': json['telegramId'],
        'phone': json['phone'],
    };
}

export function CustomerPointsOperationReferralCustomerDtoToJSON(value?: CustomerPointsOperationReferralCustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'telegramId': value.telegramId,
        'phone': value.phone,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderAiforyPaymentCustomDto
 */
export interface OrderAiforyPaymentCustomDto {
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    status: OrderAiforyPaymentCustomDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderAiforyPaymentCustomDto
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderAiforyPaymentCustomDto
     */
    externalID: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    accountName: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    accountNumber: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAiforyPaymentCustomDto
     */
    recipientCardIssuerID: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderAiforyPaymentCustomDto
     */
    expiresAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAiforyPaymentCustomDto
     */
    methodId: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAiforyPaymentCustomDto
     */
    methodName: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderAiforyPaymentCustomDtoStatusEnum =
    | 'NEW'
    | 'GENERATED'
    | 'PAID'
    | 'REJECTED'
    | 'CANCELED'



export function OrderAiforyPaymentCustomDtoFromJSON(json: any): OrderAiforyPaymentCustomDto {
    return OrderAiforyPaymentCustomDtoFromJSONTyped(json, false);
}

export function OrderAiforyPaymentCustomDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderAiforyPaymentCustomDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'externalID': json['externalID'],
        'accountName': json['accountName'],
        'accountNumber': json['accountNumber'],
        'recipientCardIssuerID': json['recipientCardIssuerID'],
        'expiresAt': (json['expiresAt'] === null ? null : new Date(json['expiresAt'])),
        'methodId': json['methodId'],
        'methodName': json['methodName'],
    };
}

export function OrderAiforyPaymentCustomDtoToJSON(value?: OrderAiforyPaymentCustomDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'externalID': value.externalID,
        'accountName': value.accountName,
        'accountNumber': value.accountNumber,
        'recipientCardIssuerID': value.recipientCardIssuerID,
        'expiresAt': (value.expiresAt === null ? null : value.expiresAt.toISOString()),
        'methodId': value.methodId,
        'methodName': value.methodName,
    };
}


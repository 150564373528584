/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderInfoDeliveryType,
    OrderInfoDeliveryTypeFromJSON,
    OrderInfoDeliveryTypeFromJSONTyped,
    OrderInfoDeliveryTypeToJSON,
} from './OrderInfoDeliveryType';

/**
 * 
 * @export
 * @interface CustomerUpdateDelivery
 */
export interface CustomerUpdateDelivery {
    /**
     * 
     * @type {OrderInfoDeliveryType}
     * @memberof CustomerUpdateDelivery
     */
    deliveryType?: OrderInfoDeliveryType;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryFio?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerUpdateDelivery
     */
    deliveryCity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryIssuingWarehouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryBoxberryCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryBoxbberryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateDelivery
     */
    deliveryBoxberryIssuingWarehouse?: string | null;
}


export function CustomerUpdateDeliveryFromJSON(json: any): CustomerUpdateDelivery {
    return CustomerUpdateDeliveryFromJSONTyped(json, false);
}

export function CustomerUpdateDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUpdateDelivery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryType': !exists(json, 'deliveryType') ? undefined : OrderInfoDeliveryTypeFromJSON(json['deliveryType']),
        'deliveryPhone': !exists(json, 'deliveryPhone') ? undefined : json['deliveryPhone'],
        'deliveryFio': !exists(json, 'deliveryFio') ? undefined : json['deliveryFio'],
        'deliveryCity': !exists(json, 'deliveryCity') ? undefined : json['deliveryCity'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : json['deliveryAddress'],
        'deliveryIssuingWarehouse': !exists(json, 'deliveryIssuingWarehouse') ? undefined : json['deliveryIssuingWarehouse'],
        'deliveryBoxberryCity': !exists(json, 'deliveryBoxberryCity') ? undefined : json['deliveryBoxberryCity'],
        'deliveryBoxbberryAddress': !exists(json, 'deliveryBoxbberryAddress') ? undefined : json['deliveryBoxbberryAddress'],
        'deliveryBoxberryIssuingWarehouse': !exists(json, 'deliveryBoxberryIssuingWarehouse') ? undefined : json['deliveryBoxberryIssuingWarehouse'],
    };
}

export function CustomerUpdateDeliveryToJSON(value?: CustomerUpdateDelivery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryType': OrderInfoDeliveryTypeToJSON(value.deliveryType),
        'deliveryPhone': value.deliveryPhone,
        'deliveryFio': value.deliveryFio,
        'deliveryCity': value.deliveryCity,
        'deliveryAddress': value.deliveryAddress,
        'deliveryIssuingWarehouse': value.deliveryIssuingWarehouse,
        'deliveryBoxberryCity': value.deliveryBoxberryCity,
        'deliveryBoxbberryAddress': value.deliveryBoxbberryAddress,
        'deliveryBoxberryIssuingWarehouse': value.deliveryBoxberryIssuingWarehouse,
    };
}


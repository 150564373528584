/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductSkuSizeDto
 */
export interface CatalogProductSkuSizeDto {
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  primary?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  eu?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  fr?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  size?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  uk?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  us?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  ru?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductSkuSizeDto
   */
  it?: string
}

export function CatalogProductSkuSizeDtoFromJSON(json: any): CatalogProductSkuSizeDto {
  return CatalogProductSkuSizeDtoFromJSONTyped(json, false)
}

export function CatalogProductSkuSizeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductSkuSizeDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    primary: !exists(json, "primary") ? undefined : json["primary"],
    eu: !exists(json, "eu") ? undefined : json["eu"],
    fr: !exists(json, "fr") ? undefined : json["fr"],
    size: !exists(json, "size") ? undefined : json["size"],
    uk: !exists(json, "uk") ? undefined : json["uk"],
    us: !exists(json, "us") ? undefined : json["us"],
    ru: !exists(json, "ru") ? undefined : json["ru"],
    it: !exists(json, "it") ? undefined : json["it"],
  }
}

export function CatalogProductSkuSizeDtoToJSON(value?: CatalogProductSkuSizeDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    primary: value.primary,
    eu: value.eu,
    fr: value.fr,
    size: value.size,
    uk: value.uk,
    us: value.us,
    ru: value.ru,
    it: value.it,
  }
}

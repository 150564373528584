/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoriesReadStoriesDto
 */
export interface StoriesReadStoriesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof StoriesReadStoriesDto
     */
    urls: Array<string>;
}


export function StoriesReadStoriesDtoFromJSON(json: any): StoriesReadStoriesDto {
    return StoriesReadStoriesDtoFromJSONTyped(json, false);
}

export function StoriesReadStoriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoriesReadStoriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urls': json['urls'],
    };
}

export function StoriesReadStoriesDtoToJSON(value?: StoriesReadStoriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urls': value.urls,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CatalogProductPriceAvailability {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  UNKNOWN = "UNKNOWN",
}

export function CatalogProductPriceAvailabilityFromJSON(json: any): CatalogProductPriceAvailability {
  return CatalogProductPriceAvailabilityFromJSONTyped(json, false)
}

export function CatalogProductPriceAvailabilityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductPriceAvailability {
  return json as CatalogProductPriceAvailability
}

export function CatalogProductPriceAvailabilityToJSON(value?: CatalogProductPriceAvailability | null): any {
  return value as any
}

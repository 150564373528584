/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowYooKassaWidgetDto
 */
export interface PaymentFlowYooKassaWidgetDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowYooKassaWidgetDto
     */
    confirmationToken: string;
}


export function PaymentFlowYooKassaWidgetDtoFromJSON(json: any): PaymentFlowYooKassaWidgetDto {
    return PaymentFlowYooKassaWidgetDtoFromJSONTyped(json, false);
}

export function PaymentFlowYooKassaWidgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowYooKassaWidgetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmationToken': json['confirmationToken'],
    };
}

export function PaymentFlowYooKassaWidgetDtoToJSON(value?: PaymentFlowYooKassaWidgetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmationToken': value.confirmationToken,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowCreatePaymentRequestDto
 */
export interface PaymentFlowCreatePaymentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowCreatePaymentRequestDto
     */
    method: PaymentFlowCreatePaymentRequestDtoMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowCreatePaymentRequestDto
     */
    bank?: PaymentFlowCreatePaymentRequestDtoBankEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowCreatePaymentRequestDto
     */
    successRedirectUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowCreatePaymentRequestDto
     */
    failureRedirectUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentFlowCreatePaymentRequestDto
     */
    cashback?: boolean;
}

/**
* @export
* @enum {string}
*/
export type PaymentFlowCreatePaymentRequestDtoMethodEnum =
    | 'TRANSFER_BY_PHONE'
    | 'TRANSFER_TO_CARD'
    | 'SBP'
    | 'CARD'
    | 'EXTERNAL'
    | 'NSPK_LINK'
    | 'YOO_KASSA_WIDGET'
/**
* @export
* @enum {string}
*/
export type PaymentFlowCreatePaymentRequestDtoBankEnum =
    | 'sberbank'
    | 'tinkoff'
    | 'alfa'
    | 'vtb'
    | 'sovkombank'
    | 'rosselxozbank'
    | 'psb'
    | 'raiffeisen'



export function PaymentFlowCreatePaymentRequestDtoFromJSON(json: any): PaymentFlowCreatePaymentRequestDto {
    return PaymentFlowCreatePaymentRequestDtoFromJSONTyped(json, false);
}

export function PaymentFlowCreatePaymentRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowCreatePaymentRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': json['method'],
        'bank': !exists(json, 'bank') ? undefined : json['bank'],
        'successRedirectUrl': json['successRedirectUrl'],
        'failureRedirectUrl': json['failureRedirectUrl'],
        'cashback': !exists(json, 'cashback') ? undefined : json['cashback'],
    };
}

export function PaymentFlowCreatePaymentRequestDtoToJSON(value?: PaymentFlowCreatePaymentRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
        'bank': value.bank,
        'successRedirectUrl': value.successRedirectUrl,
        'failureRedirectUrl': value.failureRedirectUrl,
        'cashback': value.cashback,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    PriceUpdateDto,
    PriceUpdateDtoFromJSON,
    PriceUpdateDtoToJSON,
} from '../models';

export interface PriceUpdateRequest {
    priceUpdateDto: PriceUpdateDto;
}

/**
 * 
 */
export class PriceApi extends runtime.BaseAPI {

    /**
     */
    protected priceUpdateRequestOpts = (requestParameters: PriceUpdateRequest): runtime.RequestOpts => {
        if (requestParameters.priceUpdateDto === null || requestParameters.priceUpdateDto === undefined) {
            throw new runtime.RequiredError('priceUpdateDto','Required parameter requestParameters.priceUpdateDto was null or undefined when calling priceUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/price/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceUpdateDtoToJSON(requestParameters.priceUpdateDto),
        };
    }

    /**
     */
    protected priceUpdateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected priceUpdateRaw = async (requestParameters: PriceUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.priceUpdateRequestOpts(requestParameters);
        return this.priceUpdateFetch(context, initOverrides);
    }

    /**
     */
    priceUpdate = async (priceUpdateDto: PriceUpdateDto, initOverrides?: RequestInit): Promise<void> => {
        await this.priceUpdateRaw({ priceUpdateDto: priceUpdateDto }, initOverrides);
    }



}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCreateV2DeliveryFromMskRecipientDto
 */
export interface OrderCreateV2DeliveryFromMskRecipientDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskRecipientDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskRecipientDto
     */
    phone?: string;
}


export function OrderCreateV2DeliveryFromMskRecipientDtoFromJSON(json: any): OrderCreateV2DeliveryFromMskRecipientDto {
    return OrderCreateV2DeliveryFromMskRecipientDtoFromJSONTyped(json, false);
}

export function OrderCreateV2DeliveryFromMskRecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2DeliveryFromMskRecipientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function OrderCreateV2DeliveryFromMskRecipientDtoToJSON(value?: OrderCreateV2DeliveryFromMskRecipientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phone': value.phone,
    };
}


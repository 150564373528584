import { CatalogApi, Configuration } from "./_generated"
import { CatalogApiConfig } from "./config"
import { CatalogInfiniteApi } from "./infinity"

export class BackCatalogApi {
  constructor(private config: Configuration = new CatalogApiConfig()) {}

  readonly catalog = new CatalogApi(this.config)
  readonly catalogInfinity = new CatalogInfiniteApi(this.config)
}

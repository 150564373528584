/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerProductViewDto,
    CustomerProductViewDtoFromJSON,
    CustomerProductViewDtoFromJSONTyped,
    CustomerProductViewDtoToJSON,
} from './CustomerProductViewDto';

/**
 * 
 * @export
 * @interface CustomerProductViewCollectionDto
 */
export interface CustomerProductViewCollectionDto {
    /**
     * 
     * @type {Array<CustomerProductViewDto>}
     * @memberof CustomerProductViewCollectionDto
     */
    items: Array<CustomerProductViewDto>;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductViewCollectionDto
     */
    total: number;
}


export function CustomerProductViewCollectionDtoFromJSON(json: any): CustomerProductViewCollectionDto {
    return CustomerProductViewCollectionDtoFromJSONTyped(json, false);
}

export function CustomerProductViewCollectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerProductViewCollectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CustomerProductViewDtoFromJSON)),
        'total': json['total'],
    };
}

export function CustomerProductViewCollectionDtoToJSON(value?: CustomerProductViewCollectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CustomerProductViewDtoToJSON)),
        'total': value.total,
    };
}


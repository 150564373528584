/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerSizeAddDto,
    CustomerSizeAddDtoFromJSON,
    CustomerSizeAddDtoFromJSONTyped,
    CustomerSizeAddDtoToJSON,
} from './CustomerSizeAddDto';

/**
 * 
 * @export
 * @interface CustomerSizeAddCollectionRequestDto
 */
export interface CustomerSizeAddCollectionRequestDto {
    /**
     * 
     * @type {Array<CustomerSizeAddDto>}
     * @memberof CustomerSizeAddCollectionRequestDto
     */
    items: Array<CustomerSizeAddDto>;
}


export function CustomerSizeAddCollectionRequestDtoFromJSON(json: any): CustomerSizeAddCollectionRequestDto {
    return CustomerSizeAddCollectionRequestDtoFromJSONTyped(json, false);
}

export function CustomerSizeAddCollectionRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSizeAddCollectionRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CustomerSizeAddDtoFromJSON)),
    };
}

export function CustomerSizeAddCollectionRequestDtoToJSON(value?: CustomerSizeAddCollectionRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CustomerSizeAddDtoToJSON)),
    };
}


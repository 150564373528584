/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderUpdateSkuRequestDto
 */
export interface OrderUpdateSkuRequestDto {
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateSkuRequestDto
     */
    skuId: number;
}


export function OrderUpdateSkuRequestDtoFromJSON(json: any): OrderUpdateSkuRequestDto {
    return OrderUpdateSkuRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateSkuRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateSkuRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuId': json['skuId'],
    };
}

export function OrderUpdateSkuRequestDtoToJSON(value?: OrderUpdateSkuRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuId': value.skuId,
    };
}


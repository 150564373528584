/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerDto,
    CustomerDtoFromJSON,
    CustomerDtoToJSON,
    CustomerPointsOperationType,
    CustomerPointsOperationTypeFromJSON,
    CustomerPointsOperationTypeToJSON,
    CustomerPointsOperationsDto,
    CustomerPointsOperationsDtoFromJSON,
    CustomerPointsOperationsDtoToJSON,
    CustomerRefStatisticDto,
    CustomerRefStatisticDtoFromJSON,
    CustomerRefStatisticDtoToJSON,
    CustomerUnicornBonusDto,
    CustomerUnicornBonusDtoFromJSON,
    CustomerUnicornBonusDtoToJSON,
    CustomerUpdateActionNotificationsRequestDto,
    CustomerUpdateActionNotificationsRequestDtoFromJSON,
    CustomerUpdateActionNotificationsRequestDtoToJSON,
    CustomerUpdateDelivery,
    CustomerUpdateDeliveryFromJSON,
    CustomerUpdateDeliveryToJSON,
    CustomerUpdateGoogleAnalyticsRequestDto,
    CustomerUpdateGoogleAnalyticsRequestDtoFromJSON,
    CustomerUpdateGoogleAnalyticsRequestDtoToJSON,
    CustomerUpdatePostHogRequestDto,
    CustomerUpdatePostHogRequestDtoFromJSON,
    CustomerUpdatePostHogRequestDtoToJSON,
    CustomerUpdateUtmRequestDto,
    CustomerUpdateUtmRequestDtoFromJSON,
    CustomerUpdateUtmRequestDtoToJSON,
    OrderCreateV2DeliveryFromMskDto,
    OrderCreateV2DeliveryFromMskDtoFromJSON,
    OrderCreateV2DeliveryFromMskDtoToJSON,
} from '../models';

export interface CustomerPointsOperationsRequest {
    types?: Array<CustomerPointsOperationType>;
    page?: number;
    perPage?: number;
}

export interface CustomerUpdateRequest {
    customerUpdateUtmRequestDto: CustomerUpdateUtmRequestDto;
}

export interface CustomerUpdateActionNotificationsRequest {
    customerUpdateActionNotificationsRequestDto: CustomerUpdateActionNotificationsRequestDto;
}

export interface CustomerUpdateDeliveryRequest {
    customerUpdateDelivery: CustomerUpdateDelivery;
}

export interface CustomerUpdateGoogleAnalyticsRequest {
    customerUpdateGoogleAnalyticsRequestDto: CustomerUpdateGoogleAnalyticsRequestDto;
}

export interface CustomerUpdatePostHogRequest {
    customerUpdatePostHogRequestDto: CustomerUpdatePostHogRequestDto;
}

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     */
    protected callConsultantRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/call-consultant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected callConsultantFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected callConsultantRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.callConsultantRequestOpts();
        return this.callConsultantFetch(context, initOverrides);
    }

    /**
     */
    callConsultant = async (initOverrides?: RequestInit): Promise<void> => {
        await this.callConsultantRaw(initOverrides);
    }



    /**
     */
    protected createOrderViaChatRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/create-order-via-chat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected createOrderViaChatFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected createOrderViaChatRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.createOrderViaChatRequestOpts();
        return this.createOrderViaChatFetch(context, initOverrides);
    }

    /**
     */
    createOrderViaChat = async (initOverrides?: RequestInit): Promise<void> => {
        await this.createOrderViaChatRaw(initOverrides);
    }



    /**
     */
    protected customerRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerDto>> => {
        const context = this.customerRequestOpts();
        return this.customerFetch(context, initOverrides);
    }

    /**
     */
    customer = async (initOverrides?: RequestInit): Promise<CustomerDto> => {
        const response = await this.customerRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomer = (() => {
        const key = () => this.customerRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerFetch(context));
        const fn = (config?: SWRConfiguration<CustomerDto>): SWRResponse<CustomerDto> => {
            return useSWR<CustomerDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected customerDeliveryRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/delivery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerDeliveryFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderCreateV2DeliveryFromMskDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderCreateV2DeliveryFromMskDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerDeliveryRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderCreateV2DeliveryFromMskDto>> => {
        const context = this.customerDeliveryRequestOpts();
        return this.customerDeliveryFetch(context, initOverrides);
    }

    /**
     */
    customerDelivery = async (initOverrides?: RequestInit): Promise<OrderCreateV2DeliveryFromMskDto> => {
        const response = await this.customerDeliveryRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerDelivery = (() => {
        const key = () => this.customerDeliveryRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerDeliveryFetch(context));
        const fn = (config?: SWRConfiguration<OrderCreateV2DeliveryFromMskDto>): SWRResponse<OrderCreateV2DeliveryFromMskDto> => {
            return useSWR<OrderCreateV2DeliveryFromMskDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected customerDepositUnicornBonusRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/unicorn-bonus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerDepositUnicornBonusFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerDepositUnicornBonusRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerDepositUnicornBonusRequestOpts();
        return this.customerDepositUnicornBonusFetch(context, initOverrides);
    }

    /**
     */
    customerDepositUnicornBonus = async (initOverrides?: RequestInit): Promise<void> => {
        await this.customerDepositUnicornBonusRaw(initOverrides);
    }



    /**
     */
    protected customerOldOrdersTransferRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/old-orders-transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerOldOrdersTransferFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerOldOrdersTransferRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerOldOrdersTransferRequestOpts();
        return this.customerOldOrdersTransferFetch(context, initOverrides);
    }

    /**
     */
    customerOldOrdersTransfer = async (initOverrides?: RequestInit): Promise<void> => {
        await this.customerOldOrdersTransferRaw(initOverrides);
    }



    /**
     */
    protected customerPointsOperationsRequestOpts = (requestParameters: CustomerPointsOperationsRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/points-operations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerPointsOperationsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPointsOperationsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPointsOperationsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerPointsOperationsRaw = async (requestParameters: CustomerPointsOperationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPointsOperationsDto>> => {
        const context = this.customerPointsOperationsRequestOpts(requestParameters);
        return this.customerPointsOperationsFetch(context, initOverrides);
    }

    /**
     */
    customerPointsOperations = async (types?: Array<CustomerPointsOperationType>, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<CustomerPointsOperationsDto> => {
        const response = await this.customerPointsOperationsRaw({ types: types, page: page, perPage: perPage }, initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerPointsOperations = (() => {
        const key = (types?: Array<CustomerPointsOperationType>, page?: number, perPage?: number) => this.customerPointsOperationsRequestOpts({ types: types, page: page, perPage: perPage });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerPointsOperationsFetch(context));
        const fn = (types?: Array<CustomerPointsOperationType>, page?: number, perPage?: number, config?: SWRConfiguration<CustomerPointsOperationsDto>): SWRResponse<CustomerPointsOperationsDto> => {
            return useSWR<CustomerPointsOperationsDto>(() => key(types, page, perPage), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected customerRefStatisticRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/ref-statistic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerRefStatisticFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerRefStatisticDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerRefStatisticDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerRefStatisticRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerRefStatisticDto>> => {
        const context = this.customerRefStatisticRequestOpts();
        return this.customerRefStatisticFetch(context, initOverrides);
    }

    /**
     */
    customerRefStatistic = async (initOverrides?: RequestInit): Promise<CustomerRefStatisticDto> => {
        const response = await this.customerRefStatisticRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerRefStatistic = (() => {
        const key = () => this.customerRefStatisticRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerRefStatisticFetch(context));
        const fn = (config?: SWRConfiguration<CustomerRefStatisticDto>): SWRResponse<CustomerRefStatisticDto> => {
            return useSWR<CustomerRefStatisticDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected customerUnicornBonusRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/unicorn-bonus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerUnicornBonusFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerUnicornBonusDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerUnicornBonusDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerUnicornBonusRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerUnicornBonusDto>> => {
        const context = this.customerUnicornBonusRequestOpts();
        return this.customerUnicornBonusFetch(context, initOverrides);
    }

    /**
     */
    customerUnicornBonus = async (initOverrides?: RequestInit): Promise<CustomerUnicornBonusDto> => {
        const response = await this.customerUnicornBonusRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerUnicornBonus = (() => {
        const key = () => this.customerUnicornBonusRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerUnicornBonusFetch(context));
        const fn = (config?: SWRConfiguration<CustomerUnicornBonusDto>): SWRResponse<CustomerUnicornBonusDto> => {
            return useSWR<CustomerUnicornBonusDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected customerUpdateRequestOpts = (requestParameters: CustomerUpdateRequest): runtime.RequestOpts => {
        if (requestParameters.customerUpdateUtmRequestDto === null || requestParameters.customerUpdateUtmRequestDto === undefined) {
            throw new runtime.RequiredError('customerUpdateUtmRequestDto','Required parameter requestParameters.customerUpdateUtmRequestDto was null or undefined when calling customerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateUtmRequestDtoToJSON(requestParameters.customerUpdateUtmRequestDto),
        };
    }

    /**
     */
    protected customerUpdateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerUpdateRaw = async (requestParameters: CustomerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerDto>> => {
        const context = this.customerUpdateRequestOpts(requestParameters);
        return this.customerUpdateFetch(context, initOverrides);
    }

    /**
     */
    customerUpdate = async (customerUpdateUtmRequestDto: CustomerUpdateUtmRequestDto, initOverrides?: RequestInit): Promise<CustomerDto> => {
        const response = await this.customerUpdateRaw({ customerUpdateUtmRequestDto: customerUpdateUtmRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected customerUpdateActionNotificationsRequestOpts = (requestParameters: CustomerUpdateActionNotificationsRequest): runtime.RequestOpts => {
        if (requestParameters.customerUpdateActionNotificationsRequestDto === null || requestParameters.customerUpdateActionNotificationsRequestDto === undefined) {
            throw new runtime.RequiredError('customerUpdateActionNotificationsRequestDto','Required parameter requestParameters.customerUpdateActionNotificationsRequestDto was null or undefined when calling customerUpdateActionNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/update-action-notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateActionNotificationsRequestDtoToJSON(requestParameters.customerUpdateActionNotificationsRequestDto),
        };
    }

    /**
     */
    protected customerUpdateActionNotificationsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerUpdateActionNotificationsRaw = async (requestParameters: CustomerUpdateActionNotificationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerUpdateActionNotificationsRequestOpts(requestParameters);
        return this.customerUpdateActionNotificationsFetch(context, initOverrides);
    }

    /**
     */
    customerUpdateActionNotifications = async (customerUpdateActionNotificationsRequestDto: CustomerUpdateActionNotificationsRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerUpdateActionNotificationsRaw({ customerUpdateActionNotificationsRequestDto: customerUpdateActionNotificationsRequestDto }, initOverrides);
    }



    /**
     */
    protected customerUpdateDeliveryRequestOpts = (requestParameters: CustomerUpdateDeliveryRequest): runtime.RequestOpts => {
        if (requestParameters.customerUpdateDelivery === null || requestParameters.customerUpdateDelivery === undefined) {
            throw new runtime.RequiredError('customerUpdateDelivery','Required parameter requestParameters.customerUpdateDelivery was null or undefined when calling customerUpdateDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/delivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateDeliveryToJSON(requestParameters.customerUpdateDelivery),
        };
    }

    /**
     */
    protected customerUpdateDeliveryFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerUpdateDeliveryRaw = async (requestParameters: CustomerUpdateDeliveryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerUpdateDeliveryRequestOpts(requestParameters);
        return this.customerUpdateDeliveryFetch(context, initOverrides);
    }

    /**
     */
    customerUpdateDelivery = async (customerUpdateDelivery: CustomerUpdateDelivery, initOverrides?: RequestInit): Promise<void> => {
        await this.customerUpdateDeliveryRaw({ customerUpdateDelivery: customerUpdateDelivery }, initOverrides);
    }



    /**
     */
    protected customerUpdateGoogleAnalyticsRequestOpts = (requestParameters: CustomerUpdateGoogleAnalyticsRequest): runtime.RequestOpts => {
        if (requestParameters.customerUpdateGoogleAnalyticsRequestDto === null || requestParameters.customerUpdateGoogleAnalyticsRequestDto === undefined) {
            throw new runtime.RequiredError('customerUpdateGoogleAnalyticsRequestDto','Required parameter requestParameters.customerUpdateGoogleAnalyticsRequestDto was null or undefined when calling customerUpdateGoogleAnalytics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/google-analytics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateGoogleAnalyticsRequestDtoToJSON(requestParameters.customerUpdateGoogleAnalyticsRequestDto),
        };
    }

    /**
     */
    protected customerUpdateGoogleAnalyticsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerUpdateGoogleAnalyticsRaw = async (requestParameters: CustomerUpdateGoogleAnalyticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerUpdateGoogleAnalyticsRequestOpts(requestParameters);
        return this.customerUpdateGoogleAnalyticsFetch(context, initOverrides);
    }

    /**
     */
    customerUpdateGoogleAnalytics = async (customerUpdateGoogleAnalyticsRequestDto: CustomerUpdateGoogleAnalyticsRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerUpdateGoogleAnalyticsRaw({ customerUpdateGoogleAnalyticsRequestDto: customerUpdateGoogleAnalyticsRequestDto }, initOverrides);
    }



    /**
     */
    protected customerUpdatePostHogRequestOpts = (requestParameters: CustomerUpdatePostHogRequest): runtime.RequestOpts => {
        if (requestParameters.customerUpdatePostHogRequestDto === null || requestParameters.customerUpdatePostHogRequestDto === undefined) {
            throw new runtime.RequiredError('customerUpdatePostHogRequestDto','Required parameter requestParameters.customerUpdatePostHogRequestDto was null or undefined when calling customerUpdatePostHog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer/post-hog`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdatePostHogRequestDtoToJSON(requestParameters.customerUpdatePostHogRequestDto),
        };
    }

    /**
     */
    protected customerUpdatePostHogFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerUpdatePostHogRaw = async (requestParameters: CustomerUpdatePostHogRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerUpdatePostHogRequestOpts(requestParameters);
        return this.customerUpdatePostHogFetch(context, initOverrides);
    }

    /**
     */
    customerUpdatePostHog = async (customerUpdatePostHogRequestDto: CustomerUpdatePostHogRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerUpdatePostHogRaw({ customerUpdatePostHogRequestDto: customerUpdatePostHogRequestDto }, initOverrides);
    }



}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeocoderItemComponent,
    GeocoderItemComponentFromJSON,
    GeocoderItemComponentFromJSONTyped,
    GeocoderItemComponentToJSON,
} from './GeocoderItemComponent';

/**
 * 
 * @export
 * @interface GeocoderItemDto
 */
export interface GeocoderItemDto {
    /**
     * 
     * @type {string}
     * @memberof GeocoderItemDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GeocoderItemDto
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof GeocoderItemDto
     */
    coordinates: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GeocoderItemDto
     */
    kind: string;
    /**
     * 
     * @type {string}
     * @memberof GeocoderItemDto
     */
    text: string;
    /**
     * 
     * @type {Array<GeocoderItemComponent>}
     * @memberof GeocoderItemDto
     */
    components: Array<GeocoderItemComponent>;
}


export function GeocoderItemDtoFromJSON(json: any): GeocoderItemDto {
    return GeocoderItemDtoFromJSONTyped(json, false);
}

export function GeocoderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeocoderItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'coordinates': json['coordinates'],
        'kind': json['kind'],
        'text': json['text'],
        'components': ((json['components'] as Array<any>).map(GeocoderItemComponentFromJSON)),
    };
}

export function GeocoderItemDtoToJSON(value?: GeocoderItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'coordinates': value.coordinates,
        'kind': value.kind,
        'text': value.text,
        'components': ((value.components as Array<any>).map(GeocoderItemComponentToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerOnetimeAuthDto,
    CustomerOnetimeAuthDtoFromJSON,
    CustomerOnetimeAuthDtoToJSON,
} from '../models';

/**
 * 
 */
export class CustomerOnetimeAuthApi extends runtime.BaseAPI {

    /**
     */
    protected customerOnetimeAuthGenerateRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-onetime-auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerOnetimeAuthGenerateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerOnetimeAuthDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOnetimeAuthDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerOnetimeAuthGenerateRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerOnetimeAuthDto>> => {
        const context = this.customerOnetimeAuthGenerateRequestOpts();
        return this.customerOnetimeAuthGenerateFetch(context, initOverrides);
    }

    /**
     */
    customerOnetimeAuthGenerate = async (initOverrides?: RequestInit): Promise<CustomerOnetimeAuthDto> => {
        const response = await this.customerOnetimeAuthGenerateRaw(initOverrides);
        return await response.value();
    }



}

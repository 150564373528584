/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductCategoryDto
 */
export interface CatalogProductCategoryDto {
  /**
   *
   * @type {string}
   * @memberof CatalogProductCategoryDto
   */
  category1: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductCategoryDto
   */
  category2: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductCategoryDto
   */
  category3: string
}

export function CatalogProductCategoryDtoFromJSON(json: any): CatalogProductCategoryDto {
  return CatalogProductCategoryDtoFromJSONTyped(json, false)
}

export function CatalogProductCategoryDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductCategoryDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    category1: json["category1"],
    category2: json["category2"],
    category3: json["category3"],
  }
}

export function CatalogProductCategoryDtoToJSON(value?: CatalogProductCategoryDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    category1: value.category1,
    category2: value.category2,
    category3: value.category3,
  }
}

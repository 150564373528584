import { CustomerApi, CustomerDto } from "shared/api/back/_generated"
import * as runtime from "shared/api/back/_generated/runtime"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"
import { getAuthed } from "shared/api/back/lib"

export class CustomerApiOverridden extends CustomerApi {
  useCustomer = (() => {
    const key = () => {
      if (!getAuthed()) throw new Error("not authed")
      return this.customerRequestOpts()
    }
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerFetch(context))
    const fn = (config?: SWRConfiguration<CustomerDto>): SWRResponse<CustomerDto> => {
      return useSWR<CustomerDto>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()
}

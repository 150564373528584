/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReviewProductDto
 */
export interface ReviewProductDto {
    /**
     * 
     * @type {number}
     * @memberof ReviewProductDto
     */
    spuId: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewProductDto
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewProductDto
     */
    slug: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewProductDto
     */
    images: Array<string>;
}


export function ReviewProductDtoFromJSON(json: any): ReviewProductDto {
    return ReviewProductDtoFromJSONTyped(json, false);
}

export function ReviewProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spuId': json['spuId'],
        'name': json['name'],
        'brand': json['brand'],
        'slug': json['slug'],
        'images': json['images'],
    };
}

export function ReviewProductDtoToJSON(value?: ReviewProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spuId': value.spuId,
        'name': value.name,
        'brand': value.brand,
        'slug': value.slug,
        'images': value.images,
    };
}


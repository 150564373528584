/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerPointsOperationType {
    CART_PAYMENT_WITHDRAW = 'CART_PAYMENT_WITHDRAW',
    CART_PAYMENT_WITHDRAW_RETURN = 'CART_PAYMENT_WITHDRAW_RETURN',
    ORDER_PAYMENT_WITHDRAW = 'ORDER_PAYMENT_WITHDRAW',
    ORDER_PAYMENT_WITHDRAW_RETURN = 'ORDER_PAYMENT_WITHDRAW_RETURN',
    ORDER_DEPOSIT = 'ORDER_DEPOSIT',
    ORDER_CASHBACK_CANCEL = 'ORDER_CASHBACK_CANCEL',
    ORDER_CANCEL_DEPOSIT = 'ORDER_CANCEL_DEPOSIT',
    REFERRAL_DEPOSIT = 'REFERRAL_DEPOSIT',
    REFERRAL_INVITE_DEPOSIT = 'REFERRAL_INVITE_DEPOSIT',
    MANUAL = 'MANUAL'
}

export function CustomerPointsOperationTypeFromJSON(json: any): CustomerPointsOperationType {
    return CustomerPointsOperationTypeFromJSONTyped(json, false);
}

export function CustomerPointsOperationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPointsOperationType {
    return json as CustomerPointsOperationType;
}

export function CustomerPointsOperationTypeToJSON(value?: CustomerPointsOperationType | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualPaymentCardDto
 */
export interface ManualPaymentCardDto {
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentCardDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentCardDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentCardDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentCardDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManualPaymentCardDto
     */
    active: boolean;
}


export function ManualPaymentCardDtoFromJSON(json: any): ManualPaymentCardDto {
    return ManualPaymentCardDtoFromJSONTyped(json, false);
}

export function ManualPaymentCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPaymentCardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'value': json['value'],
        'description': json['description'],
        'active': json['active'],
    };
}

export function ManualPaymentCardDtoToJSON(value?: ManualPaymentCardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'description': value.description,
        'active': value.active,
    };
}


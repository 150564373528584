/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerOrderReviewCreateRequestDto
 */
export interface CustomerOrderReviewCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    advantages: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    disadvantages: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    comment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    imageIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    customerOrderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerOrderReviewCreateRequestDto
     */
    anonymous: boolean;
}


export function CustomerOrderReviewCreateRequestDtoFromJSON(json: any): CustomerOrderReviewCreateRequestDto {
    return CustomerOrderReviewCreateRequestDtoFromJSONTyped(json, false);
}

export function CustomerOrderReviewCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderReviewCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advantages': json['advantages'],
        'disadvantages': json['disadvantages'],
        'comment': json['comment'],
        'imageIds': json['imageIds'],
        'score': json['score'],
        'customerOrderId': json['customerOrderId'],
        'anonymous': json['anonymous'],
    };
}

export function CustomerOrderReviewCreateRequestDtoToJSON(value?: CustomerOrderReviewCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advantages': value.advantages,
        'disadvantages': value.disadvantages,
        'comment': value.comment,
        'imageIds': value.imageIds,
        'score': value.score,
        'customerOrderId': value.customerOrderId,
        'anonymous': value.anonymous,
    };
}


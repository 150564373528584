/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManualPaymentCardDto,
    ManualPaymentCardDtoFromJSON,
    ManualPaymentCardDtoFromJSONTyped,
    ManualPaymentCardDtoToJSON,
} from './ManualPaymentCardDto';

/**
 * 
 * @export
 * @interface ManualPaymentCardsDto
 */
export interface ManualPaymentCardsDto {
    /**
     * 
     * @type {Array<ManualPaymentCardDto>}
     * @memberof ManualPaymentCardsDto
     */
    paymentCards: Array<ManualPaymentCardDto>;
}


export function ManualPaymentCardsDtoFromJSON(json: any): ManualPaymentCardsDto {
    return ManualPaymentCardsDtoFromJSONTyped(json, false);
}

export function ManualPaymentCardsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualPaymentCardsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentCards': ((json['paymentCards'] as Array<any>).map(ManualPaymentCardDtoFromJSON)),
    };
}

export function ManualPaymentCardsDtoToJSON(value?: ManualPaymentCardsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentCards': ((value.paymentCards as Array<any>).map(ManualPaymentCardDtoToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CdekDeliveryPointDto,
    CdekDeliveryPointDtoFromJSON,
    CdekDeliveryPointDtoToJSON,
    DeliveryCityDto,
    DeliveryCityDtoFromJSON,
    DeliveryCityDtoToJSON,
    DeliveryCostDto,
    DeliveryCostDtoFromJSON,
    DeliveryCostDtoToJSON,
    WarehouseCdekOrderType,
    WarehouseCdekOrderTypeFromJSON,
    WarehouseCdekOrderTypeToJSON,
} from '../models';

export interface DeliveryCitiesRequest {
    name?: string;
    id?: number;
}

export interface DeliveryCostRequest {
    type: WarehouseCdekOrderType;
    city: number;
}

export interface DeliveryPointsRequest {
    cityId: number;
}

/**
 * 
 */
export class DeliveryApi extends runtime.BaseAPI {

    /**
     */
    protected deliveryCitiesRequestOpts = (requestParameters: DeliveryCitiesRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery/delivery/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryCitiesFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryCityDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryCityDtoFromJSON));
    }

    /**
     */
    protected deliveryCitiesRaw = async (requestParameters: DeliveryCitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryCityDto>>> => {
        const context = this.deliveryCitiesRequestOpts(requestParameters);
        return this.deliveryCitiesFetch(context, initOverrides);
    }

    /**
     */
    deliveryCities = async (name?: string, id?: number, initOverrides?: RequestInit): Promise<Array<DeliveryCityDto>> => {
        const response = await this.deliveryCitiesRaw({ name: name, id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryCities = (() => {
        const key = (name?: string, id?: number) => this.deliveryCitiesRequestOpts({ name: name, id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryCitiesFetch(context));
        const fn = (name?: string, id?: number, config?: SWRConfiguration<Array<DeliveryCityDto>>): SWRResponse<Array<DeliveryCityDto>> => {
            return useSWR<Array<DeliveryCityDto>>(() => key(name, id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected deliveryCostRequestOpts = (requestParameters: DeliveryCostRequest): runtime.RequestOpts => {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deliveryCost.');
        }

        if (requestParameters.city === null || requestParameters.city === undefined) {
            throw new runtime.RequiredError('city','Required parameter requestParameters.city was null or undefined when calling deliveryCost.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryCostFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryCostDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCostDtoFromJSON(jsonValue));
    }

    /**
     */
    protected deliveryCostRaw = async (requestParameters: DeliveryCostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryCostDto>> => {
        const context = this.deliveryCostRequestOpts(requestParameters);
        return this.deliveryCostFetch(context, initOverrides);
    }

    /**
     */
    deliveryCost = async (type: WarehouseCdekOrderType, city: number, initOverrides?: RequestInit): Promise<DeliveryCostDto> => {
        const response = await this.deliveryCostRaw({ type: type, city: city }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryCost = (() => {
        const key = (type: WarehouseCdekOrderType, city: number) => this.deliveryCostRequestOpts({ type: type, city: city });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryCostFetch(context));
        const fn = (type: WarehouseCdekOrderType, city: number, config?: SWRConfiguration<DeliveryCostDto>): SWRResponse<DeliveryCostDto> => {
            return useSWR<DeliveryCostDto>(() => key(type, city), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected deliveryPointsRequestOpts = (requestParameters: DeliveryPointsRequest): runtime.RequestOpts => {
        if (requestParameters.cityId === null || requestParameters.cityId === undefined) {
            throw new runtime.RequiredError('cityId','Required parameter requestParameters.cityId was null or undefined when calling deliveryPoints.');
        }

        const queryParameters: any = {};

        if (requestParameters.cityId !== undefined) {
            queryParameters['cityId'] = requestParameters.cityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery/delivery/points`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryPointsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CdekDeliveryPointDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CdekDeliveryPointDtoFromJSON));
    }

    /**
     */
    protected deliveryPointsRaw = async (requestParameters: DeliveryPointsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CdekDeliveryPointDto>>> => {
        const context = this.deliveryPointsRequestOpts(requestParameters);
        return this.deliveryPointsFetch(context, initOverrides);
    }

    /**
     */
    deliveryPoints = async (cityId: number, initOverrides?: RequestInit): Promise<Array<CdekDeliveryPointDto>> => {
        const response = await this.deliveryPointsRaw({ cityId: cityId }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryPoints = (() => {
        const key = (cityId: number) => this.deliveryPointsRequestOpts({ cityId: cityId });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryPointsFetch(context));
        const fn = (cityId: number, config?: SWRConfiguration<Array<CdekDeliveryPointDto>>): SWRResponse<Array<CdekDeliveryPointDto>> => {
            return useSWR<Array<CdekDeliveryPointDto>>(() => key(cityId), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCreateV2DeliveryFromMskCourierDto
 */
export interface OrderCreateV2DeliveryFromMskCourierDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskCourierDto
     */
    type: OrderCreateV2DeliveryFromMskCourierDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskCourierDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskCourierDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskCourierDto
     */
    comment?: string;
}

/**
* @export
* @enum {string}
*/
export type OrderCreateV2DeliveryFromMskCourierDtoTypeEnum =
    | 'CDEK'
    | 'BOXBERRY'



export function OrderCreateV2DeliveryFromMskCourierDtoFromJSON(json: any): OrderCreateV2DeliveryFromMskCourierDto {
    return OrderCreateV2DeliveryFromMskCourierDtoFromJSONTyped(json, false);
}

export function OrderCreateV2DeliveryFromMskCourierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2DeliveryFromMskCourierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function OrderCreateV2DeliveryFromMskCourierDtoToJSON(value?: OrderCreateV2DeliveryFromMskCourierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'city': value.city,
        'address': value.address,
        'comment': value.comment,
    };
}


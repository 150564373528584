/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderCreateV2DeliveryFromMskDto,
    OrderCreateV2DeliveryFromMskDtoFromJSON,
    OrderCreateV2DeliveryFromMskDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskDtoToJSON,
} from './OrderCreateV2DeliveryFromMskDto';

/**
 * 
 * @export
 * @interface OrderUpdateDeliveryV2RequestDto
 */
export interface OrderUpdateDeliveryV2RequestDto {
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskDto}
     * @memberof OrderUpdateDeliveryV2RequestDto
     */
    deliveryFromMSK: OrderCreateV2DeliveryFromMskDto;
}


export function OrderUpdateDeliveryV2RequestDtoFromJSON(json: any): OrderUpdateDeliveryV2RequestDto {
    return OrderUpdateDeliveryV2RequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateDeliveryV2RequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateDeliveryV2RequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoFromJSON(json['deliveryFromMSK']),
    };
}

export function OrderUpdateDeliveryV2RequestDtoToJSON(value?: OrderUpdateDeliveryV2RequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoToJSON(value.deliveryFromMSK),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderCreateV2DeliveryFromMskCourierDto,
    OrderCreateV2DeliveryFromMskCourierDtoFromJSON,
    OrderCreateV2DeliveryFromMskCourierDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskCourierDtoToJSON,
} from './OrderCreateV2DeliveryFromMskCourierDto';
import {
    OrderCreateV2DeliveryFromMskPickupPointDto,
    OrderCreateV2DeliveryFromMskPickupPointDtoFromJSON,
    OrderCreateV2DeliveryFromMskPickupPointDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskPickupPointDtoToJSON,
} from './OrderCreateV2DeliveryFromMskPickupPointDto';
import {
    OrderCreateV2DeliveryFromMskRecipientDto,
    OrderCreateV2DeliveryFromMskRecipientDtoFromJSON,
    OrderCreateV2DeliveryFromMskRecipientDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskRecipientDtoToJSON,
} from './OrderCreateV2DeliveryFromMskRecipientDto';

/**
 * 
 * @export
 * @interface OrderCreateV2DeliveryFromMskDto
 */
export interface OrderCreateV2DeliveryFromMskDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2DeliveryFromMskDto
     */
    type?: OrderCreateV2DeliveryFromMskDtoTypeEnum;
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskPickupPointDto}
     * @memberof OrderCreateV2DeliveryFromMskDto
     */
    pickupPoint?: OrderCreateV2DeliveryFromMskPickupPointDto;
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskCourierDto}
     * @memberof OrderCreateV2DeliveryFromMskDto
     */
    courier?: OrderCreateV2DeliveryFromMskCourierDto;
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskRecipientDto}
     * @memberof OrderCreateV2DeliveryFromMskDto
     */
    recipient?: OrderCreateV2DeliveryFromMskRecipientDto;
}

/**
* @export
* @enum {string}
*/
export type OrderCreateV2DeliveryFromMskDtoTypeEnum =
    | 'PICKUP_POINT'
    | 'COURIER'
    | 'SHOP'



export function OrderCreateV2DeliveryFromMskDtoFromJSON(json: any): OrderCreateV2DeliveryFromMskDto {
    return OrderCreateV2DeliveryFromMskDtoFromJSONTyped(json, false);
}

export function OrderCreateV2DeliveryFromMskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2DeliveryFromMskDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'pickupPoint': !exists(json, 'pickupPoint') ? undefined : OrderCreateV2DeliveryFromMskPickupPointDtoFromJSON(json['pickupPoint']),
        'courier': !exists(json, 'courier') ? undefined : OrderCreateV2DeliveryFromMskCourierDtoFromJSON(json['courier']),
        'recipient': !exists(json, 'recipient') ? undefined : OrderCreateV2DeliveryFromMskRecipientDtoFromJSON(json['recipient']),
    };
}

export function OrderCreateV2DeliveryFromMskDtoToJSON(value?: OrderCreateV2DeliveryFromMskDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'pickupPoint': OrderCreateV2DeliveryFromMskPickupPointDtoToJSON(value.pickupPoint),
        'courier': OrderCreateV2DeliveryFromMskCourierDtoToJSON(value.courier),
        'recipient': OrderCreateV2DeliveryFromMskRecipientDtoToJSON(value.recipient),
    };
}


import { ArticleDto, ArticleShortDto } from "../strapi-unicorn/models"
import { BaseStrapiApi } from "shared/lib"

export class StrapiPoizonShopApi extends BaseStrapiApi {
  articles = async (): Promise<ArticleShortDto[]> => {
    const data = await this.request(`/api/articles`, {
      sort: ["createdAt:DESC"],
      pagination: {
        pageSize: "1000",
      },
      populate: {
        hero: {
          populate: "*",
        },
      },
    })

    return data.data
  }

  articleBySlug = async (slug: string): Promise<ArticleDto> => {
    const data = await this.request(`/api/articles`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: {
        hero: {
          populate: "*",
        },
        metadata: {
          populate: "*",
        },
      },
    })
    return data?.data?.[0]
  }
}

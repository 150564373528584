/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPaymentUpdateRedirectUrl
 */
export interface OrderPaymentUpdateRedirectUrl {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentUpdateRedirectUrl
     */
    redirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentUpdateRedirectUrl
     */
    failedRedirectUrl: string;
}


export function OrderPaymentUpdateRedirectUrlFromJSON(json: any): OrderPaymentUpdateRedirectUrl {
    return OrderPaymentUpdateRedirectUrlFromJSONTyped(json, false);
}

export function OrderPaymentUpdateRedirectUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPaymentUpdateRedirectUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectUrl': json['redirectUrl'],
        'failedRedirectUrl': json['failedRedirectUrl'],
    };
}

export function OrderPaymentUpdateRedirectUrlToJSON(value?: OrderPaymentUpdateRedirectUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectUrl': value.redirectUrl,
        'failedRedirectUrl': value.failedRedirectUrl,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    AuthApiKeyDto,
    AuthApiKeyDtoFromJSON,
    AuthApiKeyDtoToJSON,
    AuthOneTimeRequestDto,
    AuthOneTimeRequestDtoFromJSON,
    AuthOneTimeRequestDtoToJSON,
    AuthSmsCodeIdDto,
    AuthSmsCodeIdDtoFromJSON,
    AuthSmsCodeIdDtoToJSON,
    AuthSmsCodeSendRequestDto,
    AuthSmsCodeSendRequestDtoFromJSON,
    AuthSmsCodeSendRequestDtoToJSON,
    AuthSmsCodeVerifyRequestDto,
    AuthSmsCodeVerifyRequestDtoFromJSON,
    AuthSmsCodeVerifyRequestDtoToJSON,
    AuthTelegramBotNameDto,
    AuthTelegramBotNameDtoFromJSON,
    AuthTelegramBotNameDtoToJSON,
    AuthTelegramOneTimeStateDto,
    AuthTelegramOneTimeStateDtoFromJSON,
    AuthTelegramOneTimeStateDtoToJSON,
    AuthTelegramRequestDto,
    AuthTelegramRequestDtoFromJSON,
    AuthTelegramRequestDtoToJSON,
} from '../models';

export interface AuthOneTimeRequest {
    authOneTimeRequestDto: AuthOneTimeRequestDto;
}

export interface AuthSmsCodeSendRequest {
    authSmsCodeSendRequestDto: AuthSmsCodeSendRequestDto;
}

export interface AuthSmsCodeVerifyRequest {
    authSmsCodeVerifyRequestDto: AuthSmsCodeVerifyRequestDto;
}

export interface AuthTelegramRequest {
    authTelegramRequestDto: AuthTelegramRequestDto;
}

export interface AuthTelegramOnetimeApiKeyRequest {
    id: string;
}

export interface AuthTelegramOnetimeStateRequest {
    id: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    protected authOneTimeRequestOpts = (requestParameters: AuthOneTimeRequest): runtime.RequestOpts => {
        if (requestParameters.authOneTimeRequestDto === null || requestParameters.authOneTimeRequestDto === undefined) {
            throw new runtime.RequiredError('authOneTimeRequestDto','Required parameter requestParameters.authOneTimeRequestDto was null or undefined when calling authOneTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/auth/onetime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthOneTimeRequestDtoToJSON(requestParameters.authOneTimeRequestDto),
        };
    }

    /**
     */
    protected authOneTimeFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthApiKeyDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authOneTimeRaw = async (requestParameters: AuthOneTimeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const context = this.authOneTimeRequestOpts(requestParameters);
        return this.authOneTimeFetch(context, initOverrides);
    }

    /**
     */
    authOneTime = async (authOneTimeRequestDto: AuthOneTimeRequestDto, initOverrides?: RequestInit): Promise<AuthApiKeyDto> => {
        const response = await this.authOneTimeRaw({ authOneTimeRequestDto: authOneTimeRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected authSmsCodeSendRequestOpts = (requestParameters: AuthSmsCodeSendRequest): runtime.RequestOpts => {
        if (requestParameters.authSmsCodeSendRequestDto === null || requestParameters.authSmsCodeSendRequestDto === undefined) {
            throw new runtime.RequiredError('authSmsCodeSendRequestDto','Required parameter requestParameters.authSmsCodeSendRequestDto was null or undefined when calling authSmsCodeSend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/auth/sms-code/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthSmsCodeSendRequestDtoToJSON(requestParameters.authSmsCodeSendRequestDto),
        };
    }

    /**
     */
    protected authSmsCodeSendFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthSmsCodeIdDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthSmsCodeIdDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authSmsCodeSendRaw = async (requestParameters: AuthSmsCodeSendRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthSmsCodeIdDto>> => {
        const context = this.authSmsCodeSendRequestOpts(requestParameters);
        return this.authSmsCodeSendFetch(context, initOverrides);
    }

    /**
     */
    authSmsCodeSend = async (authSmsCodeSendRequestDto: AuthSmsCodeSendRequestDto, initOverrides?: RequestInit): Promise<AuthSmsCodeIdDto> => {
        const response = await this.authSmsCodeSendRaw({ authSmsCodeSendRequestDto: authSmsCodeSendRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected authSmsCodeVerifyRequestOpts = (requestParameters: AuthSmsCodeVerifyRequest): runtime.RequestOpts => {
        if (requestParameters.authSmsCodeVerifyRequestDto === null || requestParameters.authSmsCodeVerifyRequestDto === undefined) {
            throw new runtime.RequiredError('authSmsCodeVerifyRequestDto','Required parameter requestParameters.authSmsCodeVerifyRequestDto was null or undefined when calling authSmsCodeVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/auth/sms-code/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthSmsCodeVerifyRequestDtoToJSON(requestParameters.authSmsCodeVerifyRequestDto),
        };
    }

    /**
     */
    protected authSmsCodeVerifyFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthApiKeyDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authSmsCodeVerifyRaw = async (requestParameters: AuthSmsCodeVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const context = this.authSmsCodeVerifyRequestOpts(requestParameters);
        return this.authSmsCodeVerifyFetch(context, initOverrides);
    }

    /**
     */
    authSmsCodeVerify = async (authSmsCodeVerifyRequestDto: AuthSmsCodeVerifyRequestDto, initOverrides?: RequestInit): Promise<AuthApiKeyDto> => {
        const response = await this.authSmsCodeVerifyRaw({ authSmsCodeVerifyRequestDto: authSmsCodeVerifyRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected authTelegramRequestOpts = (requestParameters: AuthTelegramRequest): runtime.RequestOpts => {
        if (requestParameters.authTelegramRequestDto === null || requestParameters.authTelegramRequestDto === undefined) {
            throw new runtime.RequiredError('authTelegramRequestDto','Required parameter requestParameters.authTelegramRequestDto was null or undefined when calling authTelegram.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/auth/telegram`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthTelegramRequestDtoToJSON(requestParameters.authTelegramRequestDto),
        };
    }

    /**
     */
    protected authTelegramFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthApiKeyDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authTelegramRaw = async (requestParameters: AuthTelegramRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const context = this.authTelegramRequestOpts(requestParameters);
        return this.authTelegramFetch(context, initOverrides);
    }

    /**
     */
    authTelegram = async (authTelegramRequestDto: AuthTelegramRequestDto, initOverrides?: RequestInit): Promise<AuthApiKeyDto> => {
        const response = await this.authTelegramRaw({ authTelegramRequestDto: authTelegramRequestDto }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected authTelegramBotNameRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/auth/telegram`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected authTelegramBotNameFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramBotNameDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTelegramBotNameDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authTelegramBotNameRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramBotNameDto>> => {
        const context = this.authTelegramBotNameRequestOpts();
        return this.authTelegramBotNameFetch(context, initOverrides);
    }

    /**
     */
    authTelegramBotName = async (initOverrides?: RequestInit): Promise<AuthTelegramBotNameDto> => {
        const response = await this.authTelegramBotNameRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useAuthTelegramBotName = (() => {
        const key = () => this.authTelegramBotNameRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.authTelegramBotNameFetch(context));
        const fn = (config?: SWRConfiguration<AuthTelegramBotNameDto>): SWRResponse<AuthTelegramBotNameDto> => {
            return useSWR<AuthTelegramBotNameDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected authTelegramOnetimeRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/auth/telegram/onetime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected authTelegramOnetimeFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramOneTimeStateDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTelegramOneTimeStateDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authTelegramOnetimeRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramOneTimeStateDto>> => {
        const context = this.authTelegramOnetimeRequestOpts();
        return this.authTelegramOnetimeFetch(context, initOverrides);
    }

    /**
     */
    authTelegramOnetime = async (initOverrides?: RequestInit): Promise<AuthTelegramOneTimeStateDto> => {
        const response = await this.authTelegramOnetimeRaw(initOverrides);
        return await response.value();
    }



    /**
     */
    protected authTelegramOnetimeApiKeyRequestOpts = (requestParameters: AuthTelegramOnetimeApiKeyRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authTelegramOnetimeApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/auth/telegram/onetime/{id}/api-key`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected authTelegramOnetimeApiKeyFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthApiKeyDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authTelegramOnetimeApiKeyRaw = async (requestParameters: AuthTelegramOnetimeApiKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthApiKeyDto>> => {
        const context = this.authTelegramOnetimeApiKeyRequestOpts(requestParameters);
        return this.authTelegramOnetimeApiKeyFetch(context, initOverrides);
    }

    /**
     */
    authTelegramOnetimeApiKey = async (id: string, initOverrides?: RequestInit): Promise<AuthApiKeyDto> => {
        const response = await this.authTelegramOnetimeApiKeyRaw({ id: id }, initOverrides);
        return await response.value();
    }



    /**
     */
    protected authTelegramOnetimeStateRequestOpts = (requestParameters: AuthTelegramOnetimeStateRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authTelegramOnetimeState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/auth/telegram/onetime/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected authTelegramOnetimeStateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramOneTimeStateDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTelegramOneTimeStateDtoFromJSON(jsonValue));
    }

    /**
     */
    protected authTelegramOnetimeStateRaw = async (requestParameters: AuthTelegramOnetimeStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthTelegramOneTimeStateDto>> => {
        const context = this.authTelegramOnetimeStateRequestOpts(requestParameters);
        return this.authTelegramOnetimeStateFetch(context, initOverrides);
    }

    /**
     */
    authTelegramOnetimeState = async (id: string, initOverrides?: RequestInit): Promise<AuthTelegramOneTimeStateDto> => {
        const response = await this.authTelegramOnetimeStateRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useAuthTelegramOnetimeState = (() => {
        const key = (id: string) => this.authTelegramOnetimeStateRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.authTelegramOnetimeStateFetch(context));
        const fn = (id: string, config?: SWRConfiguration<AuthTelegramOneTimeStateDto>): SWRResponse<AuthTelegramOneTimeStateDto> => {
            return useSWR<AuthTelegramOneTimeStateDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

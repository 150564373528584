/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CatalogProductBasicPropertyDefinitionEnum {
  RELEASE_DATE = "RELEASE_DATE",
  TOE_STYLE = "TOE_STYLE",
  HEEL_TYPE = "HEEL_TYPE",
  CLOSURE = "CLOSURE",
  UPPER_HEIGHT = "UPPER_HEIGHT",
  FEATURE = "FEATURE",
  APPLICABLE_SEASON = "APPLICABLE_SEASON",
  APPLICABLE_VENUES = "APPLICABLE_VENUES",
  APPLICABLE_SCENE = "APPLICABLE_SCENE",
  UPPER_MATERIAL = "UPPER_MATERIAL",
  SHOE_WEIGHT_APPROX = "SHOE_WEIGHT_APPROX",
  PRIMARY_COLOR = "PRIMARY_COLOR",
  SECONDARY_COLOR = "SECONDARY_COLOR",
  MAIN_ARTICLE_NUMBER = "MAIN_ARTICLE_NUMBER",
  SALE_PRICE = "SALE_PRICE",
  APPLICABLE_PLAY_STYLE = "APPLICABLE_PLAY_STYLE",
  SUITABLE_FOR_FOOT_WIDTH = "SUITABLE_FOR_FOOT_WIDTH",
  STYLE = "STYLE",
  MATERIAL = "MATERIAL",
  COLOR = "COLOR",
  PRODUCT_HEIGHT = "PRODUCT_HEIGHT",
  PRODUCT_IMAGE = "PRODUCT_IMAGE",
  FABRIC = "FABRIC",
  VERSION = "VERSION",
  SLEEVE_LENGTH = "SLEEVE_LENGTH",
  CLOTHES_LENGTH = "CLOTHES_LENGTH",
  SHAPE = "SHAPE",
  INGREDIENTS = "INGREDIENTS",
  THICKNESS = "THICKNESS",
}

export function CatalogProductBasicPropertyDefinitionEnumFromJSON(
  json: any,
): CatalogProductBasicPropertyDefinitionEnum {
  return CatalogProductBasicPropertyDefinitionEnumFromJSONTyped(json, false)
}

export function CatalogProductBasicPropertyDefinitionEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductBasicPropertyDefinitionEnum {
  return json as CatalogProductBasicPropertyDefinitionEnum
}

export function CatalogProductBasicPropertyDefinitionEnumToJSON(
  value?: CatalogProductBasicPropertyDefinitionEnum | null,
): any {
  return value as any
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {string}
   * @memberof Category
   */
  slug: string
  /**
   *
   * @type {string}
   * @memberof Category
   */
  name?: string
  /**
   *
   * @type {Array<Category>}
   * @memberof Category
   */
  categories?: Array<Category>
}

export function CategoryFromJSON(json: any): Category {
  return CategoryFromJSONTyped(json, false)
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
  if (json === undefined || json === null) {
    return json
  }
  return {
    slug: json["slug"],
    name: !exists(json, "name") ? undefined : json["name"],
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(CategoryFromJSON),
  }
}

export function CategoryToJSON(value?: Category | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    slug: value.slug,
    name: value.name,
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(CategoryToJSON),
  }
}

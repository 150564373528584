/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RocketWorkAccountType,
    RocketWorkAccountTypeFromJSON,
    RocketWorkAccountTypeFromJSONTyped,
    RocketWorkAccountTypeToJSON,
} from './RocketWorkAccountType';
import {
    RocketWorkPaymentType,
    RocketWorkPaymentTypeFromJSON,
    RocketWorkPaymentTypeFromJSONTyped,
    RocketWorkPaymentTypeToJSON,
} from './RocketWorkPaymentType';

/**
 * 
 * @export
 * @interface OrderRocketWorkPaymentDto
 */
export interface OrderRocketWorkPaymentDto {
    /**
     * 
     * @type {RocketWorkPaymentType}
     * @memberof OrderRocketWorkPaymentDto
     */
    type: RocketWorkPaymentType;
    /**
     * 
     * @type {RocketWorkAccountType}
     * @memberof OrderRocketWorkPaymentDto
     */
    accountType: RocketWorkAccountType;
    /**
     * 
     * @type {string}
     * @memberof OrderRocketWorkPaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRocketWorkPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRocketWorkPaymentDto
     */
    status: OrderRocketWorkPaymentDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderRocketWorkPaymentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderRocketWorkPaymentDto
     */
    taskId: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRocketWorkPaymentDto
     */
    paymentUrl: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderRocketWorkPaymentDtoStatusEnum =
    | 'NEW'
    | 'PAID'
    | 'WAIT_PAYMENT_URL'
    | 'WAIT_PAYMENT'
    | 'EXPIRED'
    | 'CANCELED'
    | 'REFUNDING'
    | 'REFUNDED'



export function OrderRocketWorkPaymentDtoFromJSON(json: any): OrderRocketWorkPaymentDto {
    return OrderRocketWorkPaymentDtoFromJSONTyped(json, false);
}

export function OrderRocketWorkPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRocketWorkPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': RocketWorkPaymentTypeFromJSON(json['type']),
        'accountType': RocketWorkAccountTypeFromJSON(json['accountType']),
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'taskId': json['taskId'],
        'paymentUrl': json['paymentUrl'],
    };
}

export function OrderRocketWorkPaymentDtoToJSON(value?: OrderRocketWorkPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': RocketWorkPaymentTypeToJSON(value.type),
        'accountType': RocketWorkAccountTypeToJSON(value.accountType),
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'taskId': value.taskId,
        'paymentUrl': value.paymentUrl,
    };
}


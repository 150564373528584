/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    QuestionRequestDto,
    QuestionRequestDtoFromJSON,
    QuestionRequestDtoToJSON,
} from '../models';

export interface QuestionRequest {
    questionRequestDto: QuestionRequestDto;
}

/**
 * 
 */
export class QuestionApi extends runtime.BaseAPI {

    /**
     */
    protected questionRequestOpts = (requestParameters: QuestionRequest): runtime.RequestOpts => {
        if (requestParameters.questionRequestDto === null || requestParameters.questionRequestDto === undefined) {
            throw new runtime.RequiredError('questionRequestDto','Required parameter requestParameters.questionRequestDto was null or undefined when calling question.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/question`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionRequestDtoToJSON(requestParameters.questionRequestDto),
        };
    }

    /**
     */
    protected questionFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected questionRaw = async (requestParameters: QuestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.questionRequestOpts(requestParameters);
        return this.questionFetch(context, initOverrides);
    }

    /**
     */
    question = async (questionRequestDto: QuestionRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.questionRaw({ questionRequestDto: questionRequestDto }, initOverrides);
    }



}

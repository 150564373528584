/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderReviewStatus,
    CustomerOrderReviewStatusFromJSON,
    CustomerOrderReviewStatusFromJSONTyped,
    CustomerOrderReviewStatusToJSON,
} from './CustomerOrderReviewStatus';
import {
    ImageDto,
    ImageDtoFromJSON,
    ImageDtoFromJSONTyped,
    ImageDtoToJSON,
} from './ImageDto';
import {
    ReviewCustomerDto,
    ReviewCustomerDtoFromJSON,
    ReviewCustomerDtoFromJSONTyped,
    ReviewCustomerDtoToJSON,
} from './ReviewCustomerDto';
import {
    ReviewProductDto,
    ReviewProductDtoFromJSON,
    ReviewProductDtoFromJSONTyped,
    ReviewProductDtoToJSON,
} from './ReviewProductDto';

/**
 * 
 * @export
 * @interface ReviewDto
 */
export interface ReviewDto {
    /**
     * 
     * @type {CustomerOrderReviewStatus}
     * @memberof ReviewDto
     */
    status: CustomerOrderReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ReviewDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReviewDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    advantages: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    disadvantages: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    comment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewDto
     */
    imageIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ReviewDto
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    spuId: string;
    /**
     * 
     * @type {Array<ImageDto>}
     * @memberof ReviewDto
     */
    images: Array<ImageDto>;
    /**
     * 
     * @type {ReviewCustomerDto}
     * @memberof ReviewDto
     */
    customer: ReviewCustomerDto;
    /**
     * 
     * @type {ReviewProductDto}
     * @memberof ReviewDto
     */
    product: ReviewProductDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewDto
     */
    hiddenOnProductPage: boolean;
}


export function ReviewDtoFromJSON(json: any): ReviewDto {
    return ReviewDtoFromJSONTyped(json, false);
}

export function ReviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': CustomerOrderReviewStatusFromJSON(json['status']),
        'customerId': json['customerId'],
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'advantages': json['advantages'],
        'disadvantages': json['disadvantages'],
        'comment': json['comment'],
        'imageIds': json['imageIds'],
        'score': json['score'],
        'spuId': json['spuId'],
        'images': ((json['images'] as Array<any>).map(ImageDtoFromJSON)),
        'customer': ReviewCustomerDtoFromJSON(json['customer']),
        'product': ReviewProductDtoFromJSON(json['product']),
        'hiddenOnProductPage': json['hiddenOnProductPage'],
    };
}

export function ReviewDtoToJSON(value?: ReviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': CustomerOrderReviewStatusToJSON(value.status),
        'customerId': value.customerId,
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'advantages': value.advantages,
        'disadvantages': value.disadvantages,
        'comment': value.comment,
        'imageIds': value.imageIds,
        'score': value.score,
        'spuId': value.spuId,
        'images': ((value.images as Array<any>).map(ImageDtoToJSON)),
        'customer': ReviewCustomerDtoToJSON(value.customer),
        'product': ReviewProductDtoToJSON(value.product),
        'hiddenOnProductPage': value.hiddenOnProductPage,
    };
}


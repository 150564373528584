/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogPriceV2Dto,
  CatalogPriceV2DtoFromJSON,
  CatalogPriceV2DtoFromJSONTyped,
  CatalogPriceV2DtoToJSON,
} from "./CatalogPriceV2Dto"
import {
  CatalogProductSkuAvailabilityDto,
  CatalogProductSkuAvailabilityDtoFromJSON,
  CatalogProductSkuAvailabilityDtoFromJSONTyped,
  CatalogProductSkuAvailabilityDtoToJSON,
} from "./CatalogProductSkuAvailabilityDto"
import {
  CatalogProductSkuDeliveryTimeDto,
  CatalogProductSkuDeliveryTimeDtoFromJSON,
  CatalogProductSkuDeliveryTimeDtoFromJSONTyped,
  CatalogProductSkuDeliveryTimeDtoToJSON,
} from "./CatalogProductSkuDeliveryTimeDto"
import {
  CatalogProductSkuSizeDto,
  CatalogProductSkuSizeDtoFromJSON,
  CatalogProductSkuSizeDtoFromJSONTyped,
  CatalogProductSkuSizeDtoToJSON,
} from "./CatalogProductSkuSizeDto"
import {
  CatalogProductSplitDto,
  CatalogProductSplitDtoFromJSON,
  CatalogProductSplitDtoFromJSONTyped,
  CatalogProductSplitDtoToJSON,
} from "./CatalogProductSplitDto"

/**
 *
 * @export
 * @interface CatalogProductSkuDto
 */
export interface CatalogProductSkuDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDto
   */
  skuId: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDto
   */
  price: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDto
   */
  cnyPrice: number
  /**
   *
   * @type {CatalogProductSkuSizeDto}
   * @memberof CatalogProductSkuDto
   */
  size?: CatalogProductSkuSizeDto
  /**
   *
   * @type {CatalogProductSplitDto}
   * @memberof CatalogProductSkuDto
   */
  split?: CatalogProductSplitDto
  /**
   *
   * @type {Array<CatalogProductSkuAvailabilityDto>}
   * @memberof CatalogProductSkuDto
   */
  fromAvailability: Array<CatalogProductSkuAvailabilityDto>
  /**
   *
   * @type {CatalogPriceV2Dto}
   * @memberof CatalogProductSkuDto
   */
  priceV2: CatalogPriceV2Dto
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDto
   */
  maxPrice: number
  /**
   *
   * @type {CatalogProductSkuDeliveryTimeDto}
   * @memberof CatalogProductSkuDto
   */
  deliveryTime?: CatalogProductSkuDeliveryTimeDto
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductSkuDto
   */
  images: Array<string>
}

export function CatalogProductSkuDtoFromJSON(json: any): CatalogProductSkuDto {
  return CatalogProductSkuDtoFromJSONTyped(json, false)
}

export function CatalogProductSkuDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductSkuDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    skuId: json["skuId"],
    price: json["price"],
    cnyPrice: json["cnyPrice"],
    size: !exists(json, "size") ? undefined : CatalogProductSkuSizeDtoFromJSON(json["size"]),
    split: !exists(json, "split") ? undefined : CatalogProductSplitDtoFromJSON(json["split"]),
    fromAvailability: (json["fromAvailability"] as Array<any>).map(CatalogProductSkuAvailabilityDtoFromJSON),
    priceV2: CatalogPriceV2DtoFromJSON(json["priceV2"]),
    maxPrice: json["maxPrice"],
    deliveryTime: !exists(json, "deliveryTime")
      ? undefined
      : CatalogProductSkuDeliveryTimeDtoFromJSON(json["deliveryTime"]),
    images: json["images"],
  }
}

export function CatalogProductSkuDtoToJSON(value?: CatalogProductSkuDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    skuId: value.skuId,
    price: value.price,
    cnyPrice: value.cnyPrice,
    size: CatalogProductSkuSizeDtoToJSON(value.size),
    split: CatalogProductSplitDtoToJSON(value.split),
    fromAvailability: (value.fromAvailability as Array<any>).map(CatalogProductSkuAvailabilityDtoToJSON),
    priceV2: CatalogPriceV2DtoToJSON(value.priceV2),
    maxPrice: value.maxPrice,
    deliveryTime: CatalogProductSkuDeliveryTimeDtoToJSON(value.deliveryTime),
    images: value.images,
  }
}

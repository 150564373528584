/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerDiscountDto,
    CustomerDiscountDtoFromJSON,
    CustomerDiscountDtoToJSON,
} from '../models';

/**
 * 
 */
export class CustomerDiscountApi extends runtime.BaseAPI {

    /**
     */
    protected customerDiscountActiveRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-discount/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerDiscountActiveFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CustomerDiscountDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerDiscountDtoFromJSON));
    }

    /**
     */
    protected customerDiscountActiveRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CustomerDiscountDto>>> => {
        const context = this.customerDiscountActiveRequestOpts();
        return this.customerDiscountActiveFetch(context, initOverrides);
    }

    /**
     */
    customerDiscountActive = async (initOverrides?: RequestInit): Promise<Array<CustomerDiscountDto>> => {
        const response = await this.customerDiscountActiveRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerDiscountActive = (() => {
        const key = () => this.customerDiscountActiveRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerDiscountActiveFetch(context));
        const fn = (config?: SWRConfiguration<Array<CustomerDiscountDto>>): SWRResponse<Array<CustomerDiscountDto>> => {
            return useSWR<Array<CustomerDiscountDto>>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryCostDto
 */
export interface DeliveryCostDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryCostDto
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCostDto
     */
    periodMin: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCostDto
     */
    periodMax: number;
}


export function DeliveryCostDtoFromJSON(json: any): DeliveryCostDto {
    return DeliveryCostDtoFromJSONTyped(json, false);
}

export function DeliveryCostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryCostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'periodMin': json['periodMin'],
        'periodMax': json['periodMax'],
    };
}

export function DeliveryCostDtoToJSON(value?: DeliveryCostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'periodMin': value.periodMin,
        'periodMax': value.periodMax,
    };
}


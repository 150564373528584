import { ArticleDto, ArticleShortDto, BrandDto, BrandShortDto, CatalogDto, CollectionDto, ModelDto } from "./models"
import { BaseStrapiApi } from "shared/lib"

export class StrapiUnicornApi extends BaseStrapiApi {
  articles = async (): Promise<ArticleShortDto[]> => {
    const data = await this.request(`/api/articles`, {
      sort: ["createdAt:DESC"],
      pagination: {
        pageSize: "1000",
      },
      populate: {
        hero: {
          populate: "*",
        },
      },
    })

    return data.data
  }

  articleBySlug = async (slug: string): Promise<ArticleDto> => {
    const data = await this.request(`/api/articles`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: {
        hero: {
          populate: "*",
        },
        metadata: {
          populate: "*",
        },
      },
    })
    return data?.data?.[0]
  }

  collections = async (): Promise<CollectionDto[]> => {
    const data = await this.request(`/api/collections`, {
      sort: ["createdAt:DESC"],
      populate: "*",
      pagination: {
        pageSize: "1000",
      },
    })

    return data.data
  }

  collectionBySlug = async (slug: string): Promise<CollectionDto> => {
    const data = await this.request(`/api/collections`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: "*",
    })
    return data?.data?.[0]
  }

  brands = async (): Promise<BrandShortDto[]> => {
    const data = await this.request(`/api/brands`, {
      sort: ["createdAt:DESC"],
      fields: ["slug", "title", "description", "svg"],
      pagination: {
        pageSize: "1000",
      },
    })
    return data?.data
  }

  brandBySlug = async (slug: string): Promise<BrandDto> => {
    const data = await this.request(`/api/brands`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: {
        metadata: {
          populate: "*",
        },
        models: {
          fields: ["slug", "name"],
          populate: ["image"],
        },
      },
    })
    return data?.data?.[0]
  }

  modelBySlug = async (slug: string): Promise<ModelDto> => {
    const data = await this.request(`/api/models`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: {
        brand: {
          fields: ["slug", "title", "svg"],
        },
        image: {
          populate: "*",
        },
        metadata: {
          populate: "*",
        },
      },
    })
    return data?.data?.[0]
  }

  catalogBySlug = async (slug: string): Promise<CatalogDto> => {
    const data = await this.request(`/api/catalogs`, {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: "*",
    })
    return data?.data?.[0]
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderType,
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';

/**
 * 
 * @export
 * @interface OrderUpdateType
 */
export interface OrderUpdateType {
    /**
     * 
     * @type {OrderType}
     * @memberof OrderUpdateType
     */
    type: OrderType;
}


export function OrderUpdateTypeFromJSON(json: any): OrderUpdateType {
    return OrderUpdateTypeFromJSONTyped(json, false);
}

export function OrderUpdateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': OrderTypeFromJSON(json['type']),
    };
}

export function OrderUpdateTypeToJSON(value?: OrderUpdateType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': OrderTypeToJSON(value.type),
    };
}


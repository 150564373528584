/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionLevelDto
 */
export interface ActionLevelDto {
    /**
     * 
     * @type {string}
     * @memberof ActionLevelDto
     */
    type: ActionLevelDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ActionLevelDto
     */
    participants: number;
    /**
     * 
     * @type {number}
     * @memberof ActionLevelDto
     */
    tickets: number;
}

/**
* @export
* @enum {string}
*/
export type ActionLevelDtoTypeEnum =
    | 'IS_SUBSCRIBER'
    | 'IS_PARTICIPANT'
    | 'HAS_ORDER_FOR_PERIOD'
    | 'HAS_REVIEW_WITH_IMAGES_FOR_PERIOD'
    | 'HAS_REFERRALS_FOR_PERIOD'



export function ActionLevelDtoFromJSON(json: any): ActionLevelDto {
    return ActionLevelDtoFromJSONTyped(json, false);
}

export function ActionLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionLevelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'participants': json['participants'],
        'tickets': json['tickets'],
    };
}

export function ActionLevelDtoToJSON(value?: ActionLevelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'participants': value.participants,
        'tickets': value.tickets,
    };
}


import Image, { ImageProps } from "next/image";
import { StrapiImageDto } from "./image.dto";
export type IStrapiImage = Omit<ImageProps, "src" | "width" | "height" | "alt"> & {
  src: string | StrapiImageDto;
  alt?: string;
};
const StrapiImage = ({
  src,
  ...props
}: IStrapiImage & {
  baseUrl: string;
}) => {
  const data = typeof src === "string" ? {
    url: src,
    width: undefined,
    height: undefined
  } : src.data?.attributes;
  if (!data) return null;
  const widthAndHeight = data?.width && data?.height && !props.fill ? {
    width: data.width,
    height: data.height
  } : {};
  return <Image src={props.baseUrl + data?.url} alt="" {...widthAndHeight} {...props} data-sentry-element="Image" data-sentry-component="StrapiImage" data-sentry-source-file="strapi-image.tsx" />;
};
export const createStrapiImage = (baseUrl: string) => (props: Omit<IStrapiImage, "baseUrl">) => <StrapiImage {...props} baseUrl={baseUrl} />;
/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentFlowTransferByPhoneDto,
    PaymentFlowTransferByPhoneDtoFromJSON,
    PaymentFlowTransferByPhoneDtoFromJSONTyped,
    PaymentFlowTransferByPhoneDtoToJSON,
} from './PaymentFlowTransferByPhoneDto';
import {
    PaymentFlowTransferToCardDto,
    PaymentFlowTransferToCardDtoFromJSON,
    PaymentFlowTransferToCardDtoFromJSONTyped,
    PaymentFlowTransferToCardDtoToJSON,
} from './PaymentFlowTransferToCardDto';

/**
 * 
 * @export
 * @interface PaymentFlowAvailablePaymentMethodsDto
 */
export interface PaymentFlowAvailablePaymentMethodsDto {
    /**
     * 
     * @type {PaymentFlowTransferByPhoneDto}
     * @memberof PaymentFlowAvailablePaymentMethodsDto
     */
    transferByPhone: PaymentFlowTransferByPhoneDto;
    /**
     * 
     * @type {PaymentFlowTransferToCardDto}
     * @memberof PaymentFlowAvailablePaymentMethodsDto
     */
    transferToCard: PaymentFlowTransferToCardDto;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentFlowAvailablePaymentMethodsDto
     */
    sbp: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentFlowAvailablePaymentMethodsDto
     */
    card: boolean;
}


export function PaymentFlowAvailablePaymentMethodsDtoFromJSON(json: any): PaymentFlowAvailablePaymentMethodsDto {
    return PaymentFlowAvailablePaymentMethodsDtoFromJSONTyped(json, false);
}

export function PaymentFlowAvailablePaymentMethodsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowAvailablePaymentMethodsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transferByPhone': PaymentFlowTransferByPhoneDtoFromJSON(json['transferByPhone']),
        'transferToCard': PaymentFlowTransferToCardDtoFromJSON(json['transferToCard']),
        'sbp': json['sbp'],
        'card': json['card'],
    };
}

export function PaymentFlowAvailablePaymentMethodsDtoToJSON(value?: PaymentFlowAvailablePaymentMethodsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transferByPhone': PaymentFlowTransferByPhoneDtoToJSON(value.transferByPhone),
        'transferToCard': PaymentFlowTransferToCardDtoToJSON(value.transferToCard),
        'sbp': value.sbp,
        'card': value.card,
    };
}


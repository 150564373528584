/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowTransferToCardDto
 */
export interface PaymentFlowTransferToCardDto {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentFlowTransferToCardDto
     */
    enabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentFlowTransferToCardDto
     */
    availableBanks: Array<PaymentFlowTransferToCardDtoAvailableBanksEnum>;
}

/**
* @export
* @enum {string}
*/
export type PaymentFlowTransferToCardDtoAvailableBanksEnum =
    | 'sberbank'
    | 'tinkoff'
    | 'alfa'
    | 'vtb'
    | 'sovkombank'
    | 'rosselxozbank'
    | 'psb'
    | 'raiffeisen'



export function PaymentFlowTransferToCardDtoFromJSON(json: any): PaymentFlowTransferToCardDto {
    return PaymentFlowTransferToCardDtoFromJSONTyped(json, false);
}

export function PaymentFlowTransferToCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowTransferToCardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'availableBanks': json['availableBanks'],
    };
}

export function PaymentFlowTransferToCardDtoToJSON(value?: PaymentFlowTransferToCardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'availableBanks': value.availableBanks,
    };
}


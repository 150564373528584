/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductPropertyValueDto
 */
export interface CatalogProductPropertyValueDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductPropertyValueDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductPropertyValueDto
   */
  typeId: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductPropertyValueDto
   */
  value: string
}

export function CatalogProductPropertyValueDtoFromJSON(json: any): CatalogProductPropertyValueDto {
  return CatalogProductPropertyValueDtoFromJSONTyped(json, false)
}

export function CatalogProductPropertyValueDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductPropertyValueDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    typeId: json["typeId"],
    value: json["value"],
  }
}

export function CatalogProductPropertyValueDtoToJSON(value?: CatalogProductPropertyValueDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    typeId: value.typeId,
    value: value.value,
  }
}

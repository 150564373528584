/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerSizeAddCollectionRequestDto,
    CustomerSizeAddCollectionRequestDtoFromJSON,
    CustomerSizeAddCollectionRequestDtoToJSON,
    CustomerSizeCollectionDto,
    CustomerSizeCollectionDtoFromJSON,
    CustomerSizeCollectionDtoToJSON,
} from '../models';

export interface CustomerSizeAddRequest {
    customerSizeAddCollectionRequestDto: CustomerSizeAddCollectionRequestDto;
}

export interface CustomerSizesRequest {
    page?: number;
    perPage?: number;
}

/**
 * 
 */
export class CustomerSizeApi extends runtime.BaseAPI {

    /**
     */
    protected customerSizeAddRequestOpts = (requestParameters: CustomerSizeAddRequest): runtime.RequestOpts => {
        if (requestParameters.customerSizeAddCollectionRequestDto === null || requestParameters.customerSizeAddCollectionRequestDto === undefined) {
            throw new runtime.RequiredError('customerSizeAddCollectionRequestDto','Required parameter requestParameters.customerSizeAddCollectionRequestDto was null or undefined when calling customerSizeAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-size`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerSizeAddCollectionRequestDtoToJSON(requestParameters.customerSizeAddCollectionRequestDto),
        };
    }

    /**
     */
    protected customerSizeAddFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerSizeAddRaw = async (requestParameters: CustomerSizeAddRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerSizeAddRequestOpts(requestParameters);
        return this.customerSizeAddFetch(context, initOverrides);
    }

    /**
     */
    customerSizeAdd = async (customerSizeAddCollectionRequestDto: CustomerSizeAddCollectionRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerSizeAddRaw({ customerSizeAddCollectionRequestDto: customerSizeAddCollectionRequestDto }, initOverrides);
    }



    /**
     */
    protected customerSizesRequestOpts = (requestParameters: CustomerSizesRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-size`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerSizesFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerSizeCollectionDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSizeCollectionDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerSizesRaw = async (requestParameters: CustomerSizesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerSizeCollectionDto>> => {
        const context = this.customerSizesRequestOpts(requestParameters);
        return this.customerSizesFetch(context, initOverrides);
    }

    /**
     */
    customerSizes = async (page?: number, perPage?: number, initOverrides?: RequestInit): Promise<CustomerSizeCollectionDto> => {
        const response = await this.customerSizesRaw({ page: page, perPage: perPage }, initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerSizes = (() => {
        const key = (page?: number, perPage?: number) => this.customerSizesRequestOpts({ page: page, perPage: perPage });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerSizesFetch(context));
        const fn = (page?: number, perPage?: number, config?: SWRConfiguration<CustomerSizeCollectionDto>): SWRResponse<CustomerSizeCollectionDto> => {
            return useSWR<CustomerSizeCollectionDto>(() => key(page, perPage), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductSkuDeliveryTimeDto
 */
export interface CatalogProductSkuDeliveryTimeDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDeliveryTimeDto
   */
  max: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDeliveryTimeDto
   */
  min: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDeliveryTimeDto
   */
  expressMax: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuDeliveryTimeDto
   */
  expressMin: number
}

export function CatalogProductSkuDeliveryTimeDtoFromJSON(json: any): CatalogProductSkuDeliveryTimeDto {
  return CatalogProductSkuDeliveryTimeDtoFromJSONTyped(json, false)
}

export function CatalogProductSkuDeliveryTimeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductSkuDeliveryTimeDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    max: json["max"],
    min: json["min"],
    expressMax: json["expressMax"],
    expressMin: json["expressMin"],
  }
}

export function CatalogProductSkuDeliveryTimeDtoToJSON(value?: CatalogProductSkuDeliveryTimeDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    max: value.max,
    min: value.min,
    expressMax: value.expressMax,
    expressMin: value.expressMin,
  }
}

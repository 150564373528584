/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductShortDto,
  CatalogProductShortDtoFromJSON,
  CatalogProductShortDtoFromJSONTyped,
  CatalogProductShortDtoToJSON,
} from "./CatalogProductShortDto"

/**
 *
 * @export
 * @interface CatalogProductListDto
 */
export interface CatalogProductListDto {
  /**
   *
   * @type {Array<CatalogProductShortDto>}
   * @memberof CatalogProductListDto
   */
  items: Array<CatalogProductShortDto>
  /**
   *
   * @type {number}
   * @memberof CatalogProductListDto
   */
  total: number
}

export function CatalogProductListDtoFromJSON(json: any): CatalogProductListDto {
  return CatalogProductListDtoFromJSONTyped(json, false)
}

export function CatalogProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductListDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    items: (json["items"] as Array<any>).map(CatalogProductShortDtoFromJSON),
    total: json["total"],
  }
}

export function CatalogProductListDtoToJSON(value?: CatalogProductListDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    items: (value.items as Array<any>).map(CatalogProductShortDtoToJSON),
    total: value.total,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SearchResultBrand,
  SearchResultBrandFromJSON,
  SearchResultBrandFromJSONTyped,
  SearchResultBrandToJSON,
} from "./SearchResultBrand"
import {
  SearchResultFilterSize,
  SearchResultFilterSizeFromJSON,
  SearchResultFilterSizeFromJSONTyped,
  SearchResultFilterSizeToJSON,
} from "./SearchResultFilterSize"

/**
 *
 * @export
 * @interface SearchResultFilters
 */
export interface SearchResultFilters {
  /**
   *
   * @type {Array<SearchResultFilterSize>}
   * @memberof SearchResultFilters
   */
  sizes: Array<SearchResultFilterSize>
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResultFilters
   */
  fitIds: Array<SearchResultFiltersFitIdsEnum>
  /**
   *
   * @type {Array<SearchResultBrand>}
   * @memberof SearchResultFilters
   */
  brands: Array<SearchResultBrand>
  /**
   *
   * @type {number}
   * @memberof SearchResultFilters
   */
  maxPrice: number
  /**
   *
   * @type {number}
   * @memberof SearchResultFilters
   */
  minPrice: number
}

/**
 * @export
 * @enum {string}
 */
export type SearchResultFiltersFitIdsEnum =
  | "UNISEX"
  | "MALE"
  | "FEMALE"
  | "CHILD"
  | "BABY"
  | "MIDDLE_CHILD"
  | "ELDEST_CHILD"

export function SearchResultFiltersFromJSON(json: any): SearchResultFilters {
  return SearchResultFiltersFromJSONTyped(json, false)
}

export function SearchResultFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultFilters {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sizes: (json["sizes"] as Array<any>).map(SearchResultFilterSizeFromJSON),
    fitIds: json["fitIds"],
    brands: (json["brands"] as Array<any>).map(SearchResultBrandFromJSON),
    maxPrice: json["maxPrice"],
    minPrice: json["minPrice"],
  }
}

export function SearchResultFiltersToJSON(value?: SearchResultFilters | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sizes: (value.sizes as Array<any>).map(SearchResultFilterSizeToJSON),
    fitIds: value.fitIds,
    brands: (value.brands as Array<any>).map(SearchResultBrandToJSON),
    maxPrice: value.maxPrice,
    minPrice: value.minPrice,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderUpdateExpressDeliveryRequestDto
 */
export interface OrderUpdateExpressDeliveryRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof OrderUpdateExpressDeliveryRequestDto
     */
    enable: boolean;
}


export function OrderUpdateExpressDeliveryRequestDtoFromJSON(json: any): OrderUpdateExpressDeliveryRequestDto {
    return OrderUpdateExpressDeliveryRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateExpressDeliveryRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateExpressDeliveryRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enable': json['enable'],
    };
}

export function OrderUpdateExpressDeliveryRequestDtoToJSON(value?: OrderUpdateExpressDeliveryRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable': value.enable,
    };
}


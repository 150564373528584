/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { EventNames, EventNamesFromJSON, EventNamesFromJSONTyped, EventNamesToJSON } from "./EventNames"

/**
 *
 * @export
 * @interface CatalogAnalyticCaptureRequestDto
 */
export interface CatalogAnalyticCaptureRequestDto {
  /**
   *
   * @type {EventNames}
   * @memberof CatalogAnalyticCaptureRequestDto
   */
  event: EventNames
  /**
   *
   * @type {string}
   * @memberof CatalogAnalyticCaptureRequestDto
   */
  query: string
  /**
   *
   * @type {object}
   * @memberof CatalogAnalyticCaptureRequestDto
   */
  data: object
}

export function CatalogAnalyticCaptureRequestDtoFromJSON(json: any): CatalogAnalyticCaptureRequestDto {
  return CatalogAnalyticCaptureRequestDtoFromJSONTyped(json, false)
}

export function CatalogAnalyticCaptureRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogAnalyticCaptureRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    event: EventNamesFromJSON(json["event"]),
    query: json["query"],
    data: json["data"],
  }
}

export function CatalogAnalyticCaptureRequestDtoToJSON(value?: CatalogAnalyticCaptureRequestDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    event: EventNamesToJSON(value.event),
    query: value.query,
    data: value.data,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderPaymentDto,
    OrderPaymentDtoFromJSON,
    OrderPaymentDtoFromJSONTyped,
    OrderPaymentDtoToJSON,
} from './OrderPaymentDto';

/**
 * 
 * @export
 * @interface OrderStartPaymentDto
 */
export interface OrderStartPaymentDto {
    /**
     * 
     * @type {OrderPaymentDto}
     * @memberof OrderStartPaymentDto
     */
    payment: OrderPaymentDto | null;
}


export function OrderStartPaymentDtoFromJSON(json: any): OrderStartPaymentDto {
    return OrderStartPaymentDtoFromJSONTyped(json, false);
}

export function OrderStartPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStartPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment': OrderPaymentDtoFromJSON(json['payment']),
    };
}

export function OrderStartPaymentDtoToJSON(value?: OrderStartPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment': OrderPaymentDtoToJSON(value.payment),
    };
}


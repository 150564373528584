/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BotCommandShortRequestDto
 */
export interface BotCommandShortRequestDto {
    /**
     * 
     * @type {object}
     * @memberof BotCommandShortRequestDto
     */
    params: object;
}


export function BotCommandShortRequestDtoFromJSON(json: any): BotCommandShortRequestDto {
    return BotCommandShortRequestDtoFromJSONTyped(json, false);
}

export function BotCommandShortRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BotCommandShortRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': json['params'],
    };
}

export function BotCommandShortRequestDtoToJSON(value?: BotCommandShortRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': value.params,
    };
}


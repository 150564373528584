/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    DeliveryBoxberryCityDto,
    DeliveryBoxberryCityDtoFromJSON,
    DeliveryBoxberryCityDtoToJSON,
    DeliveryBoxberryPointDto,
    DeliveryBoxberryPointDtoFromJSON,
    DeliveryBoxberryPointDtoToJSON,
    DeliveryCostDto,
    DeliveryCostDtoFromJSON,
    DeliveryCostDtoToJSON,
    WarehouseCdekOrderType,
    WarehouseCdekOrderTypeFromJSON,
    WarehouseCdekOrderTypeToJSON,
} from '../models';

export interface DeliveryBoxberryCitiesRequest {
    search?: string;
}

export interface DeliveryBoxberryCostRequest {
    type: WarehouseCdekOrderType;
    city: number;
}

export interface DeliveryBoxberryPointsRequest {
    cityId: string;
}

/**
 * 
 */
export class DeliveryBoxberryApi extends runtime.BaseAPI {

    /**
     */
    protected deliveryBoxberryCitiesRequestOpts = (requestParameters: DeliveryBoxberryCitiesRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-boxberry/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryBoxberryCitiesFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryBoxberryCityDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryBoxberryCityDtoFromJSON));
    }

    /**
     */
    protected deliveryBoxberryCitiesRaw = async (requestParameters: DeliveryBoxberryCitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryBoxberryCityDto>>> => {
        const context = this.deliveryBoxberryCitiesRequestOpts(requestParameters);
        return this.deliveryBoxberryCitiesFetch(context, initOverrides);
    }

    /**
     */
    deliveryBoxberryCities = async (search?: string, initOverrides?: RequestInit): Promise<Array<DeliveryBoxberryCityDto>> => {
        const response = await this.deliveryBoxberryCitiesRaw({ search: search }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryBoxberryCities = (() => {
        const key = (search?: string) => this.deliveryBoxberryCitiesRequestOpts({ search: search });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryBoxberryCitiesFetch(context));
        const fn = (search?: string, config?: SWRConfiguration<Array<DeliveryBoxberryCityDto>>): SWRResponse<Array<DeliveryBoxberryCityDto>> => {
            return useSWR<Array<DeliveryBoxberryCityDto>>(() => key(search), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected deliveryBoxberryCostRequestOpts = (requestParameters: DeliveryBoxberryCostRequest): runtime.RequestOpts => {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deliveryBoxberryCost.');
        }

        if (requestParameters.city === null || requestParameters.city === undefined) {
            throw new runtime.RequiredError('city','Required parameter requestParameters.city was null or undefined when calling deliveryBoxberryCost.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-boxberry/cost`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryBoxberryCostFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryCostDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCostDtoFromJSON(jsonValue));
    }

    /**
     */
    protected deliveryBoxberryCostRaw = async (requestParameters: DeliveryBoxberryCostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryCostDto>> => {
        const context = this.deliveryBoxberryCostRequestOpts(requestParameters);
        return this.deliveryBoxberryCostFetch(context, initOverrides);
    }

    /**
     */
    deliveryBoxberryCost = async (type: WarehouseCdekOrderType, city: number, initOverrides?: RequestInit): Promise<DeliveryCostDto> => {
        const response = await this.deliveryBoxberryCostRaw({ type: type, city: city }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryBoxberryCost = (() => {
        const key = (type: WarehouseCdekOrderType, city: number) => this.deliveryBoxberryCostRequestOpts({ type: type, city: city });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryBoxberryCostFetch(context));
        const fn = (type: WarehouseCdekOrderType, city: number, config?: SWRConfiguration<DeliveryCostDto>): SWRResponse<DeliveryCostDto> => {
            return useSWR<DeliveryCostDto>(() => key(type, city), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected deliveryBoxberryLocationSyncRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-boxberry/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryBoxberryLocationSyncFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected deliveryBoxberryLocationSyncRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.deliveryBoxberryLocationSyncRequestOpts();
        return this.deliveryBoxberryLocationSyncFetch(context, initOverrides);
    }

    /**
     */
    deliveryBoxberryLocationSync = async (initOverrides?: RequestInit): Promise<void> => {
        await this.deliveryBoxberryLocationSyncRaw(initOverrides);
    }



    /**
     */
    protected deliveryBoxberryPointsRequestOpts = (requestParameters: DeliveryBoxberryPointsRequest): runtime.RequestOpts => {
        if (requestParameters.cityId === null || requestParameters.cityId === undefined) {
            throw new runtime.RequiredError('cityId','Required parameter requestParameters.cityId was null or undefined when calling deliveryBoxberryPoints.');
        }

        const queryParameters: any = {};

        if (requestParameters.cityId !== undefined) {
            queryParameters['cityId'] = requestParameters.cityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-boxberry/points`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryBoxberryPointsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryBoxberryPointDto>>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryBoxberryPointDtoFromJSON));
    }

    /**
     */
    protected deliveryBoxberryPointsRaw = async (requestParameters: DeliveryBoxberryPointsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryBoxberryPointDto>>> => {
        const context = this.deliveryBoxberryPointsRequestOpts(requestParameters);
        return this.deliveryBoxberryPointsFetch(context, initOverrides);
    }

    /**
     */
    deliveryBoxberryPoints = async (cityId: string, initOverrides?: RequestInit): Promise<Array<DeliveryBoxberryPointDto>> => {
        const response = await this.deliveryBoxberryPointsRaw({ cityId: cityId }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryBoxberryPoints = (() => {
        const key = (cityId: string) => this.deliveryBoxberryPointsRequestOpts({ cityId: cityId });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryBoxberryPointsFetch(context));
        const fn = (cityId: string, config?: SWRConfiguration<Array<DeliveryBoxberryPointDto>>): SWRResponse<Array<DeliveryBoxberryPointDto>> => {
            return useSWR<Array<DeliveryBoxberryPointDto>>(() => key(cityId), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

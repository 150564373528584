/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductPropertyTypeDto,
  CatalogProductPropertyTypeDtoFromJSON,
  CatalogProductPropertyTypeDtoFromJSONTyped,
  CatalogProductPropertyTypeDtoToJSON,
} from "./CatalogProductPropertyTypeDto"
import {
  CatalogProductPropertyValueDto,
  CatalogProductPropertyValueDtoFromJSON,
  CatalogProductPropertyValueDtoFromJSONTyped,
  CatalogProductPropertyValueDtoToJSON,
} from "./CatalogProductPropertyValueDto"
import {
  CatalogProductSkuPropertiesDto,
  CatalogProductSkuPropertiesDtoFromJSON,
  CatalogProductSkuPropertiesDtoFromJSONTyped,
  CatalogProductSkuPropertiesDtoToJSON,
} from "./CatalogProductSkuPropertiesDto"

/**
 *
 * @export
 * @interface CatalogProductPropertiesDto
 */
export interface CatalogProductPropertiesDto {
  /**
   *
   * @type {Array<CatalogProductSkuPropertiesDto>}
   * @memberof CatalogProductPropertiesDto
   */
  skus: Array<CatalogProductSkuPropertiesDto>
  /**
   *
   * @type {Array<CatalogProductPropertyTypeDto>}
   * @memberof CatalogProductPropertiesDto
   */
  propertyTypes: Array<CatalogProductPropertyTypeDto>
  /**
   *
   * @type {Array<CatalogProductPropertyValueDto>}
   * @memberof CatalogProductPropertiesDto
   */
  propertyValues: Array<CatalogProductPropertyValueDto>
}

export function CatalogProductPropertiesDtoFromJSON(json: any): CatalogProductPropertiesDto {
  return CatalogProductPropertiesDtoFromJSONTyped(json, false)
}

export function CatalogProductPropertiesDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductPropertiesDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    skus: (json["skus"] as Array<any>).map(CatalogProductSkuPropertiesDtoFromJSON),
    propertyTypes: (json["propertyTypes"] as Array<any>).map(CatalogProductPropertyTypeDtoFromJSON),
    propertyValues: (json["propertyValues"] as Array<any>).map(CatalogProductPropertyValueDtoFromJSON),
  }
}

export function CatalogProductPropertiesDtoToJSON(value?: CatalogProductPropertiesDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    skus: (value.skus as Array<any>).map(CatalogProductSkuPropertiesDtoToJSON),
    propertyTypes: (value.propertyTypes as Array<any>).map(CatalogProductPropertyTypeDtoToJSON),
    propertyValues: (value.propertyValues as Array<any>).map(CatalogProductPropertyValueDtoToJSON),
  }
}

import { FrontApi } from "./front"
import { BackApi } from "./back"
import { BackCatalogApi } from "./catalog"
import { StrapiUnicornApi } from "./strapi-unicorn"
import { StrapiPoizonShopApi } from "shared/api/strapi-poizonshop"

export const strapiPoizonshopApi = new StrapiPoizonShopApi(process.env.NEXT_PUBLIC_STRAPI_POIZONSHOP_API_URL!)
export const strapiUnicornApi = new StrapiUnicornApi(process.env.NEXT_PUBLIC_STRAPI_UNICORN_API_URL!)
export const catalogApi = new BackCatalogApi()
export const api = new BackApi()
export const frontApi = new FrontApi()

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPaymentReferMpcVip
 */
export interface OrderPaymentReferMpcVip {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentReferMpcVip
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentReferMpcVip
     */
    failedRedirectUrl?: string;
}


export function OrderPaymentReferMpcVipFromJSON(json: any): OrderPaymentReferMpcVip {
    return OrderPaymentReferMpcVipFromJSONTyped(json, false);
}

export function OrderPaymentReferMpcVipFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPaymentReferMpcVip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'failedRedirectUrl': !exists(json, 'failedRedirectUrl') ? undefined : json['failedRedirectUrl'],
    };
}

export function OrderPaymentReferMpcVipToJSON(value?: OrderPaymentReferMpcVip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectUrl': value.redirectUrl,
        'failedRedirectUrl': value.failedRedirectUrl,
    };
}


import { CatalogApi, CatalogProductListDto, CatalogProductListRequest } from "../_generated"
import useSWRInfinite from "swr/infinite"
import { SWRConfiguration } from "swr"

export class CatalogInfiniteApi extends CatalogApi {
  useProductsInfinite = (request: Omit<CatalogProductListRequest, "page">, config?: SWRConfiguration) => {
    return useSWRInfinite<CatalogProductListDto>(
      (page) => this.catalogProductListRequestOpts({ perPage: 100, ...request, page: page + 1 }),
      (context) => this.swrFetch(this.catalogProductListFetch(context)),
      config,
    )
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerProductViewAddDto,
    CustomerProductViewAddDtoFromJSON,
    CustomerProductViewAddDtoToJSON,
    CustomerProductViewCollectionDto,
    CustomerProductViewCollectionDtoFromJSON,
    CustomerProductViewCollectionDtoToJSON,
} from '../models';

export interface CustomerProductViewAddRequest {
    customerProductViewAddDto: CustomerProductViewAddDto;
}

export interface CustomerProductViewsRequest {
    page?: number;
    perPage?: number;
}

/**
 * 
 */
export class CustomerProductViewApi extends runtime.BaseAPI {

    /**
     */
    protected customerProductViewAddRequestOpts = (requestParameters: CustomerProductViewAddRequest): runtime.RequestOpts => {
        if (requestParameters.customerProductViewAddDto === null || requestParameters.customerProductViewAddDto === undefined) {
            throw new runtime.RequiredError('customerProductViewAddDto','Required parameter requestParameters.customerProductViewAddDto was null or undefined when calling customerProductViewAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-product-view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerProductViewAddDtoToJSON(requestParameters.customerProductViewAddDto),
        };
    }

    /**
     */
    protected customerProductViewAddFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerProductViewAddRaw = async (requestParameters: CustomerProductViewAddRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerProductViewAddRequestOpts(requestParameters);
        return this.customerProductViewAddFetch(context, initOverrides);
    }

    /**
     */
    customerProductViewAdd = async (customerProductViewAddDto: CustomerProductViewAddDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerProductViewAddRaw({ customerProductViewAddDto: customerProductViewAddDto }, initOverrides);
    }



    /**
     */
    protected customerProductViewsRequestOpts = (requestParameters: CustomerProductViewsRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-product-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerProductViewsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerProductViewCollectionDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerProductViewCollectionDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerProductViewsRaw = async (requestParameters: CustomerProductViewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerProductViewCollectionDto>> => {
        const context = this.customerProductViewsRequestOpts(requestParameters);
        return this.customerProductViewsFetch(context, initOverrides);
    }

    /**
     */
    customerProductViews = async (page?: number, perPage?: number, initOverrides?: RequestInit): Promise<CustomerProductViewCollectionDto> => {
        const response = await this.customerProductViewsRaw({ page: page, perPage: perPage }, initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerProductViews = (() => {
        const key = (page?: number, perPage?: number) => this.customerProductViewsRequestOpts({ page: page, perPage: perPage });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerProductViewsFetch(context));
        const fn = (page?: number, perPage?: number, config?: SWRConfiguration<CustomerProductViewCollectionDto>): SWRResponse<CustomerProductViewCollectionDto> => {
            return useSWR<CustomerProductViewCollectionDto>(() => key(page, perPage), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductPropertyValueDto,
  CatalogProductPropertyValueDtoFromJSON,
  CatalogProductPropertyValueDtoFromJSONTyped,
  CatalogProductPropertyValueDtoToJSON,
} from "./CatalogProductPropertyValueDto"

/**
 *
 * @export
 * @interface CatalogProductSkuPropertiesDto
 */
export interface CatalogProductSkuPropertiesDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductSkuPropertiesDto
   */
  skuId: number
  /**
   *
   * @type {Array<CatalogProductPropertyValueDto>}
   * @memberof CatalogProductSkuPropertiesDto
   */
  properties: Array<CatalogProductPropertyValueDto>
}

export function CatalogProductSkuPropertiesDtoFromJSON(json: any): CatalogProductSkuPropertiesDto {
  return CatalogProductSkuPropertiesDtoFromJSONTyped(json, false)
}

export function CatalogProductSkuPropertiesDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductSkuPropertiesDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    skuId: json["skuId"],
    properties: (json["properties"] as Array<any>).map(CatalogProductPropertyValueDtoFromJSON),
  }
}

export function CatalogProductSkuPropertiesDtoToJSON(value?: CatalogProductSkuPropertiesDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    skuId: value.skuId,
    properties: (value.properties as Array<any>).map(CatalogProductPropertyValueDtoToJSON),
  }
}

import { CustomerApi, CustomerPointsOperationsDto, CustomerPointsOperationsRequest } from "../_generated"
import useSWRInfinite from "swr/infinite"
import { SWRConfiguration } from "swr"

export class PointInfiniteApi extends CustomerApi {
  usePointInfinite = (request: Omit<CustomerPointsOperationsRequest, "page">, config?: SWRConfiguration) => {
    return useSWRInfinite<CustomerPointsOperationsDto>(
      (page) => this.customerPointsOperationsRequestOpts({ ...request, page: page + 1 }),
      (context) => this.swrFetch(this.customerPointsOperationsFetch(context)),
      config,
    )
  }
}

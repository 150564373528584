/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductBasicPropertiesDto,
  CatalogProductBasicPropertiesDtoFromJSON,
  CatalogProductBasicPropertiesDtoFromJSONTyped,
  CatalogProductBasicPropertiesDtoToJSON,
} from "./CatalogProductBasicPropertiesDto"
import {
  CatalogProductCategoryDto,
  CatalogProductCategoryDtoFromJSON,
  CatalogProductCategoryDtoFromJSONTyped,
  CatalogProductCategoryDtoToJSON,
} from "./CatalogProductCategoryDto"
import {
  CatalogProductMetadataDto,
  CatalogProductMetadataDtoFromJSON,
  CatalogProductMetadataDtoFromJSONTyped,
  CatalogProductMetadataDtoToJSON,
} from "./CatalogProductMetadataDto"
import {
  CatalogProductPriceAvailability,
  CatalogProductPriceAvailabilityFromJSON,
  CatalogProductPriceAvailabilityFromJSONTyped,
  CatalogProductPriceAvailabilityToJSON,
} from "./CatalogProductPriceAvailability"
import {
  CatalogProductPropertiesDto,
  CatalogProductPropertiesDtoFromJSON,
  CatalogProductPropertiesDtoFromJSONTyped,
  CatalogProductPropertiesDtoToJSON,
} from "./CatalogProductPropertiesDto"
import {
  CatalogProductSizeTableRowDto,
  CatalogProductSizeTableRowDtoFromJSON,
  CatalogProductSizeTableRowDtoFromJSONTyped,
  CatalogProductSizeTableRowDtoToJSON,
} from "./CatalogProductSizeTableRowDto"
import {
  CatalogProductSkuDto,
  CatalogProductSkuDtoFromJSON,
  CatalogProductSkuDtoFromJSONTyped,
  CatalogProductSkuDtoToJSON,
} from "./CatalogProductSkuDto"

/**
 *
 * @export
 * @interface CatalogProductDto
 */
export interface CatalogProductDto {
  /**
   *
   * @type {CatalogProductPriceAvailability}
   * @memberof CatalogProductDto
   */
  availability: CatalogProductPriceAvailability
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  fit?: CatalogProductDtoFitEnum
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  spuId: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  article: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  slug: string
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  category1: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  category2: number
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  category3: number
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductDto
   */
  images: Array<string>
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  brand: string
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  brandId: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof CatalogProductDto
   */
  price: number
  /**
   *
   * @type {Array<CatalogProductSkuDto>}
   * @memberof CatalogProductDto
   */
  skus: Array<CatalogProductSkuDto>
  /**
   *
   * @type {Array<CatalogProductSizeTableRowDto>}
   * @memberof CatalogProductDto
   */
  sizeTable: Array<CatalogProductSizeTableRowDto>
  /**
   *
   * @type {CatalogProductCategoryDto}
   * @memberof CatalogProductDto
   */
  category: CatalogProductCategoryDto
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  primarySizeType?: string
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductDto
   */
  fromAvailability: boolean
  /**
   *
   * @type {CatalogProductMetadataDto}
   * @memberof CatalogProductDto
   */
  metadata?: CatalogProductMetadataDto
  /**
   *
   * @type {CatalogProductPropertiesDto}
   * @memberof CatalogProductDto
   */
  properties: CatalogProductPropertiesDto
  /**
   *
   * @type {string}
   * @memberof CatalogProductDto
   */
  colorTheme?: string
  /**
   *
   * @type {Array<CatalogProductBasicPropertiesDto>}
   * @memberof CatalogProductDto
   */
  productProperties?: Array<CatalogProductBasicPropertiesDto>
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductDto
   */
  returnable: boolean
}

/**
 * @export
 * @enum {string}
 */
export type CatalogProductDtoFitEnum = "UNISEX" | "MALE" | "FEMALE" | "CHILD" | "BABY" | "MIDDLE_CHILD" | "ELDEST_CHILD"

export function CatalogProductDtoFromJSON(json: any): CatalogProductDto {
  return CatalogProductDtoFromJSONTyped(json, false)
}

export function CatalogProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    availability: CatalogProductPriceAvailabilityFromJSON(json["availability"]),
    fit: !exists(json, "fit") ? undefined : json["fit"],
    spuId: json["spuId"],
    article: json["article"],
    slug: json["slug"],
    category1: json["category1"],
    category2: json["category2"],
    category3: json["category3"],
    images: json["images"],
    brand: json["brand"],
    brandId: json["brandId"],
    name: json["name"],
    description: json["description"],
    price: json["price"],
    skus: (json["skus"] as Array<any>).map(CatalogProductSkuDtoFromJSON),
    sizeTable: (json["sizeTable"] as Array<any>).map(CatalogProductSizeTableRowDtoFromJSON),
    category: CatalogProductCategoryDtoFromJSON(json["category"]),
    primarySizeType: !exists(json, "primarySizeType") ? undefined : json["primarySizeType"],
    fromAvailability: json["fromAvailability"],
    metadata: !exists(json, "metadata") ? undefined : CatalogProductMetadataDtoFromJSON(json["metadata"]),
    properties: CatalogProductPropertiesDtoFromJSON(json["properties"]),
    colorTheme: !exists(json, "colorTheme") ? undefined : json["colorTheme"],
    productProperties: !exists(json, "productProperties")
      ? undefined
      : (json["productProperties"] as Array<any>).map(CatalogProductBasicPropertiesDtoFromJSON),
    returnable: json["returnable"],
  }
}

export function CatalogProductDtoToJSON(value?: CatalogProductDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    availability: CatalogProductPriceAvailabilityToJSON(value.availability),
    fit: value.fit,
    spuId: value.spuId,
    article: value.article,
    slug: value.slug,
    category1: value.category1,
    category2: value.category2,
    category3: value.category3,
    images: value.images,
    brand: value.brand,
    brandId: value.brandId,
    name: value.name,
    description: value.description,
    price: value.price,
    skus: (value.skus as Array<any>).map(CatalogProductSkuDtoToJSON),
    sizeTable: (value.sizeTable as Array<any>).map(CatalogProductSizeTableRowDtoToJSON),
    category: CatalogProductCategoryDtoToJSON(value.category),
    primarySizeType: value.primarySizeType,
    fromAvailability: value.fromAvailability,
    metadata: CatalogProductMetadataDtoToJSON(value.metadata),
    properties: CatalogProductPropertiesDtoToJSON(value.properties),
    colorTheme: value.colorTheme,
    productProperties:
      value.productProperties === undefined
        ? undefined
        : (value.productProperties as Array<any>).map(CatalogProductBasicPropertiesDtoToJSON),
    returnable: value.returnable,
  }
}

import { CalculateDto } from "./calculate.request.dto"
import { AskQuestionDto } from "./ask-question-request.dto"
import { OrderRequestDto } from "./order-request.dto"

export class FrontApi {
  async order(data: OrderRequestDto) {
    const resp = await fetch("/api/order", { method: "POST", body: JSON.stringify(data) })
  }

  async askQuestion(data: AskQuestionDto) {
    const resp = await fetch("/api/ask-question", { method: "POST", body: JSON.stringify(data) })
  }

  async calculate(data: CalculateDto) {
    const resp = await fetch("/api/calculate", { method: "POST", body: JSON.stringify(data) })
  }
}

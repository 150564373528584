/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCreateV2GoogleAnalyticsDto
 */
export interface OrderCreateV2GoogleAnalyticsDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2GoogleAnalyticsDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2GoogleAnalyticsDto
     */
    measurementId?: string;
}


export function OrderCreateV2GoogleAnalyticsDtoFromJSON(json: any): OrderCreateV2GoogleAnalyticsDto {
    return OrderCreateV2GoogleAnalyticsDtoFromJSONTyped(json, false);
}

export function OrderCreateV2GoogleAnalyticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2GoogleAnalyticsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'measurementId': !exists(json, 'measurementId') ? undefined : json['measurementId'],
    };
}

export function OrderCreateV2GoogleAnalyticsDtoToJSON(value?: OrderCreateV2GoogleAnalyticsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'measurementId': value.measurementId,
    };
}


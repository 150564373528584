/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReviewDto,
    ReviewDtoFromJSON,
    ReviewDtoFromJSONTyped,
    ReviewDtoToJSON,
} from './ReviewDto';

/**
 * 
 * @export
 * @interface ReviewsCollectionDto
 */
export interface ReviewsCollectionDto {
    /**
     * 
     * @type {number}
     * @memberof ReviewsCollectionDto
     */
    total: number;
    /**
     * 
     * @type {Array<ReviewDto>}
     * @memberof ReviewsCollectionDto
     */
    items: Array<ReviewDto>;
}


export function ReviewsCollectionDtoFromJSON(json: any): ReviewsCollectionDto {
    return ReviewsCollectionDtoFromJSONTyped(json, false);
}

export function ReviewsCollectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewsCollectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(ReviewDtoFromJSON)),
    };
}

export function ReviewsCollectionDtoToJSON(value?: ReviewsCollectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': ((value.items as Array<any>).map(ReviewDtoToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerOrderPaymentType {
    MANUAL = 'MANUAL',
    MPCVIP = 'MPCVIP',
    MPCVIP_REFER = 'MPCVIP_REFER',
    CART = 'CART',
    ROCKET_WORK = 'ROCKET_WORK',
    ROCKET_WORK_TKB = 'ROCKET_WORK_TKB',
    ONE_PAYMENTS = 'ONE_PAYMENTS',
    ONE_PAYMENTS_CUSTOM = 'ONE_PAYMENTS_CUSTOM',
    COMPANY_MONEY = 'COMPANY_MONEY',
    CUSTOM = 'CUSTOM',
    CASH = 'CASH',
    YOOKASSA = 'YOOKASSA',
    AIFORY = 'AIFORY',
    LEGIT_PAY_CUSTOM_FORM = 'LEGIT_PAY_CUSTOM_FORM',
    LEGIT_PAY_SIMPLE_FORM = 'LEGIT_PAY_SIMPLE_FORM',
    AIFORY_CUSTOM = 'AIFORY_CUSTOM',
    X_PAY = 'X_PAY',
    SBC_TECH = 'SBC_TECH',
    ALFA_BANK = 'ALFA_BANK'
}

export function CustomerOrderPaymentTypeFromJSON(json: any): CustomerOrderPaymentType {
    return CustomerOrderPaymentTypeFromJSONTyped(json, false);
}

export function CustomerOrderPaymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderPaymentType {
    return json as CustomerOrderPaymentType;
}

export function CustomerOrderPaymentTypeToJSON(value?: CustomerOrderPaymentType | null): any {
    return value as any;
}


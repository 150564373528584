/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderInfoDeliveryType,
    OrderInfoDeliveryTypeFromJSON,
    OrderInfoDeliveryTypeFromJSONTyped,
    OrderInfoDeliveryTypeToJSON,
} from './OrderInfoDeliveryType';

/**
 * 
 * @export
 * @interface OrderUpdateDeliveryRequestDto
 */
export interface OrderUpdateDeliveryRequestDto {
    /**
     * 
     * @type {OrderInfoDeliveryType}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryType?: OrderInfoDeliveryType;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryFio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryPhone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryCity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryIssuingWarehouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryBoxberryCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryBoxberryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDeliveryRequestDto
     */
    deliveryBoxberryIssuingWarehouse?: string | null;
}


export function OrderUpdateDeliveryRequestDtoFromJSON(json: any): OrderUpdateDeliveryRequestDto {
    return OrderUpdateDeliveryRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateDeliveryRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateDeliveryRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryType': !exists(json, 'deliveryType') ? undefined : OrderInfoDeliveryTypeFromJSON(json['deliveryType']),
        'deliveryFio': !exists(json, 'deliveryFio') ? undefined : json['deliveryFio'],
        'deliveryPhone': !exists(json, 'deliveryPhone') ? undefined : json['deliveryPhone'],
        'deliveryCity': !exists(json, 'deliveryCity') ? undefined : json['deliveryCity'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : json['deliveryAddress'],
        'deliveryComment': !exists(json, 'deliveryComment') ? undefined : json['deliveryComment'],
        'deliveryIssuingWarehouse': !exists(json, 'deliveryIssuingWarehouse') ? undefined : json['deliveryIssuingWarehouse'],
        'deliveryBoxberryCity': !exists(json, 'deliveryBoxberryCity') ? undefined : json['deliveryBoxberryCity'],
        'deliveryBoxberryAddress': !exists(json, 'deliveryBoxberryAddress') ? undefined : json['deliveryBoxberryAddress'],
        'deliveryBoxberryIssuingWarehouse': !exists(json, 'deliveryBoxberryIssuingWarehouse') ? undefined : json['deliveryBoxberryIssuingWarehouse'],
    };
}

export function OrderUpdateDeliveryRequestDtoToJSON(value?: OrderUpdateDeliveryRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryType': OrderInfoDeliveryTypeToJSON(value.deliveryType),
        'deliveryFio': value.deliveryFio,
        'deliveryPhone': value.deliveryPhone,
        'deliveryCity': value.deliveryCity,
        'deliveryAddress': value.deliveryAddress,
        'deliveryComment': value.deliveryComment,
        'deliveryIssuingWarehouse': value.deliveryIssuingWarehouse,
        'deliveryBoxberryCity': value.deliveryBoxberryCity,
        'deliveryBoxberryAddress': value.deliveryBoxberryAddress,
        'deliveryBoxberryIssuingWarehouse': value.deliveryBoxberryIssuingWarehouse,
    };
}


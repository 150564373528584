/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    ReviewDto,
    ReviewDtoFromJSON,
    ReviewDtoToJSON,
    ReviewsDto,
    ReviewsDtoFromJSON,
    ReviewsDtoToJSON,
} from '../models';

export interface ReviewRequest {
    id: string;
}

export interface ReviewsRequest {
    spuId?: string;
    brandName?: string;
    hasPhotos?: boolean;
    page?: number;
    perPage?: number;
}

/**
 * 
 */
export class ReviewApi extends runtime.BaseAPI {

    /**
     */
    protected reviewRequestOpts = (requestParameters: ReviewRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling review.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/review/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected reviewFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewDtoFromJSON(jsonValue));
    }

    /**
     */
    protected reviewRaw = async (requestParameters: ReviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewDto>> => {
        const context = this.reviewRequestOpts(requestParameters);
        return this.reviewFetch(context, initOverrides);
    }

    /**
     */
    review = async (id: string, initOverrides?: RequestInit): Promise<ReviewDto> => {
        const response = await this.reviewRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    useReview = (() => {
        const key = (id: string) => this.reviewRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.reviewFetch(context));
        const fn = (id: string, config?: SWRConfiguration<ReviewDto>): SWRResponse<ReviewDto> => {
            return useSWR<ReviewDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected reviewsRequestOpts = (requestParameters: ReviewsRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.spuId !== undefined) {
            queryParameters['spuId'] = requestParameters.spuId;
        }

        if (requestParameters.brandName !== undefined) {
            queryParameters['brandName'] = requestParameters.brandName;
        }

        if (requestParameters.hasPhotos !== undefined) {
            queryParameters['hasPhotos'] = requestParameters.hasPhotos;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/review`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected reviewsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected reviewsRaw = async (requestParameters: ReviewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviewsDto>> => {
        const context = this.reviewsRequestOpts(requestParameters);
        return this.reviewsFetch(context, initOverrides);
    }

    /**
     */
    reviews = async (spuId?: string, brandName?: string, hasPhotos?: boolean, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<ReviewsDto> => {
        const response = await this.reviewsRaw({ spuId: spuId, brandName: brandName, hasPhotos: hasPhotos, page: page, perPage: perPage }, initOverrides);
        return await response.value();
    }


    /**
     */
    useReviews = (() => {
        const key = (requestParameters: ReviewsRequest = {}, config?: SWRConfiguration<ReviewsDto>) => this.reviewsRequestOpts(requestParameters);
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.reviewsFetch(context));
        const fn = (requestParameters: ReviewsRequest = {}, config?: SWRConfiguration<ReviewsDto>): SWRResponse<ReviewsDto> => {
            return useSWR<ReviewsDto>(() => key(requestParameters), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

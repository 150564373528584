/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    ManualPaymentCardDto,
    ManualPaymentCardDtoFromJSON,
    ManualPaymentCardDtoToJSON,
    ManualPaymentCardsDto,
    ManualPaymentCardsDtoFromJSON,
    ManualPaymentCardsDtoToJSON,
} from '../models';

export interface PaymentCardRequest {
    id: string;
}

/**
 * 
 */
export class ManualPaymentCardApi extends runtime.BaseAPI {

    /**
     */
    protected paymentCardRequestOpts = (requestParameters: PaymentCardRequest): runtime.RequestOpts => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling paymentCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/manual-payment-card/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected paymentCardFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManualPaymentCardDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualPaymentCardDtoFromJSON(jsonValue));
    }

    /**
     */
    protected paymentCardRaw = async (requestParameters: PaymentCardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManualPaymentCardDto>> => {
        const context = this.paymentCardRequestOpts(requestParameters);
        return this.paymentCardFetch(context, initOverrides);
    }

    /**
     */
    paymentCard = async (id: string, initOverrides?: RequestInit): Promise<ManualPaymentCardDto> => {
        const response = await this.paymentCardRaw({ id: id }, initOverrides);
        return await response.value();
    }


    /**
     */
    usePaymentCard = (() => {
        const key = (id: string) => this.paymentCardRequestOpts({ id: id });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.paymentCardFetch(context));
        const fn = (id: string, config?: SWRConfiguration<ManualPaymentCardDto>): SWRResponse<ManualPaymentCardDto> => {
            return useSWR<ManualPaymentCardDto>(() => key(id), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected paymentCardsRequestOpts = (): runtime.RequestOpts => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/manual-payment-card`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected paymentCardsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManualPaymentCardsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualPaymentCardsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected paymentCardsRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManualPaymentCardsDto>> => {
        const context = this.paymentCardsRequestOpts();
        return this.paymentCardsFetch(context, initOverrides);
    }

    /**
     */
    paymentCards = async (initOverrides?: RequestInit): Promise<ManualPaymentCardsDto> => {
        const response = await this.paymentCardsRaw(initOverrides);
        return await response.value();
    }


    /**
     */
    usePaymentCards = (() => {
        const key = () => this.paymentCardsRequestOpts();
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.paymentCardsFetch(context));
        const fn = (config?: SWRConfiguration<ManualPaymentCardsDto>): SWRResponse<ManualPaymentCardsDto> => {
            return useSWR<ManualPaymentCardsDto>(() => key(), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

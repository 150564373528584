/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicOrderGenerateRocketWorkPaymentRequestDto
 */
export interface PublicOrderGenerateRocketWorkPaymentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PublicOrderGenerateRocketWorkPaymentRequestDto
     */
    type: PublicOrderGenerateRocketWorkPaymentRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderGenerateRocketWorkPaymentRequestDto
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderGenerateRocketWorkPaymentRequestDto
     */
    failedRedirectUrl?: string;
}

/**
* @export
* @enum {string}
*/
export type PublicOrderGenerateRocketWorkPaymentRequestDtoTypeEnum =
    | 'QR'
    | 'CARD'



export function PublicOrderGenerateRocketWorkPaymentRequestDtoFromJSON(json: any): PublicOrderGenerateRocketWorkPaymentRequestDto {
    return PublicOrderGenerateRocketWorkPaymentRequestDtoFromJSONTyped(json, false);
}

export function PublicOrderGenerateRocketWorkPaymentRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicOrderGenerateRocketWorkPaymentRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'failedRedirectUrl': !exists(json, 'failedRedirectUrl') ? undefined : json['failedRedirectUrl'],
    };
}

export function PublicOrderGenerateRocketWorkPaymentRequestDtoToJSON(value?: PublicOrderGenerateRocketWorkPaymentRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'redirectUrl': value.redirectUrl,
        'failedRedirectUrl': value.failedRedirectUrl,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCreateV2UtmDto
 */
export interface OrderCreateV2UtmDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2UtmDto
     */
    utmSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2UtmDto
     */
    utmMedium?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2UtmDto
     */
    utmCampaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2UtmDto
     */
    utmTerm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2UtmDto
     */
    utmContent?: string | null;
}


export function OrderCreateV2UtmDtoFromJSON(json: any): OrderCreateV2UtmDto {
    return OrderCreateV2UtmDtoFromJSONTyped(json, false);
}

export function OrderCreateV2UtmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2UtmDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'utmSource': !exists(json, 'utm_source') ? undefined : json['utm_source'],
        'utmMedium': !exists(json, 'utm_medium') ? undefined : json['utm_medium'],
        'utmCampaign': !exists(json, 'utm_campaign') ? undefined : json['utm_campaign'],
        'utmTerm': !exists(json, 'utm_term') ? undefined : json['utm_term'],
        'utmContent': !exists(json, 'utm_content') ? undefined : json['utm_content'],
    };
}

export function OrderCreateV2UtmDtoToJSON(value?: OrderCreateV2UtmDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'utm_source': value.utmSource,
        'utm_medium': value.utmMedium,
        'utm_campaign': value.utmCampaign,
        'utm_term': value.utmTerm,
        'utm_content': value.utmContent,
    };
}


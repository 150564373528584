import {
  ActionApi,
  AuthApi,
  BotCommandShortApi,
  Configuration,
  CustomerDiscountApi,
  CustomerOnetimeAuthApi,
  CustomerOrderReviewApi,
  CustomerProductViewApi,
  DeliveryApi,
  DeliveryBoxberryApi,
  DeliveryPointsApi,
  ImageApi,
  ManualPaymentCardApi,
  OrderV2Api,
  PaymentFlowApi,
  PriceApi,
  QuestionApi,
  StoriesApi,
} from "./_generated"
import { GeocoderApi } from "./_generated/apis/GeocoderApi"
import { BackApiConfig } from "./config"
import { PointInfiniteApi, ReviewsInfiniteApi } from "./infinity"
import { CustomerApiOverridden, CustomerSizeApiOverridden, FavoriteApiOverridden, OrderApiOverridden } from "./override"

export class BackApi {
  readonly action = new ActionApi(this.config)
  readonly auth = new AuthApi(this.config)
  readonly customer = new CustomerApiOverridden(this.config)
  readonly customerOnetimeAuth = new CustomerOnetimeAuthApi(this.config)
  readonly delivery = new DeliveryApi(this.config)
  readonly deliveryBoxberry = new DeliveryBoxberryApi(this.config)
  readonly favorite = new FavoriteApiOverridden(this.config)
  readonly image = new ImageApi(this.config)
  readonly manualPaymentCard = new ManualPaymentCardApi(this.config)
  readonly order = new OrderApiOverridden(this.config)
  readonly orderV2 = new OrderV2Api(this.config)
  readonly price = new PriceApi(this.config)
  readonly stories = new StoriesApi(this.config)
  readonly point = new PointInfiniteApi(this.config)
  readonly customerSize = new CustomerSizeApiOverridden(this.config)
  readonly question = new QuestionApi(this.config)
  readonly deliveryPoints = new DeliveryPointsApi(this.config)
  readonly geocoder = new GeocoderApi(this.config)
  readonly paymentFlow = new PaymentFlowApi(this.config)
  readonly botCommandShort = new BotCommandShortApi(this.config)
  readonly customerOrderReview = new CustomerOrderReviewApi(this.config)
  readonly review = new ReviewsInfiniteApi(this.config)
  readonly customerProductView = new CustomerProductViewApi(this.config)
  readonly customerDiscount = new CustomerDiscountApi(this.config)

  constructor(private config: Configuration = new BackApiConfig()) {}
}

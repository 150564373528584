/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductPropertyValueDto,
  CatalogProductPropertyValueDtoFromJSON,
  CatalogProductPropertyValueDtoFromJSONTyped,
  CatalogProductPropertyValueDtoToJSON,
} from "./CatalogProductPropertyValueDto"

/**
 *
 * @export
 * @interface CatalogProductPropertyTypeDto
 */
export interface CatalogProductPropertyTypeDto {
  /**
   *
   * @type {number}
   * @memberof CatalogProductPropertyTypeDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductPropertyTypeDto
   */
  name: string
  /**
   *
   * @type {Array<CatalogProductPropertyValueDto>}
   * @memberof CatalogProductPropertyTypeDto
   */
  values: Array<CatalogProductPropertyValueDto>
}

export function CatalogProductPropertyTypeDtoFromJSON(json: any): CatalogProductPropertyTypeDto {
  return CatalogProductPropertyTypeDtoFromJSONTyped(json, false)
}

export function CatalogProductPropertyTypeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductPropertyTypeDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    name: json["name"],
    values: (json["values"] as Array<any>).map(CatalogProductPropertyValueDtoFromJSON),
  }
}

export function CatalogProductPropertyTypeDtoToJSON(value?: CatalogProductPropertyTypeDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    values: (value.values as Array<any>).map(CatalogProductPropertyValueDtoToJSON),
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderUpdateRequestDto
 */
export interface OrderUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequestDto
     */
    productCategoryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequestDto
     */
    productUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequestDto
     */
    productModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequestDto
     */
    productSize?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateRequestDto
     */
    productPriceCNY?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderUpdateRequestDto
     */
    productImageIds?: Array<string>;
}


export function OrderUpdateRequestDtoFromJSON(json: any): OrderUpdateRequestDto {
    return OrderUpdateRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productCategoryId': !exists(json, 'productCategoryId') ? undefined : json['productCategoryId'],
        'productUrl': !exists(json, 'productUrl') ? undefined : json['productUrl'],
        'productModel': !exists(json, 'productModel') ? undefined : json['productModel'],
        'productSize': !exists(json, 'productSize') ? undefined : json['productSize'],
        'productPriceCNY': !exists(json, 'productPriceCNY') ? undefined : json['productPriceCNY'],
        'productImageIds': !exists(json, 'productImageIds') ? undefined : json['productImageIds'],
    };
}

export function OrderUpdateRequestDtoToJSON(value?: OrderUpdateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productCategoryId': value.productCategoryId,
        'productUrl': value.productUrl,
        'productModel': value.productModel,
        'productSize': value.productSize,
        'productPriceCNY': value.productPriceCNY,
        'productImageIds': value.productImageIds,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CatalogProductCategory {
  sneakers = "sneakers",
  shoes = "shoes",
  apparel = "apparel",
  accessories = "accessories",
}

export function CatalogProductCategoryFromJSON(json: any): CatalogProductCategory {
  return CatalogProductCategoryFromJSONTyped(json, false)
}

export function CatalogProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductCategory {
  return json as CatalogProductCategory
}

export function CatalogProductCategoryToJSON(value?: CatalogProductCategory | null): any {
  return value as any
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerProductViewDto
 */
export interface CustomerProductViewDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerProductViewDto
     */
    spuId: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerProductViewDto
     */
    createdAt: Date;
}


export function CustomerProductViewDtoFromJSON(json: any): CustomerProductViewDto {
    return CustomerProductViewDtoFromJSONTyped(json, false);
}

export function CustomerProductViewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerProductViewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spuId': json['spuId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CustomerProductViewDtoToJSON(value?: CustomerProductViewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spuId': value.spuId,
        'createdAt': (value.createdAt.toISOString()),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentType {
    TRANSFER_BY_PHONE = 'TRANSFER_BY_PHONE',
    TRANSFER_TO_CARD = 'TRANSFER_TO_CARD',
    SBP = 'SBP',
    CARD = 'CARD',
    EXTERNAL = 'EXTERNAL',
    NSPK_LINK = 'NSPK_LINK',
    YOO_KASSA_WIDGET = 'YOO_KASSA_WIDGET'
}

export function PaymentTypeFromJSON(json: any): PaymentType {
    return PaymentTypeFromJSONTyped(json, false);
}

export function PaymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentType {
    return json as PaymentType;
}

export function PaymentTypeToJSON(value?: PaymentType | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentFlowTransferDto
 */
export interface PaymentFlowTransferDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowTransferDto
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowTransferDto
     */
    cardOwner: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowTransferDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowTransferDto
     */
    bankName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PaymentFlowTransferDto
     */
    deadline: Date | null;
}


export function PaymentFlowTransferDtoFromJSON(json: any): PaymentFlowTransferDto {
    return PaymentFlowTransferDtoFromJSONTyped(json, false);
}

export function PaymentFlowTransferDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowTransferDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'cardOwner': json['cardOwner'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'bankName': json['bankName'],
        'deadline': (json['deadline'] === null ? null : new Date(json['deadline'])),
    };
}

export function PaymentFlowTransferDtoToJSON(value?: PaymentFlowTransferDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cardNumber': value.cardNumber,
        'cardOwner': value.cardOwner,
        'phone': value.phone,
        'bankName': value.bankName,
        'deadline': (value.deadline === null ? null : value.deadline.toISOString()),
    };
}


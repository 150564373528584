import { api } from "shared/api/init"
import { ResponseContext } from "../_generated"
import { setApiKey } from "../lib/current-api-key"
import posthog from "posthog-js"

const baseUrl = process.env.NEXT_PUBLIC_CRM_API_PREFIX!

export const successAuthMiddleware = async (context: ResponseContext): Promise<Response | void> => {
  let url = context.url

  if (url.startsWith(baseUrl)) {
    url = url.substring(baseUrl.length)
  }

  if (url.startsWith("/main-api")) {
    url = url.substring("/main-api".length)
  }

  if (
    context.response.ok &&
    (url === `/api/customer-bot/auth/sms-code/verify` ||
      url === `/api/customer-bot/auth/telegram` ||
      url === `/api/customer-bot/auth/onetime` ||
      (url.startsWith("/api/customer-bot/auth/telegram/onetime/") && url.endsWith("/api-key"))) &&
    context.init.method === "POST"
  ) {
    const response = await context.response.json()
    setApiKey(response.value)
  }

  if (context.response.status === 401) {
    setApiKey(undefined)
    return
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CatalogProductFit {
  UNISEX = "UNISEX",
  MALE = "MALE",
  FEMALE = "FEMALE",
  CHILD = "CHILD",
  BABY = "BABY",
  MIDDLE_CHILD = "MIDDLE_CHILD",
  ELDEST_CHILD = "ELDEST_CHILD",
}

export function CatalogProductFitFromJSON(json: any): CatalogProductFit {
  return CatalogProductFitFromJSONTyped(json, false)
}

export function CatalogProductFitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductFit {
  return json as CatalogProductFit
}

export function CatalogProductFitToJSON(value?: CatalogProductFit | null): any {
  return value as any
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    ActionParticipantDto,
    ActionParticipantDtoFromJSON,
    ActionParticipantDtoToJSON,
    ActionStatisticDto,
    ActionStatisticDtoFromJSON,
    ActionStatisticDtoToJSON,
} from '../models';

export interface InfoRequest {
    slug: string;
}

export interface ParticipateRequest {
    slug: string;
}

export interface StatisticRequest {
    slug: string;
}

/**
 * 
 */
export class ActionApi extends runtime.BaseAPI {

    /**
     */
    protected infoRequestOpts = (requestParameters: InfoRequest): runtime.RequestOpts => {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling info.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/action/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected infoFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionParticipantDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionParticipantDtoFromJSON(jsonValue));
    }

    /**
     */
    protected infoRaw = async (requestParameters: InfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionParticipantDto>> => {
        const context = this.infoRequestOpts(requestParameters);
        return this.infoFetch(context, initOverrides);
    }

    /**
     */
    info = async (slug: string, initOverrides?: RequestInit): Promise<ActionParticipantDto> => {
        const response = await this.infoRaw({ slug: slug }, initOverrides);
        return await response.value();
    }


    /**
     */
    useInfo = (() => {
        const key = (slug: string) => this.infoRequestOpts({ slug: slug });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.infoFetch(context));
        const fn = (slug: string, config?: SWRConfiguration<ActionParticipantDto>): SWRResponse<ActionParticipantDto> => {
            return useSWR<ActionParticipantDto>(() => key(slug), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected participateRequestOpts = (requestParameters: ParticipateRequest): runtime.RequestOpts => {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling participate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/action/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected participateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected participateRaw = async (requestParameters: ParticipateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.participateRequestOpts(requestParameters);
        return this.participateFetch(context, initOverrides);
    }

    /**
     */
    participate = async (slug: string, initOverrides?: RequestInit): Promise<void> => {
        await this.participateRaw({ slug: slug }, initOverrides);
    }



    /**
     */
    protected statisticRequestOpts = (requestParameters: StatisticRequest): runtime.RequestOpts => {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling statistic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/action/{slug}/stats`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected statisticFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionStatisticDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionStatisticDtoFromJSON(jsonValue));
    }

    /**
     */
    protected statisticRaw = async (requestParameters: StatisticRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionStatisticDto>> => {
        const context = this.statisticRequestOpts(requestParameters);
        return this.statisticFetch(context, initOverrides);
    }

    /**
     */
    statistic = async (slug: string, initOverrides?: RequestInit): Promise<ActionStatisticDto> => {
        const response = await this.statisticRaw({ slug: slug }, initOverrides);
        return await response.value();
    }


    /**
     */
    useStatistic = (() => {
        const key = (slug: string) => this.statisticRequestOpts({ slug: slug });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.statisticFetch(context));
        const fn = (slug: string, config?: SWRConfiguration<ActionStatisticDto>): SWRResponse<ActionStatisticDto> => {
            return useSWR<ActionStatisticDto>(() => key(slug), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

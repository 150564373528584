/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderManualPaymentStatus,
    CustomerOrderManualPaymentStatusFromJSON,
    CustomerOrderManualPaymentStatusFromJSONTyped,
    CustomerOrderManualPaymentStatusToJSON,
} from './CustomerOrderManualPaymentStatus';
import {
    ImageDto,
    ImageDtoFromJSON,
    ImageDtoFromJSONTyped,
    ImageDtoToJSON,
} from './ImageDto';

/**
 * 
 * @export
 * @interface OrderManualPaymentDto
 */
export interface OrderManualPaymentDto {
    /**
     * 
     * @type {CustomerOrderManualPaymentStatus}
     * @memberof OrderManualPaymentDto
     */
    status: CustomerOrderManualPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderManualPaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderManualPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderManualPaymentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderManualPaymentDto
     */
    paymentMethodId: string | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof OrderManualPaymentDto
     */
    receiptImage: ImageDto | null;
}


export function OrderManualPaymentDtoFromJSON(json: any): OrderManualPaymentDto {
    return OrderManualPaymentDtoFromJSONTyped(json, false);
}

export function OrderManualPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderManualPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': CustomerOrderManualPaymentStatusFromJSON(json['status']),
        'id': json['id'],
        'amount': json['amount'],
        'createdAt': (new Date(json['createdAt'])),
        'paymentMethodId': json['paymentMethodId'],
        'receiptImage': ImageDtoFromJSON(json['receiptImage']),
    };
}

export function OrderManualPaymentDtoToJSON(value?: OrderManualPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': CustomerOrderManualPaymentStatusToJSON(value.status),
        'id': value.id,
        'amount': value.amount,
        'createdAt': (value.createdAt.toISOString()),
        'paymentMethodId': value.paymentMethodId,
        'receiptImage': ImageDtoToJSON(value.receiptImage),
    };
}


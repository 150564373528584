/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderOnepaymentsPaymentDto
 */
export interface OrderOnepaymentsPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof OrderOnepaymentsPaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOnepaymentsPaymentDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOnepaymentsPaymentDto
     */
    status: OrderOnepaymentsPaymentDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderOnepaymentsPaymentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderOnepaymentsPaymentDto
     */
    externalID: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderOnepaymentsPaymentDto
     */
    paymentURL: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderOnepaymentsPaymentDtoStatusEnum =
    | 'NEW'
    | 'GENERATED'
    | 'PAID'
    | 'CANCELED'



export function OrderOnepaymentsPaymentDtoFromJSON(json: any): OrderOnepaymentsPaymentDto {
    return OrderOnepaymentsPaymentDtoFromJSONTyped(json, false);
}

export function OrderOnepaymentsPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderOnepaymentsPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'externalID': json['externalID'],
        'paymentURL': json['paymentURL'],
    };
}

export function OrderOnepaymentsPaymentDtoToJSON(value?: OrderOnepaymentsPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'externalID': value.externalID,
        'paymentURL': value.paymentURL,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LegitPayMethodType {
    card_number = 'card_number',
    phone_number = 'phone_number'
}

export function LegitPayMethodTypeFromJSON(json: any): LegitPayMethodType {
    return LegitPayMethodTypeFromJSONTyped(json, false);
}

export function LegitPayMethodTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegitPayMethodType {
    return json as LegitPayMethodType;
}

export function LegitPayMethodTypeToJSON(value?: LegitPayMethodType | null): any {
    return value as any;
}


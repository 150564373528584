/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerOrderReviewStatus {
    ON_MODERATION = 'ON_MODERATION',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED'
}

export function CustomerOrderReviewStatusFromJSON(json: any): CustomerOrderReviewStatus {
    return CustomerOrderReviewStatusFromJSONTyped(json, false);
}

export function CustomerOrderReviewStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderReviewStatus {
    return json as CustomerOrderReviewStatus;
}

export function CustomerOrderReviewStatusToJSON(value?: CustomerOrderReviewStatus | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    BotCommandShortDto,
    BotCommandShortDtoFromJSON,
    BotCommandShortDtoToJSON,
    BotCommandShortRequestDto,
    BotCommandShortRequestDtoFromJSON,
    BotCommandShortRequestDtoToJSON,
} from '../models';

export interface BotCommandShortRequest {
    botCommandShortRequestDto: BotCommandShortRequestDto;
}

/**
 * 
 */
export class BotCommandShortApi extends runtime.BaseAPI {

    /**
     */
    protected botCommandShortRequestOpts = (requestParameters: BotCommandShortRequest): runtime.RequestOpts => {
        if (requestParameters.botCommandShortRequestDto === null || requestParameters.botCommandShortRequestDto === undefined) {
            throw new runtime.RequiredError('botCommandShortRequestDto','Required parameter requestParameters.botCommandShortRequestDto was null or undefined when calling botCommandShort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/bot-command-short`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BotCommandShortRequestDtoToJSON(requestParameters.botCommandShortRequestDto),
        };
    }

    /**
     */
    protected botCommandShortFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BotCommandShortDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BotCommandShortDtoFromJSON(jsonValue));
    }

    /**
     */
    protected botCommandShortRaw = async (requestParameters: BotCommandShortRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BotCommandShortDto>> => {
        const context = this.botCommandShortRequestOpts(requestParameters);
        return this.botCommandShortFetch(context, initOverrides);
    }

    /**
     */
    botCommandShort = async (botCommandShortRequestDto: BotCommandShortRequestDto, initOverrides?: RequestInit): Promise<BotCommandShortDto> => {
        const response = await this.botCommandShortRaw({ botCommandShortRequestDto: botCommandShortRequestDto }, initOverrides);
        return await response.value();
    }



}

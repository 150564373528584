/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SearchResultBrand
 */
export interface SearchResultBrand {
  /**
   *
   * @type {string}
   * @memberof SearchResultBrand
   */
  originalName: string
  /**
   *
   * @type {string}
   * @memberof SearchResultBrand
   */
  name: string
}

export function SearchResultBrandFromJSON(json: any): SearchResultBrand {
  return SearchResultBrandFromJSONTyped(json, false)
}

export function SearchResultBrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultBrand {
  if (json === undefined || json === null) {
    return json
  }
  return {
    originalName: json["originalName"],
    name: json["name"],
  }
}

export function SearchResultBrandToJSON(value?: SearchResultBrand | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    originalName: value.originalName,
    name: value.name,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerOrderManualPaymentStatus {
    NEW = 'NEW',
    CHECK_RECEIPT = 'CHECK_RECEIPT',
    PAID = 'PAID',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED'
}

export function CustomerOrderManualPaymentStatusFromJSON(json: any): CustomerOrderManualPaymentStatus {
    return CustomerOrderManualPaymentStatusFromJSONTyped(json, false);
}

export function CustomerOrderManualPaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderManualPaymentStatus {
    return json as CustomerOrderManualPaymentStatus;
}

export function CustomerOrderManualPaymentStatusToJSON(value?: CustomerOrderManualPaymentStatus | null): any {
    return value as any;
}


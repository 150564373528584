/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogPriceV2Dto
 */
export interface CatalogPriceV2Dto {
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  price: number
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  priceWithoutDiscount: number
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  priceWithExpress: number
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  priceWithExpressWithoutDiscount: number
  /**
   *
   * @type {boolean}
   * @memberof CatalogPriceV2Dto
   */
  discount: boolean
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  priceFromAvailability: number
  /**
   *
   * @type {number}
   * @memberof CatalogPriceV2Dto
   */
  previousPriceFromAvailability: number
  /**
   *
   * @type {boolean}
   * @memberof CatalogPriceV2Dto
   */
  discountFromAvailability: boolean
}

export function CatalogPriceV2DtoFromJSON(json: any): CatalogPriceV2Dto {
  return CatalogPriceV2DtoFromJSONTyped(json, false)
}

export function CatalogPriceV2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogPriceV2Dto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    price: json["price"],
    priceWithoutDiscount: json["priceWithoutDiscount"],
    priceWithExpress: json["priceWithExpress"],
    priceWithExpressWithoutDiscount: json["priceWithExpressWithoutDiscount"],
    discount: json["discount"],
    priceFromAvailability: json["priceFromAvailability"],
    previousPriceFromAvailability: json["previousPriceFromAvailability"],
    discountFromAvailability: json["discountFromAvailability"],
  }
}

export function CatalogPriceV2DtoToJSON(value?: CatalogPriceV2Dto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    price: value.price,
    priceWithoutDiscount: value.priceWithoutDiscount,
    priceWithExpress: value.priceWithExpress,
    priceWithExpressWithoutDiscount: value.priceWithExpressWithoutDiscount,
    discount: value.discount,
    priceFromAvailability: value.priceFromAvailability,
    previousPriceFromAvailability: value.previousPriceFromAvailability,
    discountFromAvailability: value.discountFromAvailability,
  }
}

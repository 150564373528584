/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CatalogProductSort {
  by_relevance = "by-relevance",
  cheap_first = "cheap-first",
  expensive_first = "expensive-first",
  last_sold = "last-sold",
  search_relevance = "search-relevance",
  discount_diff = "discount-diff",
  favorites = "favorites",
  views = "views",
}

export function CatalogProductSortFromJSON(json: any): CatalogProductSort {
  return CatalogProductSortFromJSONTyped(json, false)
}

export function CatalogProductSortFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductSort {
  return json as CatalogProductSort
}

export function CatalogProductSortToJSON(value?: CatalogProductSort | null): any {
  return value as any
}

import { FavoriteApi } from "shared/api/back/_generated/apis"
import type * as runtime from "shared/api/back/_generated/runtime"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"
import { FavoriteDto } from "shared/api/back/_generated/models"
import { getAuthed } from "shared/api/back/lib"

export class FavoriteApiOverridden extends FavoriteApi {
  useFavorites = (() => {
    const key = () => {
      if (!getAuthed()) throw new Error("not authed")
      return this.favoritesRequestOpts()
    }

    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.favoritesFetch(context))
    const fn = (config?: SWRConfiguration<Array<FavoriteDto>>): SWRResponse<Array<FavoriteDto>> => {
      return useSWR<Array<FavoriteDto>>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerPointsOperationDto,
    CustomerPointsOperationDtoFromJSON,
    CustomerPointsOperationDtoFromJSONTyped,
    CustomerPointsOperationDtoToJSON,
} from './CustomerPointsOperationDto';

/**
 * 
 * @export
 * @interface CustomerPointsOperationsDto
 */
export interface CustomerPointsOperationsDto {
    /**
     * 
     * @type {Array<CustomerPointsOperationDto>}
     * @memberof CustomerPointsOperationsDto
     */
    items: Array<CustomerPointsOperationDto>;
    /**
     * 
     * @type {number}
     * @memberof CustomerPointsOperationsDto
     */
    total: number;
}


export function CustomerPointsOperationsDtoFromJSON(json: any): CustomerPointsOperationsDto {
    return CustomerPointsOperationsDtoFromJSONTyped(json, false);
}

export function CustomerPointsOperationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPointsOperationsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CustomerPointsOperationDtoFromJSON)),
        'total': json['total'],
    };
}

export function CustomerPointsOperationsDtoToJSON(value?: CustomerPointsOperationsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CustomerPointsOperationDtoToJSON)),
        'total': value.total,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerRefStatisticDto
 */
export interface CustomerRefStatisticDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerRefStatisticDto
     */
    referrals: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerRefStatisticDto
     */
    paid: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerRefStatisticDto
     */
    sentToMoscow: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerRefStatisticDto
     */
    pointsEarning: number;
}


export function CustomerRefStatisticDtoFromJSON(json: any): CustomerRefStatisticDto {
    return CustomerRefStatisticDtoFromJSONTyped(json, false);
}

export function CustomerRefStatisticDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerRefStatisticDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': json['referrals'],
        'paid': json['paid'],
        'sentToMoscow': json['sentToMoscow'],
        'pointsEarning': json['pointsEarning'],
    };
}

export function CustomerRefStatisticDtoToJSON(value?: CustomerRefStatisticDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': value.referrals,
        'paid': value.paid,
        'sentToMoscow': value.sentToMoscow,
        'pointsEarning': value.pointsEarning,
    };
}


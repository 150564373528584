import { OrderApi } from "shared/api/back/_generated/apis"
import type * as runtime from "shared/api/back/_generated/runtime"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"
import { PaymentFlowAvailablePaymentMethodsDto } from "shared/api/back/_generated/models"
import { getAuthed } from "shared/api/back/lib"

export class OrderApiOverridden extends OrderApi {
  useOrderAvailablePaymentMethodsByAuth = (() => {
    const key = () => {
      if (!getAuthed()) throw new Error("not authed")
      return this.orderAvailablePaymentMethodsByAuthRequestOpts()
    }

    const fetcher = (context: runtime.RequestOpts) =>
      this.swrFetch(this.orderAvailablePaymentMethodsByAuthFetch(context))

    const fn = (
      config?: SWRConfiguration<PaymentFlowAvailablePaymentMethodsDto>,
    ): SWRResponse<PaymentFlowAvailablePaymentMethodsDto> => {
      return useSWR<PaymentFlowAvailablePaymentMethodsDto>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    CustomerOrderReviewCollectionDto,
    CustomerOrderReviewCollectionDtoFromJSON,
    CustomerOrderReviewCollectionDtoToJSON,
    CustomerOrderReviewCreateRequestDto,
    CustomerOrderReviewCreateRequestDtoFromJSON,
    CustomerOrderReviewCreateRequestDtoToJSON,
} from '../models';

export interface CustomerOrderReviewCreateRequest {
    customerOrderReviewCreateRequestDto: CustomerOrderReviewCreateRequestDto;
}

export interface CustomerOrderReviewsRequest {
    status?: CustomerOrderReviewsStatusEnum;
    page?: number;
    perPage?: number;
}

/**
 * 
 */
export class CustomerOrderReviewApi extends runtime.BaseAPI {

    /**
     */
    protected customerOrderReviewCreateRequestOpts = (requestParameters: CustomerOrderReviewCreateRequest): runtime.RequestOpts => {
        if (requestParameters.customerOrderReviewCreateRequestDto === null || requestParameters.customerOrderReviewCreateRequestDto === undefined) {
            throw new runtime.RequiredError('customerOrderReviewCreateRequestDto','Required parameter requestParameters.customerOrderReviewCreateRequestDto was null or undefined when calling customerOrderReviewCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        return {
            path: `/api/customer-bot/customer-order-review`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerOrderReviewCreateRequestDtoToJSON(requestParameters.customerOrderReviewCreateRequestDto),
        };
    }

    /**
     */
    protected customerOrderReviewCreateFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    protected customerOrderReviewCreateRaw = async (requestParameters: CustomerOrderReviewCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const context = this.customerOrderReviewCreateRequestOpts(requestParameters);
        return this.customerOrderReviewCreateFetch(context, initOverrides);
    }

    /**
     */
    customerOrderReviewCreate = async (customerOrderReviewCreateRequestDto: CustomerOrderReviewCreateRequestDto, initOverrides?: RequestInit): Promise<void> => {
        await this.customerOrderReviewCreateRaw({ customerOrderReviewCreateRequestDto: customerOrderReviewCreateRequestDto }, initOverrides);
    }



    /**
     */
    protected customerOrderReviewsRequestOpts = (requestParameters: CustomerOrderReviewsRequest): runtime.RequestOpts => {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
        }

        return {
            path: `/api/customer-bot/customer-order-review`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected customerOrderReviewsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerOrderReviewCollectionDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOrderReviewCollectionDtoFromJSON(jsonValue));
    }

    /**
     */
    protected customerOrderReviewsRaw = async (requestParameters: CustomerOrderReviewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerOrderReviewCollectionDto>> => {
        const context = this.customerOrderReviewsRequestOpts(requestParameters);
        return this.customerOrderReviewsFetch(context, initOverrides);
    }

    /**
     */
    customerOrderReviews = async (status?: CustomerOrderReviewsStatusEnum, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<CustomerOrderReviewCollectionDto> => {
        const response = await this.customerOrderReviewsRaw({ status: status, page: page, perPage: perPage }, initOverrides);
        return await response.value();
    }


    /**
     */
    useCustomerOrderReviews = (() => {
        const key = (status?: CustomerOrderReviewsStatusEnum, page?: number, perPage?: number) => this.customerOrderReviewsRequestOpts({ status: status, page: page, perPage: perPage });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.customerOrderReviewsFetch(context));
        const fn = (status?: CustomerOrderReviewsStatusEnum, page?: number, perPage?: number, config?: SWRConfiguration<CustomerOrderReviewCollectionDto>): SWRResponse<CustomerOrderReviewCollectionDto> => {
            return useSWR<CustomerOrderReviewCollectionDto>(() => key(status, page, perPage), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/**
    * @export
    * @enum {string}
    */
export type CustomerOrderReviewsStatusEnum = 'ON_MODERATION' | 'APPROVED' | 'DECLINED'


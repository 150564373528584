/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerPointsOperationOrderDto,
    CustomerPointsOperationOrderDtoFromJSON,
    CustomerPointsOperationOrderDtoFromJSONTyped,
    CustomerPointsOperationOrderDtoToJSON,
} from './CustomerPointsOperationOrderDto';
import {
    CustomerPointsOperationReferralCustomerDto,
    CustomerPointsOperationReferralCustomerDtoFromJSON,
    CustomerPointsOperationReferralCustomerDtoFromJSONTyped,
    CustomerPointsOperationReferralCustomerDtoToJSON,
} from './CustomerPointsOperationReferralCustomerDto';
import {
    CustomerPointsOperationType,
    CustomerPointsOperationTypeFromJSON,
    CustomerPointsOperationTypeFromJSONTyped,
    CustomerPointsOperationTypeToJSON,
} from './CustomerPointsOperationType';

/**
 * 
 * @export
 * @interface CustomerPointsOperationDto
 */
export interface CustomerPointsOperationDto {
    /**
     * 
     * @type {CustomerPointsOperationType}
     * @memberof CustomerPointsOperationDto
     */
    type: CustomerPointsOperationType;
    /**
     * 
     * @type {object}
     * @memberof CustomerPointsOperationDto
     */
    cart: object | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerPointsOperationDto
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerPointsOperationDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerPointsOperationDto
     */
    comment: string | null;
    /**
     * 
     * @type {CustomerPointsOperationOrderDto}
     * @memberof CustomerPointsOperationDto
     */
    order: CustomerPointsOperationOrderDto | null;
    /**
     * 
     * @type {CustomerPointsOperationReferralCustomerDto}
     * @memberof CustomerPointsOperationDto
     */
    referralCustomer: CustomerPointsOperationReferralCustomerDto | null;
}


export function CustomerPointsOperationDtoFromJSON(json: any): CustomerPointsOperationDto {
    return CustomerPointsOperationDtoFromJSONTyped(json, false);
}

export function CustomerPointsOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPointsOperationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': CustomerPointsOperationTypeFromJSON(json['type']),
        'cart': json['cart'],
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'amount': json['amount'],
        'comment': json['comment'],
        'order': CustomerPointsOperationOrderDtoFromJSON(json['order']),
        'referralCustomer': CustomerPointsOperationReferralCustomerDtoFromJSON(json['referralCustomer']),
    };
}

export function CustomerPointsOperationDtoToJSON(value?: CustomerPointsOperationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': CustomerPointsOperationTypeToJSON(value.type),
        'cart': value.cart,
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'amount': value.amount,
        'comment': value.comment,
        'order': CustomerPointsOperationOrderDtoToJSON(value.order),
        'referralCustomer': CustomerPointsOperationReferralCustomerDtoToJSON(value.referralCustomer),
    };
}


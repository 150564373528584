/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCreateV2YaMetrikaDto
 */
export interface OrderCreateV2YaMetrikaDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2YaMetrikaDto
     */
    clickId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2YaMetrikaDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2YaMetrikaDto
     */
    counterId?: string;
}


export function OrderCreateV2YaMetrikaDtoFromJSON(json: any): OrderCreateV2YaMetrikaDto {
    return OrderCreateV2YaMetrikaDtoFromJSONTyped(json, false);
}

export function OrderCreateV2YaMetrikaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2YaMetrikaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clickId': !exists(json, 'clickId') ? undefined : json['clickId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'counterId': !exists(json, 'counterId') ? undefined : json['counterId'],
    };
}

export function OrderCreateV2YaMetrikaDtoToJSON(value?: OrderCreateV2YaMetrikaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clickId': value.clickId,
        'clientId': value.clientId,
        'counterId': value.counterId,
    };
}


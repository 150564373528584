/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductSizeTableRowDto
 */
export interface CatalogProductSizeTableRowDto {
  /**
   *
   * @type {string}
   * @memberof CatalogProductSizeTableRowDto
   */
  type: string
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductSizeTableRowDto
   */
  primary: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductSizeTableRowDto
   */
  values: Array<string>
}

export function CatalogProductSizeTableRowDtoFromJSON(json: any): CatalogProductSizeTableRowDto {
  return CatalogProductSizeTableRowDtoFromJSONTyped(json, false)
}

export function CatalogProductSizeTableRowDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductSizeTableRowDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json["type"],
    primary: json["primary"],
    values: json["values"],
  }
}

export function CatalogProductSizeTableRowDtoToJSON(value?: CatalogProductSizeTableRowDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    primary: value.primary,
    values: value.values,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
    DeliveryPointsDto,
    DeliveryPointsDtoFromJSON,
    DeliveryPointsDtoToJSON,
} from '../models';

export interface DeliveryNearestCDEKRequest {
    longitude: number;
    latitude: number;
}

export interface DeliveryPointsLocationsRequest {
    longitude: number;
    latitude: number;
    distance: number;
}

/**
 * 
 */
export class DeliveryPointsApi extends runtime.BaseAPI {

    /**
     */
    protected deliveryNearestCDEKRequestOpts = (requestParameters: DeliveryNearestCDEKRequest): runtime.RequestOpts => {
        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling deliveryNearestCDEK.');
        }

        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling deliveryNearestCDEK.');
        }

        const queryParameters: any = {};

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-points/nearest-cdek`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryNearestCDEKFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryPointsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryPointsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected deliveryNearestCDEKRaw = async (requestParameters: DeliveryNearestCDEKRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryPointsDto>> => {
        const context = this.deliveryNearestCDEKRequestOpts(requestParameters);
        return this.deliveryNearestCDEKFetch(context, initOverrides);
    }

    /**
     */
    deliveryNearestCDEK = async (longitude: number, latitude: number, initOverrides?: RequestInit): Promise<DeliveryPointsDto> => {
        const response = await this.deliveryNearestCDEKRaw({ longitude: longitude, latitude: latitude }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryNearestCDEK = (() => {
        const key = (longitude: number, latitude: number) => this.deliveryNearestCDEKRequestOpts({ longitude: longitude, latitude: latitude });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryNearestCDEKFetch(context));
        const fn = (longitude: number, latitude: number, config?: SWRConfiguration<DeliveryPointsDto>): SWRResponse<DeliveryPointsDto> => {
            return useSWR<DeliveryPointsDto>(() => key(longitude, latitude), fetcher, config);
        }
        fn.key = key
        return fn
    })()

    /**
     */
    protected deliveryPointsLocationsRequestOpts = (requestParameters: DeliveryPointsLocationsRequest): runtime.RequestOpts => {
        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling deliveryPointsLocations.');
        }

        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling deliveryPointsLocations.');
        }

        if (requestParameters.distance === null || requestParameters.distance === undefined) {
            throw new runtime.RequiredError('distance','Required parameter requestParameters.distance was null or undefined when calling deliveryPointsLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        return {
            path: `/api/customer-bot/delivery-points`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        };
    }

    /**
     */
    protected deliveryPointsLocationsFetch = async (context: runtime.RequestOpts, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryPointsDto>> => {
        const response = await this.request(context, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryPointsDtoFromJSON(jsonValue));
    }

    /**
     */
    protected deliveryPointsLocationsRaw = async (requestParameters: DeliveryPointsLocationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeliveryPointsDto>> => {
        const context = this.deliveryPointsLocationsRequestOpts(requestParameters);
        return this.deliveryPointsLocationsFetch(context, initOverrides);
    }

    /**
     */
    deliveryPointsLocations = async (longitude: number, latitude: number, distance: number, initOverrides?: RequestInit): Promise<DeliveryPointsDto> => {
        const response = await this.deliveryPointsLocationsRaw({ longitude: longitude, latitude: latitude, distance: distance }, initOverrides);
        return await response.value();
    }


    /**
     */
    useDeliveryPointsLocations = (() => {
        const key = (longitude: number, latitude: number, distance: number) => this.deliveryPointsLocationsRequestOpts({ longitude: longitude, latitude: latitude, distance: distance });
        const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.deliveryPointsLocationsFetch(context));
        const fn = (longitude: number, latitude: number, distance: number, config?: SWRConfiguration<DeliveryPointsDto>): SWRResponse<DeliveryPointsDto> => {
            return useSWR<DeliveryPointsDto>(() => key(longitude, latitude, distance), fetcher, config);
        }
        fn.key = key
        return fn
    })()

}

/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CdekDeliveryPointDto
 */
export interface CdekDeliveryPointDto {
    /**
     * 
     * @type {string}
     * @memberof CdekDeliveryPointDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CdekDeliveryPointDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CdekDeliveryPointDto
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof CdekDeliveryPointDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof CdekDeliveryPointDto
     */
    longitude: number;
}


export function CdekDeliveryPointDtoFromJSON(json: any): CdekDeliveryPointDto {
    return CdekDeliveryPointDtoFromJSONTyped(json, false);
}

export function CdekDeliveryPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdekDeliveryPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'address': json['address'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function CdekDeliveryPointDtoToJSON(value?: CdekDeliveryPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}


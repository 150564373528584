/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderCreateV2Source {
    UNICORN = 'UNICORN',
    POIZONSHOP = 'POIZONSHOP',
    WEBAPP = 'WEBAPP'
}

export function OrderCreateV2SourceFromJSON(json: any): OrderCreateV2Source {
    return OrderCreateV2SourceFromJSONTyped(json, false);
}

export function OrderCreateV2SourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2Source {
    return json as OrderCreateV2Source;
}

export function OrderCreateV2SourceToJSON(value?: OrderCreateV2Source | null): any {
    return value as any;
}


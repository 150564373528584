import { ReviewApi, ReviewsDto, ReviewsRequest } from "../_generated"
import useSWRInfinite from "swr/infinite"
import { SWRConfiguration } from "swr"

export class ReviewsInfiniteApi extends ReviewApi {
  useReviewInfinite = (request: Omit<ReviewsRequest, "page">, config?: SWRConfiguration) => {
    return useSWRInfinite<ReviewsDto>(
      (page) => this.reviewsRequestOpts({ ...request, page: page + 1 }),
      (context) => this.swrFetch(this.reviewsFetch(context)),
      config,
    )
  }
}

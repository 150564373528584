/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryBoxberryCityDto
 */
export interface DeliveryBoxberryCityDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryBoxberryCityDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryBoxberryCityDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryBoxberryCityDto
     */
    regionName: string | null;
}


export function DeliveryBoxberryCityDtoFromJSON(json: any): DeliveryBoxberryCityDto {
    return DeliveryBoxberryCityDtoFromJSONTyped(json, false);
}

export function DeliveryBoxberryCityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryBoxberryCityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'regionName': json['regionName'],
    };
}

export function DeliveryBoxberryCityDtoToJSON(value?: DeliveryBoxberryCityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'regionName': value.regionName,
    };
}


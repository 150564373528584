/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryPointDto
 */
export interface DeliveryPointDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    type: DeliveryPointDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPointDto
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPointDto
     */
    latitude: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointDto
     */
    workTime: string;
}

/**
* @export
* @enum {string}
*/
export type DeliveryPointDtoTypeEnum =
    | 'CDEK'
    | 'BOXBERRY'



export function DeliveryPointDtoFromJSON(json: any): DeliveryPointDto {
    return DeliveryPointDtoFromJSONTyped(json, false);
}

export function DeliveryPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'city': json['city'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
        'name': json['name'],
        'address': json['address'],
        'workTime': json['workTime'],
    };
}

export function DeliveryPointDtoToJSON(value?: DeliveryPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'city': value.city,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'name': value.name,
        'address': value.address,
        'workTime': value.workTime,
    };
}


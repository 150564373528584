/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderUpdateSplitRequestDto
 */
export interface OrderUpdateSplitRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof OrderUpdateSplitRequestDto
     */
    enabled: boolean;
}


export function OrderUpdateSplitRequestDtoFromJSON(json: any): OrderUpdateSplitRequestDto {
    return OrderUpdateSplitRequestDtoFromJSONTyped(json, false);
}

export function OrderUpdateSplitRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateSplitRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function OrderUpdateSplitRequestDtoToJSON(value?: OrderUpdateSplitRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderLegitPayPaymentSimpleFormDto
 */
export interface OrderLegitPayPaymentSimpleFormDto {
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    status: OrderLegitPayPaymentSimpleFormDtoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    externalID: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLegitPayPaymentSimpleFormDto
     */
    paymentURL: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderLegitPayPaymentSimpleFormDtoStatusEnum =
    | 'NEW'
    | 'GENERATED'
    | 'MARK_PAID'
    | 'PAID'
    | 'REJECTED'
    | 'CANCELED'



export function OrderLegitPayPaymentSimpleFormDtoFromJSON(json: any): OrderLegitPayPaymentSimpleFormDto {
    return OrderLegitPayPaymentSimpleFormDtoFromJSONTyped(json, false);
}

export function OrderLegitPayPaymentSimpleFormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLegitPayPaymentSimpleFormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'externalID': json['externalID'],
        'paymentURL': json['paymentURL'],
    };
}

export function OrderLegitPayPaymentSimpleFormDtoToJSON(value?: OrderLegitPayPaymentSimpleFormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'externalID': value.externalID,
        'paymentURL': value.paymentURL,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionParticipantLevelDto,
    ActionParticipantLevelDtoFromJSON,
    ActionParticipantLevelDtoFromJSONTyped,
    ActionParticipantLevelDtoToJSON,
} from './ActionParticipantLevelDto';

/**
 * 
 * @export
 * @interface ActionParticipantDto
 */
export interface ActionParticipantDto {
    /**
     * 
     * @type {boolean}
     * @memberof ActionParticipantDto
     */
    participant: boolean;
    /**
     * 
     * @type {Array<ActionParticipantLevelDto>}
     * @memberof ActionParticipantDto
     */
    levels: Array<ActionParticipantLevelDto>;
}


export function ActionParticipantDtoFromJSON(json: any): ActionParticipantDto {
    return ActionParticipantDtoFromJSONTyped(json, false);
}

export function ActionParticipantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionParticipantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participant': json['participant'],
        'levels': ((json['levels'] as Array<any>).map(ActionParticipantLevelDtoFromJSON)),
    };
}

export function ActionParticipantDtoToJSON(value?: ActionParticipantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participant': value.participant,
        'levels': ((value.levels as Array<any>).map(ActionParticipantLevelDtoToJSON)),
    };
}

